<template>
    <ManagerFrame default-active="withdrawalConfigs" v-loading="cardLoading">
        <el-card shadow="always">
            <el-descriptions title="提现设置" :column="1" border>
                <template #extra>
                    <el-button v-if="!edit" @click="handleEdit" size="small">修改</el-button>
                    <template v-else>
                        <el-button @click="handleSave" type="success" size="small">保存</el-button>
                        <el-button @click="handleCancel" size="small">取消</el-button>
                    </template>
                </template>
                <el-descriptions-item>
                    <template #label>模式</template>
                    <el-select v-if="edit" v-model="withdrawalConfigs.model" placeholder="请选择模式">
                        <el-option label="用户输入" :value="0"></el-option>
                        <el-option label="预设金额" :value="1"></el-option>
                    </el-select>
                    <template v-else>
                        <span v-if="withdrawalConfigs.model == 0">用户输入</span>
                        <span v-else>预设金额</span>
                    </template>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>限制一天提现一次</template>
                    <el-switch v-if="edit" v-model="withdrawalConfigs.limitOnceDay" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="withdrawalConfigs.limitOnceDay">是</span>
                        <span v-else :style="{color:'#F56C6C'}">否</span>
                    </template>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>提现手续费率</template>
                    <el-input v-if="edit" v-model="withdrawalConfigs.commissionRate" size="mini"></el-input>
                    <span v-else>{{withdrawalConfigs.commissionRate}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>体验用户提现比例</template>
                    <el-input v-if="edit" v-model="withdrawalConfigs.trialUserRate" size="mini"></el-input>
                    <span v-else>{{withdrawalConfigs.trialUserRate}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>最少提现金额</template>
                    <el-input v-if="edit" v-model="withdrawalConfigs.unlessAmount" size="mini"></el-input>
                    <span v-else>{{withdrawalConfigs.unlessAmount}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>提现整数倍</template>
                    <el-input v-if="edit" v-model="withdrawalConfigs.integerMultiple" size="mini"></el-input>
                    <span v-else>{{withdrawalConfigs.integerMultiple}}</span>
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-card shadow="always" :style="{marginTop:'20px'}">
            <template #header>
                <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}">
                    <span>提现金额设置</span>
                    <el-button @click="clickAddBtn" size="small" type="primary">添加</el-button>
                </div>
            </template>
            <el-table
                    :data="tableData"
                    :show-header="false"
                    stripe="true"
                    :style="{width: '100%', marginTop: '-20px'}">
                <el-table-column
                        #default="scope"
                        label="金额">
                    <el-input v-if="scope.row.edit" v-model="scope.row.amount" @keyup.enter="handleSaveRow(scope.row)" size="mini"></el-input>
                    <span v-else>{{scope.row.amount}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="状态">
                    <el-switch v-if="scope.row.edit" v-model="scope.row.active" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="scope.row.active" :style="{color:'#67C23A'}">正常</span>
                        <span v-else :style="{color:'#F56C6C'}">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #default="scope">
                        <template v-if="scope.row.edit">
                            <el-button size="mini" type="success" @click="handleSaveRow(scope.row)">保存</el-button>
                            <el-button size="mini" @click="handleCancelRow(scope.row)">取消</el-button>
                        </template>
                        <template v-else>
                            <el-button size="mini" type="danger" @click="handleDeleteRow(scope.$index, scope.row)">删除</el-button>
                            <el-button size="mini" @click="handleEditRow(scope.row)">修改</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "WithdrawalConfigs",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/withdrawal_configs").then((response) => {
                this.withdrawalConfigs = response.data;
            });
            this.axios.post("/manager/get_withdrawal_amount_setting_list").then((response) => {
                this.cardLoading = false;
                this.tableData = response.data;
            });
        },
        data() {
            return {
                withdrawalConfigs: {},
                oldWithdrawalConfigs: {},
                cardLoading: true,
                edit: false,
                tableData: []
            }
        },
        methods: {
            handleEdit() {
                this.oldWithdrawalConfigs = null;
                this.oldWithdrawalConfigs = JSON.parse(JSON.stringify(this.withdrawalConfigs));
                this.edit = true;
            },
            handleCancel() {
                this.withdrawalConfigs = this.oldWithdrawalConfigs;
                this.edit = false;
            },
            handleSave() {
                this.cardLoading = true;
                this.axios.post("/manager/save_withdrawal_configs", this.withdrawalConfigs).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        this.edit = false;
                        this.$message.success({
                            message: "修改成功",
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            },
            clickAddBtn() {
                this.$prompt("请输入金额", "添加提现金额", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputPattern: /^[+]?(\d+)$|^[+]?(\d+\.\d+)$/,
                    inputErrorMessage: "请输入数字"
                }).then(({ value }) => {
                    this.cardLoading = true;
                    this.axios.post("/manager/add_withdrawal_amount_setting",{
                        "amount": value
                    }).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.$message({
                                type: "success",
                                message: response.data.message
                            });
                            this.tableData.unshift(response.data.data);
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            handleDeleteRow(index, row) {
                this.$confirm("确定删除吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_withdrawal_amount_setting", row).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.tableData.splice(index, 1);
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            handleEditRow(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true;
            },
            handleCancelRow(data) {
                data.amount = data.oldData.amount;
                data.active = data.oldData.active;
                data.edit = false;
            },
            handleSaveRow(data) {
                let amountStr = data.amount;
                if(amountStr.toString().trim() === "" || isNaN(amountStr)) {
                    this.$message.error({
                        message: "请输入正确金额!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(amountStr) < 0) || (parseFloat(amountStr) > 99999999.99)) {
                    this.$message.error({
                        message: "金额的范围只能是 0 - 99999999.99 !",
                        type: "error"
                    });
                    return;
                }
                this.axios.post("/manager/save_withdrawal_amount_setting", data).then((response) => {
                    if(response.data.result) {
                        data.edit = false
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>