<template>
    <ManagerFrame default-active="payment-website-upgrade">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="名称">
                    <el-input v-if="scope.row.edit" v-model="scope.row.name" @keyup.enter="handleSave(scope.row)" size="mini"></el-input>
                    <span v-else>{{scope.row.name}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="基础网站">
                    {{scope.row.originalAlias}}({{scope.row.originalPrice}} - {{scope.row.originalRevenueRate}})
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="升级网站">
                    {{scope.row.targetAlias}}({{scope.row.targetPrice}} - {{scope.row.targetRevenueRate}})
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="价格">
                    <el-input v-if="scope.row.edit" v-model="scope.row.price" @keyup.enter="handleSave(scope.row)" size="mini"></el-input>
                    <span v-else>{{scope.row.price}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="上架">
                    <el-switch v-if="scope.row.edit" v-model="scope.row.onSale" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="scope.row.onSale" :style="{color:'#67C23A'}">正常</span>
                        <span v-else :style="{color:'#F56C6C'}">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
                    </template>
                    <template #default="scope">
                        <template v-if="!scope.row.edit">
                            <el-button size="mini" @click="handleEdit(scope.row)">修改</el-button>
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                        <template v-else>
                            <el-button size="mini" type="success" @click="handleSave(scope.row)">保存</el-button>
                            <el-button size="mini" @click="handleCancel(scope.row)">取消</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                :close-on-click-modal="!addRequesting"
                :close-on-press-escape="false"
                :show-close="!addRequesting"
                title="添加" v-model="addDialogVisible"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    size="small"
                    v-loading="addRequesting">
                <el-form-item label="名称"  prop="name">
                    <el-input
                            @keyup.enter="addWebsiteUpgrade('addForm')"
                            v-model="formData.name"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="基础网站" prop="originalWebsiteTypeId">
                    <el-select v-model="formData.originalWebsiteTypeId" @change="originalSelectChange" placeholder="请选择基础网站" :style="{width:'100%'}">
                        <el-option v-for="row in originalWebsiteTypeList" :key="row.id" :value="row.id" :label="row.alias + '(' +row.price + ' - ' + row.revenueRate + ')'">
                            {{row.alias}}({{row.price}} - {{row.revenueRate}})
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="升级网站" prop="targetWebsiteTypeId">
                    <el-select v-model="formData.targetWebsiteTypeId" placeholder="请选择升级网站" :style="{width:'100%'}">
                        <el-option v-for="row in targetWebsiteTypeList" :key="row.id" :value="row.id" :label="row.alias + '(' +row.price + ' - ' + row.revenueRate + ')'">
                            {{row.alias}}({{row.price}} - {{row.revenueRate}})
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="价格"  prop="price">
                    <el-input
                            @keyup.enter="addWebsiteUpgrade('addForm')"
                            v-model="formData.price"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addRequesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addRequesting" @click="addWebsiteUpgrade('addForm')" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";

    export default {
        name: "WebsiteUpgradePrice",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/website_upgrade_list").then((response) => {
                this.tableData = response.data;
                this.cardLoading = false;
            });
        },
        data() {
            let validatePrice = (rule, value, callback) => {
                if(value.trim() === "") {
                    callback(new Error("请输入价格"));
                }
                if(isNaN(value)) {
                    callback(new Error("请输入正确的价格"));
                }
                if((parseFloat(value) <= 0) || (parseFloat(value) > 99999999.99)) {
                    callback(new Error("价格范围只能是 1 - 100000000"));
                }
                callback();
            }
            return {
                cardLoading: true,
                tableData: [],
                addRequesting: false,
                addDialogVisible: false,
                formData: {},
                originalWebsiteTypeList: [],
                targetWebsiteTypeList: [],
                rules: {
                    name: [
                        { required: true, message: "名称不能为空", trigger: "change" },
                        { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'change' }
                    ],
                    originalWebsiteTypeId: [
                        { required: true, message: "请选择基础网站", trigger: "change" },
                    ],
                    targetWebsiteTypeId: [
                        { required: true, message: "请选择升级网站", trigger: "change" },
                    ],
                    price: [
                        { validator: validatePrice, trigger: "change" }
                    ],
                },
            }
        },
        methods: {
            handleAdd() {
                this.axios.post("/manager/original_website_type_list").then((response) => {
                    this.originalWebsiteTypeList = response.data;
                });
                this.addDialogVisible = true;
            },
            handleEdit(row) {
                row.oldData = null;
                row.oldData = JSON.parse(JSON.stringify(row));
                row.edit = true
            },
            handleCancel(row) {
                row.name = row.oldData.name;
                row.price = row.oldData.price;
                row.onSale = row.oldData.onSale;
                row.edit = false
            },
            addWebsiteUpgrade(formName) {
                this.$refs[formName].validate((value) => {
                    if(value) {
                        this.addRequesting = true;
                        this.axios.post("/manager/add_website_upgrade", this.formData).then((response) => {
                            this.addRequesting = false;
                            this.addDialogVisible = false;
                            this.formData = {};
                            if(response.data.result) {
                                this.$message({
                                    type: "success",
                                    message: response.data.message
                                });
                                this.tableData.unshift(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            originalSelectChange() {
                this.targetWebsiteTypeList = [];
                this.formData.targetWebsiteTypeId = null;
                this.axios.post("/manager/target_website_type_list", {
                    "id": this.formData.originalWebsiteTypeId
                }).then((response) => {
                    this.targetWebsiteTypeList = response.data;
                });
            },
            handleDelete(index) {
                this.$confirm("确定删除吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_website_upgrade", {
                        "id": this.tableData[index].id
                    }).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.$message({
                                type: "success",
                                message: response.data.message
                            });
                            this.tableData.splice(index, 1);
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            handleSave(row) {
                for(let key in row) {
                    if(row[key].toString().trim() === "") {
                        this.$message.error({
                            message: "修改失败,不能留空!",
                            type: "error"
                        });
                        return;
                    }
                }
                if(isNaN(row.price)) {
                    this.$message.error({
                        message: "修改失败,价格只能输入数字!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(row.price) <= 0) || ((parseFloat(row.price) > 100000000))) {
                    this.$message.error({
                        message: "修改失败,价格范围只能是 1 - 100000000 !",
                        type: "error"
                    });
                    return;
                }
                this.cardLoading = true;
                this.axios.post("/manager/update_website_upgrade", row).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        row.edit = false;
                        this.$message.success({
                            message: '修改成功',
                            type: 'success'
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: 'error'
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>