<template>
    <ManagerFrame default-active="systemConfigs">
        <el-card shadow="always" v-loading="cardLoading">
            <el-descriptions title="系统设置" :column="1" border>
                <template #extra>
                    <el-button v-if="!edit" @click="handleEdit" size="small">修改</el-button>
                    <template v-else>
                        <el-button @click="handleSave" type="success" size="small">保存</el-button>
                        <el-button @click="handleCancel" size="small">取消</el-button>
                    </template>
                </template>
                <el-descriptions-item>
                    <template #label>用户网站数量上限</template>
                    <el-input v-if="edit" v-model="systemConfigs.userWebsiteCountLimit" size="mini"></el-input>
                    <span v-else>{{systemConfigs.userWebsiteCountLimit}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>每天点击广告次数</template>
                    <el-input v-if="edit" v-model="systemConfigs.clickAdsNumLimit" size="mini"></el-input>
                    <span v-else>{{systemConfigs.clickAdsNumLimit}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>持续体验天数弹广告</template>
                    <el-input v-if="edit" v-model="systemConfigs.persistCompletedAdsDays" size="mini"></el-input>
                    <span v-else>{{systemConfigs.persistCompletedAdsDays}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>用户入群标题</template>
                    <el-input v-if="edit" v-model="systemConfigs.userJoinGroupTitle" size="mini"></el-input>
                    <span v-else>{{systemConfigs.userJoinGroupTitle}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>用户入群按钮文字</template>
                    <el-input v-if="edit" v-model="systemConfigs.userJoinGroupBtnText" size="mini"></el-input>
                    <span v-else>{{systemConfigs.userJoinGroupBtnText}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>用户入群路由</template>
                    <el-input v-if="edit" v-model="systemConfigs.userJoinGroupRoute" size="mini"></el-input>
                    <span v-else>{{systemConfigs.userJoinGroupRoute}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>APP最新版本</template>
                    <el-input v-if="edit" v-model="systemConfigs.lastClientVersion" size="mini"></el-input>
                    <span v-else>{{systemConfigs.lastClientVersion}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>APP最低版本</template>
                    <el-input v-if="edit" v-model="systemConfigs.leastClientVersion" size="mini"></el-input>
                    <span v-else>{{systemConfigs.leastClientVersion}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>Android下载链接</template>
                    <el-input v-if="edit" v-model="systemConfigs.androidDownloadUrl" size="mini"></el-input>
                    <span v-else>{{systemConfigs.androidDownloadUrl}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>IOS下载链接</template>
                    <el-input v-if="edit" v-model="systemConfigs.iosDownloadUrl" size="mini"></el-input>
                    <span v-else>{{systemConfigs.iosDownloadUrl}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>状态</template>
                    <span v-if="systemConfigs.settling == 0" :style="{ color: '#67C23A'}">正常</span>
                    <span v-else :style="{ color: '#F56C6C'}">结算中</span>
                </el-descriptions-item>
<!--                <el-descriptions-item>-->
<!--                    <template #label>操作</template>-->
<!--                    <el-button @click="testHibernate" type="danger" size="small">测试</el-button>-->
<!--                </el-descriptions-item>-->
            </el-descriptions>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "SystemConfigs",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/system_configs").then((response) => {
                this.cardLoading = false;
                this.systemConfigs = response.data;
            });
        },
        data() {
            return {
                systemConfigs: {},
                oldSystemConfigs: {},
                cardLoading: true,
                edit: false
            }
        },
        methods: {
            handleEdit() {
                this.oldSystemConfigs = null;
                this.oldSystemConfigs = JSON.parse(JSON.stringify(this.systemConfigs));
                this.edit = true;
            },
            handleCancel() {
                this.systemConfigs = this.oldSystemConfigs;
                this.edit = false;
            },
            handleSave() {
                this.cardLoading = true;
                this.axios.post("/manager/save_system_configs", this.systemConfigs).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        this.edit = false;
                        this.$message.success({
                            message: "修改成功",
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: "修改失败," + response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style>
    .el-descriptions__label {
        width: 200px;
    }
</style>