<template>
  <div>
    <el-form
      ref="loginForm"
      :model="form"
      :rules="rules"
      label-width="80px"
      class="login-box"
      v-loading="loading"
    >
      <h3 class="login-title">欢迎登录</h3>
      <el-form-item label="账号" prop="username">
        <el-input
          type="text"
          placeholder="请输入账号"
          v-model="form.username"
          @keyup.enter="onSubmit('loginForm')"
          autofocus="true"
          clearable
        />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          placeholder="请输入密码"
          v-model="form.password"
          @keyup.enter="onSubmit('loginForm')"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button :style="{ width:'100%' }" type="primary" v-on:click="onSubmit('loginForm')">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  mounted() {
    this.loginApi = document.location.pathname;
  },
  data() {
    return {
      loginApi: "",
      loading: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "账号不能为空", trigger: "change" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid) {
          let $this = this;
          this.loading = true;
          this.axios.post(this.loginApi, {
            "username": this.form.username,
            "password": this.form.password
          }).then(function(response) {
            let data = response.data;
            if(data.result) {
              if($this.loginApi.indexOf("/manager/login") > -1) {
                localStorage.setItem("managerName", $this.form.username);
              } else if($this.loginApi.indexOf("/salesman/login") > -1) {
                localStorage.setItem("salesmanName", $this.form.username);
              } else if($this.loginApi.indexOf("/user/login") > -1) {
                localStorage.setItem("username", $this.form.username);
              }
              document.location.href = "./";
            } else {
              $this.loading = false;
              $this.$message.error(data.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  border: 1px solid #dcdfe6;
  width: 350px;
  margin: 180px auto;
  padding: 35px 35px 15px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 0 25px #909399;
}

.login-title {
  text-align: center;
  margin: 0 auto 40px auto;
  color: #303133;
}
</style>
