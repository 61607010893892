<template>
    <salesman-frame default-active="withdrawalList">
        <el-card id="s-withdrawal-list" v-loading="cardLoading">
            <div class="withdrawal-header">
                <el-menu
                        :default-active="activeIndex"
                        active-text-color="#409EFF"
                        mode="horizontal"
                        @select="handleSelect">
                    <el-menu-item index="pending">待确认</el-menu-item>
                    <el-menu-item index="confirmed">已确认</el-menu-item>
                    <el-menu-item index="violation">账号重复</el-menu-item>
                    <el-menu-item index="fail">失败</el-menu-item>
                    <el-menu-item index="success">成功</el-menu-item>
                    <el-menu-item index="all">全部</el-menu-item>
                </el-menu>
                <div class="withdrawal-header-btn"  v-if="this.activeIndex === 'pending'">
                    <el-button v-if="selectedRow.length > 0" @click="handleConfirmList" size="small" type="success">批量通过</el-button>
                    <el-dropdown class="payment-method" trigger="click" @command="handlePaymentMethodChange">
                        <el-button :style="{ width:'150px'}" type="primary" size="small">
                            {{paymentMethodStr}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <template v-for="(row, index) in paymentMethodList" :key="row.id">
                                    <el-dropdown-item :command="index">{{row.alias}}</el-dropdown-item>
                                </template>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <el-table
                    :data="tableData"
                    stripe="true"
                    show-overflow-tooltip="true"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                <el-table-column
                        v-if="this.activeIndex === 'pending'"
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        label="用户名"
                        prop="username">
                </el-table-column>
                <el-table-column
                        v-if="this.activeIndex !== 'pending' && this.activeIndex !== 'violation'"
                        label="订单号">
                    <template #default="scope">{{scope.row.platformOrderId === '' ? scope.row.orderId : scope.row.platformOrderId}}</template>
                </el-table-column>
                <el-table-column
                        v-if="this.activeIndex !== 'pending' && this.activeIndex !== 'violation'"
                        label="支付平台"
                        prop="paymentMethod">
                </el-table-column>
                <el-table-column
                        label="真实姓名"
                        prop="name">
                </el-table-column>
                <el-table-column
                        label="金额"
                        prop="amount"
                        width="80">
                </el-table-column>
                <el-table-column
                        label="手续费"
                        prop="commission"
                        width="80">
                </el-table-column>
                <el-table-column
                        label="银行账户"
                        prop="cardNumber">
                </el-table-column>
                <el-table-column
                        label="创建时间"
                        prop="createTime">
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-input
                                @keyup.enter="handleSearch"
                                placeholder="搜索"
                                clearable
                                size="small"
                                v-model="searchWord">
                            <template #prefix>
                                <i class="el-input__icon el-icon-search"></i>
                            </template>
                        </el-input>
                    </template>
                    <template #default="scope">
                        <template v-if="this.activeIndex === 'pending'">
                            <el-button @click="handleConfirm(scope.$index)" type="success" size="small">通过</el-button>
                            <el-button @click="handleReject(scope.$index)" type="danger" size="small">拒绝</el-button>
                        </template>
                        <span v-else-if="this.activeIndex === 'confirmed'">{{scope.row.updateTime}}</span>
                        <span v-else-if="this.activeIndex === 'fail'">{{scope.row.updateTime}}</span>
                        <span v-else-if="this.activeIndex === 'success'">{{scope.row.updateTime}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
    </salesman-frame>
</template>

<script>
    import SalesmanFrame from "../components/SalesmanFrame";
    export default {
        name: "WithdrawalList",
        components: {SalesmanFrame},
        mounted() {
            this.activeIndex = this.$route.params.status;
            this.searchWord = this.$route.params.searchWord;
            this.currentPage = this.$route.params.currentPage;
            this.loadData(this.activeIndex);
            if(this.activeIndex === "pending") {
                let $this = this;
                this.axios.post("/salesman/payment_method_list").then(function(response) {
                    $this.paymentMethodList = response.data;
                    if(response.data.length === 1) {
                        $this.handlePaymentMethodChange(0);
                    }
                });
            }
        },
        data() {
            return {
                cardLoading: false,
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                activeIndex: "pending",
                searchWord: null,
                tableData: [],
                selectedRow: [],
                paymentMethodList: [],
                selectedPaymentMethod: null,
                paymentMethodStr: "支付方式"
            }
        },
        methods: {
            handleSelect(key) {
                document.location.href = "/salesman/withdrawal_list/" + key + "/1";
            },
            handleCurrentChange(currentPage) {
                document.location.href = "/salesman/withdrawal_list/" + this.activeIndex + "/" + currentPage + "/" + this.searchWord;
            },
            loadData(status) {
                this.cardLoading = true;
                this.axios.post("/salesman/withdrawal_list", {
                    "currentPage": this.currentPage,
                    "status": status,
                    "searchWord": this.searchWord
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.cardLoading = false;
                });
            },
            handlePaymentMethodChange(command) {
                this.selectedPaymentMethod = this.paymentMethodList[command];
                this.paymentMethodStr = this.selectedPaymentMethod.alias;
            },
            handleSelectionChange(val) {
                this.selectedRow = val;
            },
            handleSearch() {
                document.location.href = "/salesman/withdrawal_list/" + this.activeIndex + "/1/" + this.searchWord;
            },
            handleConfirm(index) {
                let isReturn = true;
                if(this.selectedPaymentMethod == null) {
                    this.$confirm("请选择支付方式", "提示", {
                        confirmButtonText: "确定",
                        type: "warning"
                    });
                } else {
                    isReturn = false;
                }
                if(isReturn) {
                    return;
                }
                this.$confirm("该操作不能撤销，确定通过提现吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    let $this = this;
                    this.axios.post("/salesman/confirm_withdrawal", {
                        "id": this.tableData[index].id,
                        "paymentMethodId": this.selectedPaymentMethod.id
                    }).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                            $this.tableData.splice(index, 1);
                            $this.rowCount--;
                            if($this.tableData.length === 0) {
                                document.location.href = "/salesman/withdrawal_list/pending/1/" + $this.searchWord;
                            }
                        } else {
                            $this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            handleConfirmList() {
                let isReturn = false;
                if(this.selectedPaymentMethod === null) {
                    this.$confirm("请选择支付方式", "提示", {
                        confirmButtonText: "确定",
                        type: "warning"
                    }).then(() => {
                        isReturn = true;
                    });
                }
                if(isReturn) {
                    return;
                }
                this.$confirm("该操作不能撤销，确定通过提现吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    let $this = this;
                    let ids = [];
                    for(let i=0; i<this.selectedRow.length; i++) {
                        ids[i] = this.selectedRow[i].id;
                    }
                    this.axios.post("/salesman/confirm_withdrawal_list", {
                        "ids": JSON.stringify(ids),
                        "paymentMethodId": this.selectedPaymentMethod.id
                    }).then(function(response) {
                        $this.cardLoading = false;
                        $this.$alert(response.data.message, "提示", {
                            confirmButtonText: "确定",
                            callback: () => document.location.href = "/salesman/withdrawal_list/pending/1/" + $this.searchWord
                        });
                    });
                });
            },
            handleReject(index) {
                this.$confirm("该操作不能撤销，确定拒绝提现吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    let $this = this;
                    this.axios.post("/salesman/reject_withdrawal", {
                        "id": this.tableData[index].id,
                    }).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                            $this.tableData.splice(index, 1);
                            $this.rowCount--;
                            if($this.tableData.length === 0) {
                                document.location.href = "/salesman/withdrawal_list/pending/1/" + $this.searchWord;
                            }
                        } else {
                            $this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            }
        }
    }
</script>

<style>
#s-withdrawal-list thead th {
    padding: 0;
}
#s-withdrawal-list thead th input {
    border: none;
}
.withdrawal-header {
    position: relative;
}
.withdrawal-header-btn {
    position: absolute;
    top: 0;
    right: 0;
}
.payment-method {
    margin-left: 10px;
}
</style>