<template>
    <ManagerFrame default-active="payment-settings">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    highlight-current-row
                    @current-change="handleRowChange"
                    style="width: 100%">
                <el-table-column
                        label="名称"
                        prop="name">
                </el-table-column>
                <el-table-column
                        label="别名"
                        prop="alias">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="状态">
                    <span v-if="scope.row.status === 0">正常</span>
                    <span v-else :style="{ color:'#F56C6C' }">停用</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="right">
                    <el-button v-if="scope.row.status === 0" size="mini" type="danger" @click="handleSwitch(scope.$index)">停用</el-button>
                    <el-button v-else size="mini" type="success" @click="handleSwitch(scope.$index)">启用</el-button>
                </el-table-column>
            </el-table>
        </el-card>
        <el-card
                v-show="selectedPaymentMethod"
                v-loading="cardLoading"
                style="margin-top: 10px">
            <el-table
                    :data="paymentChannelList"
                    stripe="true">
                <el-table-column
                        label="名称"
                        prop="name">
                </el-table-column>
                <el-table-column
                        label="别名"
                        prop="alias">
                </el-table-column>
                <el-table-column
                        label="通道码"
                        prop="code">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="状态">
                    <span v-if="scope.row.active">正常</span>
                    <span v-else :style="{ color:'#F56C6C' }">停用</span>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="addDialogVisible = !addDialogVisible" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button size="mini"
                                   type="danger"
                                   @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        <el-button size="mini"
                                   @click="handleEdit(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                title="添加支付通道"
                v-model="addDialogVisible"
                :close-on-click-modal="!requesting"
                :close-on-press-escape="false"
                :show-close="!requesting"
                @closed="dialogClosed"
                width="400px">
            <el-form
                    ref="paymentChannelForm"
                    :rules="paymentChannelFormRules"
                    :label-position="left"
                    label-width="80px"
                    :model="paymentChannelForm"
                    size="small">
                <el-form-item label="名称" prop="name">
                    <el-input
                            @keyup.enter="handleSubmit"
                            v-model.trim="paymentChannelForm.name"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="别名" prop="alias">
                    <el-input
                            @keyup.enter="handleSubmit"
                            v-model.trim="paymentChannelForm.alias"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="启用" prop="active">
                    <el-switch v-model="paymentChannelForm.active" active-color="#67C23A" inactive-color="#F56C6C" active-text="是" inactive-text="否" inline-prompt></el-switch>
                </el-form-item>
                <el-form-item label="通道码" prop="code">
                    <el-input
                            @keyup.enter="handleSubmit"
                            v-model.trim="paymentChannelForm.code"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="requesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="requesting" @click="handleSubmit" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "PaymentMethod",
        components: {ManagerFrame},
        mounted() {
            let $this = this;
            this.axios.post("/manager/payment_method_list").then(function(response) {
                $this.tableData = response.data;
                $this.cardLoading = false;
            })
        },
        data() {
            return {
                cardLoading: true,
                tableData: [],
                selectedPaymentMethod: null,
                addDialogVisible: false,
                requesting: false,
                paymentChannelList: [],
                paymentChannelForm: {
                    name: "",
                    alias: "",
                    code: "",
                    active: false
                },
                paymentChannelFormRules: {
                    name: [
                        { required: true, message: "不能为空", trigger: "change" }
                    ],
                    code: [
                        { required: true, message: "不能为空", trigger: "change" }
                    ]
                }
            }
        },
        methods: {
            handleSwitch(index) {
                let title = "";
                if(this.tableData[index].status === 0) {
                    title = "确定停用 " + this.tableData[index].alias + " 支付方式吗?";
                } else {
                    title = "确定启用 " + this.tableData[index].alias + " 支付方式吗?"
                }
                this.$confirm(title, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(() => {
                    this.cardLoading = true;
                    let $this = this;
                    this.axios.post("/manager/switch_payment_method",{
                        "id": this.tableData[index].id
                    }).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.$message({
                                type: "success",
                                message: response.data.message
                            });
                            $this.tableData[index] = response.data.data;
                        } else {
                            $this.$message.error({
                                message: "操作失败",
                                type: 'error'
                            });
                        }
                    });
                })
            },
            handleRowChange(currentRow) {
                if(currentRow) {
                    this.selectedPaymentMethod = currentRow;
                    this.cardLoading = true;
                    this.axios.post("/manager/payment_channel_list", {
                        "paymentMethodId": currentRow.id
                    }).then((response) => {
                        this.paymentChannelList = response.data;
                        this.cardLoading = false;
                    });
                }
            },
            handleDelete(index, data) {
                this.$confirm("确定删除 \"" + data.name + "\" 吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_payment_channel", data).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.paymentChannelList.splice(index, 1);
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            handleSubmit() {
                this.$refs["paymentChannelForm"].validate((valid) => {
                    if(!valid) return;
                    this.requesting = true;
                    if(this.paymentChannelForm.id) {
                        this.axios.post("/manager/update_payment_channel", this.paymentChannelForm).then((response) => {
                            if(response.data.result) {
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                                for(let i=0; i<this.paymentChannelList.length; i++) {
                                    if(this.paymentChannelList[i].id === this.paymentChannelForm.id) {
                                        this.paymentChannelList[i] = JSON.parse(JSON.stringify(this.paymentChannelForm));
                                        break;
                                    }
                                }
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                            this.addDialogVisible = false;
                            this.requesting = false;
                        });
                    } else {
                        this.paymentChannelForm.paymentMethodId = this.selectedPaymentMethod.id;
                        this.axios.post("/manager/add_payment_channel", this.paymentChannelForm).then((response) => {
                            this.addDialogVisible = false;
                            this.requesting = false;
                            if(response.data.result) {
                                this.$message({
                                    type: "success",
                                    message: response.data.message
                                });
                                this.paymentChannelList.unshift(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },
            handleEdit(data) {
                this.paymentChannelForm = JSON.parse(JSON.stringify(data));
                this.addDialogVisible = true;
            },
            dialogClosed() {
                this.paymentChannelForm = {
                    name: "",
                    alias: "",
                    code: "",
                    active: false
                };
                this.$refs["paymentChannelForm"].resetFields();
            }
        }
    }
</script>

<style scoped>

</style>