<template>
    <el-dialog
            :model="model"
            @open="openCallback"
            v-loading="loading"
            :title="userData.username"
            width="800px">
        <el-table
                :data="tableData"
                stripe="true"
                style="width: 100%">
            <el-table-column
                    #default="scope"
                    label="url"
                    prop="url">
                <span v-if="scope.row.status === 1">体验网站</span>
                <span v-else>{{scope.row.url}}</span>
            </el-table-column>
            <el-table-column
                    #default="scope"
                    width="110"
                    label="名称">
                {{scope.row.alias}}({{scope.row.revenueRate}})
            </el-table-column>
            <el-table-column
                    label="租金"
                    width="60"
                    prop="deposit">
            </el-table-column>
            <el-table-column
                    #default="scope"
                    label="状态"
                    width="100">
                <span :class="statusColor(scope.row)">{{formatStatus(scope.row)}}</span>
            </el-table-column>
            <el-table-column
                    label="创建时间"
                    :formatter="formatDate"
                    prop="createTime">
            </el-table-column>
            <el-table-column
                    width="100"
                    align="right">
                <template #header>
                    <el-button @click="addUserWebsiteDialogVisible = true" size="small" type="primary">添加</el-button>
                </template>
                <template #default="scope">
                    <el-button @click="handleDeleteWebsite(scope.row.id, scope.$index)" size="small" type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <AddUserWebsiteDialog
            v-model="addUserWebsiteDialogVisible"
            :user-data="userData"
            :cancel-callback="() => {addUserWebsiteDialogVisible = false}"
            :add-website-callback="addWebsiteCallback"></AddUserWebsiteDialog>
    </el-dialog>
</template>

<script>
    import AddUserWebsiteDialog from "./AddUserWebsiteDialog";
    export default {
        name: "UserWebsiteListDialog",
        components: {AddUserWebsiteDialog},
        props: {
            model: Boolean,
            userData: Object,
            websiteCountChange: Function[Number]
        },
        data() {
            return {
                loading: false,
                tableData: [],
                addUserWebsiteDialogVisible: false
            }
        },
        methods: {
            openCallback() {
                this.loading = true;
                this.axios.post("/salesman/get_user_website_list", {
                    "id": this.userData.id
                }).then((response) => {
                    this.loading = false;
                    this.tableData = response.data;
                });
            },
            handleDeleteWebsite(id, index) {
                this.$confirm("确定删除吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.loading = true;
                    this.axios.post("/salesman/delete_user_website", {
                        "id": id
                    }).then((response) => {
                        this.loading = false;
                        if(response.data.result) {
                            this.$message({
                                type: "success",
                                message: response.data.message
                            });
                            this.tableData.splice(index, 1);
                            this.websiteCountChange(-1);
                        } else {
                            this.$message.error({
                                message: "操作失败," + response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            addWebsiteCallback(count) {
                this.addUserWebsiteDialogVisible = false;
                this.websiteCountChange(count);
                this.openCallback();
            },
            formatStatus(websiteData) {
                if(websiteData.status === 2) {
                    return "等待审核";
                }
                let expireTime = new Date(websiteData.expireTime);
                let remainTime = expireTime.getTime() - new Date().getTime();
                if(remainTime <= 0) {
                    return "已过期";
                }
                let remainDay = Math.ceil(remainTime / (1000 * 3600 * 24));
                if(remainDay <= 30) {
                    return "正常(" + remainDay + "天)";
                } else {
                    return "正常";
                }
            },
            statusColor(websiteData) {
                if(websiteData.status === 2) {
                    return "text-yellow";
                }
                let expireTime = new Date(websiteData.expireTime);
                let remainTime = expireTime.getTime() - new Date().getTime();
                if(remainTime <= 0) {
                    return "text-red";
                }
                return "text-green";
            }
        }
    }
</script>

<style scoped>

</style>