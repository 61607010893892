<template>
    <SalesmanFrame default-active="userList">
        <el-card shadow="always" v-loading="cardLoading">
            <div class="search-input">
                <el-input
                        @keyup.enter="handleSearch"
                        placeholder="搜索"
                        clearable
                        size="small"
                        v-model="searchWord">
                    <template #prefix>
                        <i class="el-input__icon el-icon-search"></i>
                    </template>
                </el-input>
            </div>
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="用户名"
                        min-width="135px"
                        prop="username">
                    <span v-if="scope.row.status === 0">{{scope.row.username}}</span>
                    <span v-else :style="{ color:'#F56C6C' }">{{scope.row.username}}(已封)</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="等级"
                        prop="membershipLevel">
                    <span v-if="scope.row.membershipLevel === '体验'" :style="{ color:'#909399' }">{{scope.row.membershipLevel}}</span>
                    <span v-else-if="scope.row.membershipLevel === '异常'" :style="{ color:'#F56C6C' }">{{scope.row.membershipLevel}}</span>
                    <span v-else>{{scope.row.membershipLevel}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="手机号码"
                        prop="mobileNumber">
                    <MobileNumberSpan :mobile-number="scope.row.mobileNumber"></MobileNumberSpan>
                </el-table-column>
                <el-table-column
                        label="收益比例"
                        prop="revenueRate">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="层级"
                        prop="hierarchyName">
                    <span @click="clickUserHierarchy(scope.row)" :style="{cursor:'pointer'}">{{scope.row.hierarchyName}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        width="60"
                        label="网站">
                        <span @click="clickUserWebsite(scope.row)" :style="{ cursor:'pointer'}">{{scope.row.websiteCount}}</span>
                </el-table-column>
                <el-table-column
                        label="余额"
                        prop="balance">
                </el-table-column>
                <el-table-column
                        label="今天预计"
                        prop="estimate">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        width="50"
                        label="上级">
                    <UserRelationshipDropdown v-if="scope.row.leader != null" :user-id="scope.row.id">
                        <span class="pointer">是</span>
                    </UserRelationshipDropdown>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="下级">
                    <i @click="teamMemberHandle(scope.row)" class="el-icon-view" :style="{cursor:'pointer'}"></i>
                </el-table-column>
<!--                <el-table-column-->
<!--                        label="有效期"-->
<!--                        :formatter="formatExpireTime"-->
<!--                        prop="expireTime">-->
<!--                </el-table-column>-->
                <el-table-column
                        #default="scope"
                        min-width="140px"
                        :formatter="formatDate"
                        label="注册时间">
                    <el-dropdown>
                        <span class="pointer">{{formatDate(scope.row, scope.column, scope.row.createTime)}}</span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>{{scope.row.lastLoginIp}}</el-dropdown-item>
                                <el-dropdown-item>{{formatDate(scope.row, scope.column, scope.row.lastLoginTime)}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="addDialogVisible = !addDialogVisible" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-dropdown v-if="scope.row.status === 0" @command="handleCommand" trigger="click">
                            <i class="el-icon-setting pointer" :style="{ fontSize: '20px'}"></i>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item :command="{'method':'changeBalance', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-coin"></i>
                                        修改余额
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'addUserCoupon', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-discount"></i>
                                        添加优惠券
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'banUser', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-error"></i>
                                        封禁用户
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.leader == null" :command="{'method':'addLeader', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-connection"></i>
                                        添加上级
                                    </el-dropdown-item>
<!--                                    <el-dropdown-item :command="{'method':'addTime', 'rowData':scope.row, 'index':scope.$index}">-->
<!--                                        <i class="el-icon-time"></i>-->
<!--                                        添加时间-->
<!--                                    </el-dropdown-item>-->
<!--                                    <el-dropdown-item  :command="{'method':'addSpreadRevenue', 'rowData':scope.row, 'index':scope.$index}">-->
<!--                                        <i class="el-icon-present"></i>-->
<!--                                        直推奖励-->
<!--                                    </el-dropdown-item>-->
                                    <el-dropdown-item :command="{'method':'userAccountFlowList', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-tickets"></i>
                                        账单列表
                                    </el-dropdown-item>
                                    <el-dropdown-item  :command="{'method':'userWithdrawalList', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-wallet"></i>
                                        提现列表
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'changeRevenueRate', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-goblet-square-full"></i>
                                        收益比例
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'changePassword', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-edit"></i>
                                        修改密码
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'viewPassword', 'rowData':scope.row}">
                                        <i class="el-icon-view"></i>
                                        查看密码
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'bankInfo', 'rowData':scope.row}">
                                        <i class="el-icon-bank-card"></i>
                                        银行信息
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{'method':'activeTrialUser', 'rowData':scope.row, 'index':scope.$index}">
                                        <i class="el-icon-magic-stick"></i>
                                        激活体验
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
        <el-dialog
                :close-on-click-modal="!addUserRequesting"
                :close-on-press-escape="false"
                :show-close="!addUserRequesting"
                title="添加用户" v-model="addDialogVisible"
                width="400px">
            <el-form
                    ref="addUserForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    size="small"
                    v-loading="addUserRequesting">
                <el-form-item label="用户名"  prop="username">
                    <el-input
                            @keyup.enter="addUser('addUserForm')"
                            v-model="formData.username"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="密码"  prop="password">
                    <el-input
                            @keyup.enter="addUser('addUserForm')"
                            v-model="formData.password"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="重复密码"  prop="passwordConfirm">
                    <el-input
                            @keyup.enter="addUser('addUserForm')"
                            v-model="formData.passwordConfirm"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="上级">
                    <el-input
                            @keyup.enter="addUser('addUserForm')"
                            v-model="formData.leaderName"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addUserRequesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addUserRequesting" @click="addUser('addUserForm')" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
                :title="clickUserRow.username" v-model="userAccountFlowListDialogVisible"
                width="800px">
            <el-table
                    :data="userAccountFlowList"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        :formatter="userAccountFlowTypeToName"
                        label="类型"
                        prop="type">
                </el-table-column>
                <el-table-column
                        label="金额"
                        prop="amount">
                </el-table-column>
                <el-table-column
                        label="时间"
                        :formatter="formatDate"
                        prop="createTime">
                </el-table-column>
                <el-table-column
                        width="100"
                        align="right">
                    <template #header>
                        <el-button @click="addUserAccountFlowDialogVisible = true" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button @click="handleDeleteUserAccountFlow(scope.$index, scope.row.id)" size="small" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @current-change="handleUserAccountFlowListCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :total="userAccountFlowListRowCount"
                    :page-size="pageSize"
                    :current-page="userAccountFlowListCurrentPage"
            />
        </el-dialog>
        <el-dialog
                :close-on-click-modal="!addUserAccountFlowRequesting"
                :close-on-press-escape="false"
                :show-close="!addUserAccountFlowRequesting"
                v-model="addUserAccountFlowDialogVisible"
                width="400px">
            <el-form
                    ref="addUserAccountFlow"
                    :rules="addUserAccountFlowRules"
                    :label-position="left"
                    label-width="80px"
                    :model="addUserAccountFlowFormData"
                    size="small"
                    v-loading="addUserAccountFlowRequesting">
                <el-form-item label="账单类型" prop="type">
                    <el-select v-model="addUserAccountFlowFormData.type" @change="userAccountFlowTypeChange" placeholder="请选择类型" :style="{width:'100%'}">
                        <el-option value="0" label="广告收入"></el-option>
                        <el-option value="1" label="团队收入"></el-option>
                        <el-option value="2" label="直推奖"></el-option>
                        <el-option value="6" label="销售奖"></el-option>
                        <el-option value="7" label="广告收入(OCPA助力提升)"></el-option>
                        <el-option value="8" label="团队收入(LEAP额外奖励)"></el-option>
                        <el-option value="9" label="直推奖(LEAP额外奖励)"></el-option>
                        <el-option value="10" label="销售奖(LEAP额外奖励)"></el-option>
                        <el-option value="12" label="首购奖"></el-option>
                        <el-option value="13" label="购买内存"></el-option>
                        <el-option value="14" label="内存收入"></el-option>
                        <el-option value="16" label="周奖励"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间" prop="time">
                    <el-date-picker v-model="addUserAccountFlowFormData.time" :type="datePickerType" :value-format="datePickerValueFormat" placeholder="请选择时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="金额" prop="amount">
                    <el-input
                            @keyup.enter="addUserAccountFlow"
                            v-model="addUserAccountFlowFormData.amount"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addUserAccountFlowRequesting" @click="addUserAccountFlowDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addUserAccountFlowRequesting" @click="addUserAccountFlow" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
                :title="clickUserRow.username" v-model="userWithdrawalListDialogVisible"
                width="800px">
            <el-table
                    :data="userWithdrawalList"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        label="金额"
                        prop="amount">
                </el-table-column>
                <el-table-column
                        label="手续费"
                        prop="commission">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="状态"
                        prop="status">
                    <span v-if="scope.row.status === 0">等待通过</span>
                    <span v-else-if="scope.row.status === 1" :style="{color:'#E6A23C'}">平台付款中</span>
                    <span v-else-if="scope.row.status === 2" :style="{color:'#67C23A'}">成功</span>
                    <span v-else-if="scope.row.status === 3" :style="{color:'#F56C6C'}">失败</span>
                    <span v-else-if="scope.row.status === 4" :style="{color:'#909399'}">薅羊毛</span>
                </el-table-column>
                <el-table-column
                        label="时间"
                        :formatter="formatDate"
                        prop="createTime">
                </el-table-column>
                <el-table-column
                        width="100"
                        align="right">
                    <template #header>
                        <el-button @click="addUserWithdrawalDialogVisible = true" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button @click="handleDeleteUserWithdrawal(scope.$index, scope.row.id)" size="small" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @current-change="handleUserWithdrawalListCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :total="userWithdrawalListRowCount"
                    :page-size="pageSize"
                    :current-page="userWithdrawalListCurrentPage"
            />
        </el-dialog>
        <el-dialog
                :close-on-click-modal="!addUserWithdrawalRequesting"
                :close-on-press-escape="false"
                :show-close="!addUserWithdrawalRequesting"
                v-model="addUserWithdrawalDialogVisible"
                width="400px">
            <el-form
                    ref="addUserWithdrawal"
                    :rules="addUserWithdrawalRules"
                    :label-position="left"
                    label-width="80px"
                    :model="addUserWithdrawalFormData"
                    size="small"
                    v-loading="addUserWithdrawalRequesting">
                <el-form-item label="时间" prop="time">
                    <el-date-picker v-model="addUserWithdrawalFormData.time" type="datetime" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="金额" prop="amount">
                    <el-input
                            @keyup.enter="addUserWithdrawal"
                            v-model="addUserWithdrawalFormData.amount"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addUserWithdrawalRequesting" @click="addUserWithdrawalDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addUserWithdrawalRequesting" @click="addUserWithdrawal" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <UserWebsiteListDialog
            v-model="userWebsiteDialogVisible"
            :user-data="clickUserRow"
            :website-count-change="(count) => {clickUserRow.websiteCount += count}"></UserWebsiteListDialog>
        <TeamMemberDialog
            v-model="teamMemberDialogModel"
            :user-id="clickUserRow.id"
            :title="clickUserRow.username"></TeamMemberDialog>
        <UserBankInfoDialog
            v-model="userBankInfoDialogModel"
            :user-id="clickUserRow.id"
            :title="clickUserRow.username"></UserBankInfoDialog>
        <UserHierarchyDialog
            v-model="userHierarchyDialogModel"
            :userData="clickUserRow"
            :change-hierarchy-call-back="changeHierarchyCallBack"></UserHierarchyDialog>
        <AddUserCouponDialog
            v-model="addUserCouponDialogModel"
            :confirm-callback="addUserCoupon"
            :cancel-callback="() => {addUserCouponDialogModel = false}"></AddUserCouponDialog>
    </SalesmanFrame>
</template>

<script>
import SalesmanFrame from "../components/SalesmanFrame";
import UserRelationshipDropdown from "../components/UserRelationshipDropdown";
import TeamMemberDialog from "../components/TeamMemberDialog";
import MobileNumberSpan from "../components/MobileNumberSpan";
import UserBankInfoDialog from "../components/UserBankInfoDialog";
import UserHierarchyDialog from "../components/UserHierarchyDialog";
import AddUserCouponDialog from "../components/AddUserCouponDialog";
import UserWebsiteListDialog from "../components/UserWebsiteListDialog";
export default {
    name: "UserList",
    components: {
        UserWebsiteListDialog,
        AddUserCouponDialog,
        UserHierarchyDialog,
        UserBankInfoDialog, MobileNumberSpan, TeamMemberDialog, UserRelationshipDropdown, SalesmanFrame},
    mounted() {
        this.searchWord = this.$route.params.searchWord;
        this.currentPage = this.$route.params.currentPage;
        this.loadUser(this.currentPage);
    },
    data() {
        let passwordConfirmCheck = (rule, value, callback) => {
            if (value === "") {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        let validateAmount = (rule, value, callback) => {
            if(value.trim() === "") {
                callback(new Error("请输入金额"));
            }
            if(isNaN(value)) {
                callback(new Error("请输入正确的金额"));
            }
            if((parseFloat(value) <= 0) || (parseFloat(value) > 9999999999.99)) {
                callback(new Error("金额范围只能是 0 - 10000000000"));
            }
            callback();
        }
        return {
            cardLoading: true,
            addDialogVisible: false,
            userWebsiteDialogVisible: false,
            websiteTypeList: [],
            clickUserRow: {},
            formRequesting: false,
            addUserRequesting: false,
            tableData: [],
            rowCount: 0,
            pageSize: 10,
            currentPage: 1,
            searchWord: "",
            userAccountFlowListDialogVisible: false,
            userAccountFlowList: [],
            userAccountFlowListRowCount: 0,
            userAccountFlowListCurrentPage: 1,
            formData: {
                username: "",
                password: "",
                passwordConfirm: "",
                leaderName: ""
            },
            rules: {
                username: [
                    { required: true, message: "账号不能为空", trigger: "change" },
                    { min: 6, max: 100, message: '长度在 6 到 100 个字符', trigger: 'change' }
                ],
                password: [
                    { required: true, message: "密码不能为空", trigger: "change" },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'change' }
                ],
                passwordConfirm: [
                    { required: true, message: "请再次输入密码", trigger: "change" },
                    { validator: passwordConfirmCheck, trigger: "change" }
                ],
            },
            datePickerType: "date",
            datePickerValueFormat: "YYYY-MM-DD",
            addUserAccountFlowDialogVisible: false,
            addUserAccountFlowRequesting: false,
            addUserAccountFlowFormData: {},
            addUserAccountFlowRules: {
                type: [
                    { required: true, message: "请选择账单类型", trigger: "change" }
                ],
                time: [
                    { required: true, message: "请选择时间", trigger: "change" }
                ],
                amount: [
                    { required: true, message: "请输入金额", trigger: "change" },
                    { validator: validateAmount, trigger: "change" }
                ]
            },
            userWithdrawalListDialogVisible: false,
            addUserWithdrawalDialogVisible: false,
            addUserWithdrawalRequesting: false,
            userWithdrawalList: [],
            userWithdrawalListRowCount: 0,
            userWithdrawalListCurrentPage: 1,
            addUserWithdrawalFormData: {},
            addUserWithdrawalRules: {
                time: [
                    { required: true, message: "请选择时间", trigger: "change" }
                ],
                amount: [
                    { required: true, message: "请输入金额", trigger: "change" },
                    { validator: validateAmount, trigger: "change" }
                ]
            },
            teamMemberDialogModel: false,
            userBankInfoDialogModel: false,
            userHierarchyDialogModel: false,
            userHierarchyTableData: [],
            addUserCouponDialogModel: false
        }
    },
    methods: {
        handleCurrentChange(currentPage) {
            this.$router.push("/salesman/user_list/" + currentPage + "/" + this.searchWord);
            this.loadUser(currentPage);
        },
        handleSearch() {
            document.location.href = "/salesman/user_list/1/" + this.searchWord;
        },
        loadUser(currentPage) {
            let $this = this;
            this.cardLoading = true;
            this.axios.post("/salesman/user_list",{
                "currentPage": currentPage,
                "searchWord": this.$route.params.searchWord
            }).then(function(response) {
                $this.tableData = response.data.dataList;
                $this.rowCount = response.data.rowCount;
                $this.pageSize = response.data.pageSize;
                $this.currentPage = response.data.currentPage;
                $this.cardLoading = false;
            });
        },
        addUser(formName) {
            this.$refs[formName].validate((valid) => {
                if(valid) {
                    this.addUserRequesting = true;
                    let $this = this;
                    this.axios.post("/salesman/add_user",this.formData).then(function(response) {
                        $this.addUserRequesting = false;
                        $this.addDialogVisible = false;
                        if(response.data.result) {
                            $this.$message.success({
                                message: '添加成功',
                                type: 'success'
                            });
                            if($this.tableData.length === $this.pageSize) {
                                $this.tableData.pop();
                            }
                            response.data.data.websiteCount = 0;
                            if(response.data.data.inviterId != null) {
                                response.data.data.leader = 0;
                            }
                            $this.tableData.unshift(response.data.data);
                            $this.rowCount++;
                        } else {
                            $this.$message.error({
                                message: '添加失败,' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        clickUserWebsite(row) {
            this.clickUserRow = row;
            this.userWebsiteDialogVisible = true;
        },
        handleCommand(command) {
            let $this = this;
            this.clickUserRow = command.rowData;
            switch(command.method) {
                case "changeBalance":
                    this.$prompt("请输入新余额", "提示: " + command.rowData.username, {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        inputPattern: /^[+]?(\d+)$|^[+]?(\d+\.\d+)$/,
                        inputErrorMessage: "请输入数字"
                    }).then(({ value }) => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/change_user_balance",{
                            "userId": command.rowData.id,
                            "balance": value
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.$message({
                                    type: "success",
                                    message: response.data.message
                                });
                                $this.tableData[command.index].balance = response.data.data;
                            } else {
                                $this.$message.error({
                                    message: "操作失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break
                case "addUserCoupon":
                    this.addUserCouponDialogModel = true;
                    break;
                case "banUser":
                    this.$confirm("确定封禁 " + command.rowData.username + " 吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/ban_user",{
                            "userId": command.rowData.id
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.tableData[command.index].status = response.data.data;
                                $this.$message.success({
                                    message: '封禁成功',
                                    type: 'success'
                                });
                            } else {
                                $this.$message.error({
                                    message: '操作失败,' + response.data.message,
                                    type: 'error'
                                });
                            }
                        });
                    });
                    break;
                case "addLeader":
                    this.$prompt("请输入上级用户名", "提示: " + command.rowData.username, {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        inputPattern: /\S.+/,
                        inputErrorMessage: "不能留空"
                    }).then(({ value }) => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/add_user_leader",{
                            "userId": command.rowData.id,
                            "leaderName": value
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.$message({
                                    type: "success",
                                    message: "添加成功!"
                                });
                                $this.tableData[command.index].leader = response.data.data;
                            } else {
                                $this.$message.error({
                                    message: "添加失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break;
                case "addTime":
                    this.$prompt("请输入天数", "提示: " + command.rowData.username, {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        inputPattern: /^[1-9][0-9]?$/,
                        inputErrorMessage: "只能填入 1 - 99"
                    }).then(({ value }) => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/add_user_time",{
                            "userId": command.rowData.id,
                            "day": value
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.$message({
                                    type: "success",
                                    message: "添加成功!"
                                });
                                $this.tableData[command.index].expireTime = response.data.data;
                            } else {
                                $this.$message.error({
                                    message: "添加失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break;
                case "addSpreadRevenue":
                    this.$prompt("请输入奖励金额", "提示: " + command.rowData.username, {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                    }).then(({ value }) => {
                        if(isNaN(value)) {
                            $this.$message.error({
                                message: "添加失败,只能填入数字",
                                type: "error"
                            });
                            return;
                        }
                        if((value > 99999) || (value <= 0)) {
                            $this.$message.error({
                                message: "添加失败,只能填入 0 - 99999",
                                type: "error"
                            });
                            return;
                        }
                        this.cardLoading = true;
                        this.axios.post("/salesman/add_user_spread_revenue",{
                            "userId": command.rowData.id,
                            "amount": value
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.$message({
                                    type: "success",
                                    message: "添加成功!"
                                });
                                $this.tableData[command.index].balance = response.data.data
                            } else {
                                $this.$message.error({
                                    message: "添加失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break;
                case "changeRevenueRate":
                    this.$prompt("请输入收益比例", "提示: " + command.rowData.username, {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        inputPattern: /(^0\.[0-9]{0,3}[1-9]$)|(^[1-9][0-9]{0,2}\.[0-9]?[1-9]$)|(^[1-9][0-9]{0,2}$)/,
                        inputErrorMessage: "只能填入 0 - 1000 之间的数"
                    }).then(({ value }) => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/change_user_revenue_rate",{
                            "userId": command.rowData.id,
                            "rate": value
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.$message({
                                    type: "success",
                                    message: "修改成功!"
                                });
                                $this.tableData[command.index].revenueRate = response.data.data
                            } else {
                                $this.$message.error({
                                    message: "修改失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break;
                case "changePassword":
                    this.$prompt("请输入新密码", "提示: " + command.rowData.username, {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        inputPattern: /^\S{6,26}$/,
                        inputErrorMessage: "不能少于6位数"
                    }).then(({ value }) => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/change_user_password",{
                            "userId": command.rowData.id,
                            "newPassword": value
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.$message({
                                    type: "success",
                                    message: "修改成功!"
                                });
                                $this.tableData[command.index].password = response.data.data
                            } else {
                                $this.$message.error({
                                    message: "修改失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break;
                case "viewPassword":
                    this.$alert(command.rowData.username, command.rowData.password, {
                        confirmButtonText: "确定",
                        closeOnClickModal: true,
                        center: true
                    });
                    break;
                case "userAccountFlowList":
                    this.loadUserAccountFlowList(1);
                    this.userAccountFlowListDialogVisible = true;
                    break;
                case "userWithdrawalList":
                    this.loadUserWithdrawalList(1);
                    this.userWithdrawalListDialogVisible = true;
                    break;
                case "activeTrialUser":
                    this.$confirm("确定激活 " + command.rowData.username + " 吗?用户数据将被清空。", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this.cardLoading = true;
                        this.axios.post("/salesman/active_trial_user",{
                            "userId": command.rowData.id
                        }).then(function(response) {
                            $this.cardLoading = false;
                            if(response.data.result) {
                                $this.tableData[command.index].balance = 0;
                                $this.tableData[command.index].expireTime = null;
                                $this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                            } else {
                                $this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    });
                    break;
                case "bankInfo":
                    this.userBankInfoDialogModel = true;
                    break;
            }
        },
        handleUserAccountFlowListCurrentChange(currentPage) {
            this.loadUserAccountFlowList(currentPage);
        },
        loadUserAccountFlowList(currentPage) {
            this.axios.post("/salesman/user_account_flow_list", {
                "userId": this.clickUserRow.id,
                "currentPage": currentPage
            }).then((response) => {
                this.userAccountFlowList = response.data.dataList;
                this.userAccountFlowListRowCount = response.data.rowCount;
                this.pageSize = response.data.pageSize;
                this.userAccountFlowListCurrentPage = response.data.currentPage;
            });
        },
        handleDeleteUserAccountFlow(index, id) {
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.post("/salesman/del_user_account_flow", {
                    "id": id
                }).then((response) => {
                    if(response.data.result) {
                        this.userAccountFlowList.splice(index, 1);
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                        this.userAccountFlowListRowCount--;
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            });
        },
        userAccountFlowTypeChange(value) {
            this.addUserAccountFlowFormData.time = "";
            switch(value) {
                case "0":
                case "1":
                case "7":
                case "8":
                case "14":
                    this.datePickerType = "date";
                    this.datePickerValueFormat = "YYYY-MM-DD";
                    break;
                default:
                    this.datePickerType = "datetime";
                    this.datePickerValueFormat = "YYYY-MM-DD HH:mm:ss";
            }
        },
        addUserAccountFlow() {
            this.$refs["addUserAccountFlow"].validate((value) => {
                if(value) {
                    this.addUserAccountFlowRequesting = true;
                    this.addUserAccountFlowFormData.userId = this.clickUserRow.id;
                    this.axios.post("/salesman/add_user_account_flow", this.addUserAccountFlowFormData).then((response) => {
                        this.addUserAccountFlowRequesting = false;
                        if(response.data.result) {
                            this.$message({
                                type: "success",
                                message: response.data.message
                            });
                            this.userAccountFlowList.unshift(response.data.data);
                            this.userAccountFlowListRowCount++;
                            this.addUserAccountFlowDialogVisible = false;
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        handleUserWithdrawalListCurrentChange(currentPage) {
            this.loadUserWithdrawalList(currentPage);
        },
        loadUserWithdrawalList(currentPage) {
            this.axios.post("/salesman/user_withdrawal_list", {
                "userId": this.clickUserRow.id,
                "currentPage": currentPage
            }).then((response) => {
                this.userWithdrawalList = response.data.dataList;
                this.userWithdrawalListRowCount = response.data.rowCount;
                this.pageSize = response.data.pageSize;
                this.userWithdrawalListCurrentPage = response.data.currentPage;
            });
        },
        handleDeleteUserWithdrawal(index, id) {
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.post("/salesman/del_user_withdrawal", {
                    "id": id
                }).then((response) => {
                    if(response.data.result) {
                        this.userWithdrawalList.splice(index, 1);
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                        this.userWithdrawalListRowCount--;
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            });
        },
        addUserWithdrawal() {
            this.$refs["addUserWithdrawal"].validate((value) => {
                if(value) {
                    this.addUserWithdrawalRequesting = true;
                    this.addUserWithdrawalFormData.userId = this.clickUserRow.id;
                    this.axios.post("/salesman/add_user_withdrawal", this.addUserWithdrawalFormData).then((response) => {
                        this.addUserWithdrawalRequesting = false;
                        if(response.data.result) {
                            this.$message({
                                type: "success",
                                message: response.data.message
                            });
                            this.userWithdrawalList.unshift(response.data.data);
                            this.userWithdrawalListRowCount++;
                            this.addUserWithdrawalDialogVisible = false;
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        addUserCoupon(formData) {
            formData["userId"] = this.clickUserRow.id;
            this.axios.post("/salesman/add_user_coupon", formData).then((response) => {
                this.addUserCouponDialogModel = false;
                if(response.data.result) {
                    this.$message({
                        type: "success",
                        message: response.data.message
                    });
                } else {
                    this.$message.error({
                        message: response.data.message,
                        type: "error"
                    });
                }
            });
        },
        teamMemberHandle(row) {
            this.teamMemberDialogModel = true;
            this.clickUserRow = row;
        },
        clickUserHierarchy(data) {
            this.clickUserRow = data;
            this.userHierarchyDialogModel = true;
        },
        changeHierarchyCallBack(hierarchyName) {
            this.clickUserRow.hierarchyName = hierarchyName;
        },
        userAccountFlowTypeToName(row, column, cellValue) {
            if(cellValue == null) {
                return "";
            }
            let name ="";
            switch(cellValue) {
                case 0:
                    name = "广告收入";
                    break;
                case 1:
                    name = "团队收入";
                    break;
                case 2:
                    name = "直推奖";
                    break;
                case 3:
                    name = "提现";
                    break;
                case 6:
                    name = "销售奖";
                    break;
                case 7:
                    name = "广告收入(OCPA助力提升)";
                    break;
                case 8:
                    name = "团队收入(LEAP额外奖励)";
                    break;
                case 9:
                    name = "直推奖(LEAP额外奖励)";
                    break;
                case 10:
                    name = "销售奖(LEAP额外奖励)";
                    break;
                case 11:
                    name = "会员升级奖励";
                    break;
                case 12:
                    name = "首购奖";
                    break;
                case 13:
                    name = "购买内存";
                    break;
                case 14:
                    name = "内存收入";
                    break;
                case 16:
                    name = "周奖励";
                    break;
                default:
                    name = "其他";
                    break;
            }
            return name;
        }
    }
}
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
.search-input {
    width: 200px;
    float: right;
}
</style>