<template>
    <el-dialog
            :close-on-click-modal="!loading"
            :close-on-press-escape="false"
            :show-close="false"
            v-loading="loading"
            :model="model"
            @open="openCallback"
            width="400px">
        <el-form
                ref="addUserWebsiteForm"
                :rules="formRules"
                :label-position="left"
                label-width="80px"
                :model="formData"
                size="small">
            <el-form-item label="网站类型" prop="websiteTypeId">
                <el-select v-model="formData.websiteTypeId" placeholder="请选择网站类型" :style="{width:'100%'}">
                    <el-option v-for="row in websiteTypeList" :key="row.id" :value="row.id" :label="row.alias + '(' +row.price + ' - ' + (row.revenueRate ? row.revenueRate : row.fixedRevenue) + ')'">
                        {{row.alias}}({{row.price}} - {{row.revenueRate ? row.revenueRate : row.fixedRevenue}})
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数量" prop="count">
                <el-input
                        @keyup.enter="addUserWebsite()"
                        v-model.number="formData.count"
                        clearable
                />
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="loading" @click="cancelCallback" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="loading" @click="addUserWebsite()" size="small">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "AddUserWebsiteDialog",
        props: {
            model: Boolean,
            userData: Object,
            cancelCallback: Function,
            addWebsiteCallback: Function[Number]
        },
        data() {
            return {
                loading: false,
                websiteTypeList: [],
                formData: {
                    count: 1,
                    websiteTypeId: ""
                },
                formRules: {
                    count: [
                        { required: true, message: "数量不能为空", trigger: "change" },
                        { type: "number", message: "数量必须为数字值"}
                    ],
                    websiteTypeId: [
                        { required: true, message: "请选择网站类型", trigger: "change" },
                    ]
                },
            }
        },
        methods: {
            openCallback() {
                this.axios.post("/salesman/get_website_type_list").then((response) => {
                    this.websiteTypeList = response.data;
                });
            },
            addUserWebsite() {
                this.$refs["addUserWebsiteForm"].validate((value) => {
                    if(value) {
                        this.loading = true;
                        this.axios.post("/salesman/add_user_website", {
                            "userId": this.userData.id,
                            "count": this.formData.count,
                            "websiteTypeId": this.formData.websiteTypeId
                        }).then((response) => {
                            this.loading = false;
                            if(response.data.result) {
                                this.$message({
                                    type: "success",
                                    message: response.data.message
                                });
                                this.addWebsiteCallback(response.data.data);
                            } else {
                                this.$message.error({
                                    message: "添加失败," + response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>