<template>
    <user-header></user-header>
    <section id="s-1">
        <div>
            <div class="s1-content">
                <p class="title-1">Contact</p>
                <h1>We’d be tickled pink to hear from you.</h1>
            </div>
        </div>
    </section>
    <section id="s-2">
        <p>If you are ready to chat about your individual situation and find out how our scalable, flexible solutions can help you receive the expert advice and support you’re after, please contact us using this form.</p>
        <div>
            <fieldset class="fieldset-item">
                <legend class="legend-title">
                    Name
                    <span aria-hidden="true">*</span>
                </legend>
                <div class="field-name">
                    <label class="caption">
                        <input v-model="firstName" name="fname" x-autocompletetype="given-name" type="text" spellcheck="false" maxlength="30" data-title="First" aria-required="true">
                        <span class="caption-text">First Name</span>
                    </label>
                </div>
                <div class="field-name field-name-right">
                    <label class="caption">
                        <input v-model="lastName" name="lname" x-autocompletetype="surname" type="text" spellcheck="false" maxlength="30" data-title="Last" aria-required="true">
                        <span class="caption-text">Last Name</span>
                    </label>
                </div>
            </fieldset>
            <div class="fieldset-item">
                <label class="legend-title" for="email-yui_3_17_2_1_1554218195091_8244-field">
                    Email address
                    <span class="required" aria-hidden="true">*</span>
                </label>
                <input v-model="email" id="email-yui_3_17_2_1_1554218195091_8244-field" name="email" type="email" autocomplete="email" spellcheck="false" aria-required="true">
            </div>
            <fieldset class="fieldset-item">
                <legend class="legend-title">
                    I’m interested in help with...
                </legend>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Ad serving"> Ad serving</label></div>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Ad technology"> Ad technology</label></div>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Google support"> Google support</label></div>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Consulting"> Consulting</label></div>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Programmatic"> Programmatic</label></div>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Training"> Training</label></div>
                <div class="option"><label><input type="checkbox" name="checkbox-yui_3_17_2_1_1581985761207_31478-field" value="Data studio"> Data studio</label></div>
            </fieldset>
            <div class="fieldset-item">
                <label class="legend-title" for="textarea-yui_3_17_2_1_1554218195091_8246-field">
                    Additional message (optional)
                </label>
                <textarea class="field-element " id="textarea-yui_3_17_2_1_1554218195091_8246-field"></textarea>
            </div>
            <div @click="submit" class="card-block-btn">
                <a>{{submitBtnText}}</a>
            </div>
        </div>
    </section>
    <user-footer></user-footer>
</template>

<script>
    import UserHeader from "./UserHeader";
    import UserFooter from "./UserFooter";
    export default {
        name: "ContactUs",
        components: {UserFooter, UserHeader},
        data() {
            return {
                firstName: "",
                lastName: "",
                email: "",
                submitBtnText: "Submit >",
                requesting: false
            }
        },
        methods : {
            submit() {
                if(this.requesting) {
                    return;
                }
                if(this.firstName.trim() === "" || this.lastName.trim() === "") {
                    this.$notify({
                        title: "error",
                        message: "Please enter your name.",
                        duration: 2000,
                        type: "error"
                    });
                    return;
                }
                if(this.email.trim() === "") {
                    this.$notify({
                        title: "error",
                        message: "Please enter your email.",
                        duration: 2000,
                        type: "error"
                    });
                    return;
                }
                this.requesting = true;
                this.submitBtnText = "requesting...";
                setTimeout(() => {
                    this.firstName = "";
                    this.lastName = "";
                    this.email = "";
                    this.submitBtnText = "Submit >";
                    this.requesting = false;
                    this.$message({
                        type: "success",
                        message: "Thank you for your contact."
                    });
                }, 2000);
            }
        }
    }
</script>

<style scoped>
* {
    font-family: sofia-pro;
}
h1 {
    font-family: 'Rozha One',RozhaOne-Regular,serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .2px;
    text-transform: none;
    line-height: 1.1;
    font-size: calc(26px);
}
h2,h3 {
    font-family: sofia-pro;
    font-weight: 900;
    font-style: normal;
    letter-spacing: .2px;
    text-transform: none;
}
p {
    font-family: sofia-pro;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0.9rem;
}
#s-1 {
    background-color: #ecb09a;
    color: #fff;
}
#s-1>div {
    background-image: url("../../assets/web-bg-1.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 90px 6vw 13.2vmax;
}
.title-1 {
    font-weight: 600;
}
#s-2 {
    padding: 6vw 6vw;
}
#s-2 p {
    text-align: center;
    font-weight: 600;
    color: #2f2861;
}
.fieldset-item {
    margin-bottom: 24px;
}
fieldset {
    border: none;
}
.field-name {
    width: 49%;
    float: left;
}
.field-name-right {
    margin-left: 2%;
}
.legend-title {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 10px;
}
input {
    width: 100%;
    padding: 12px;
    margin: 6px 0 4px;
    border: 1px solid #ccc;
    background: #fafafa;
    color: #000;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
}
.caption-text {
    font-weight: 300;
    font-size: 13px;
}
.option {
    display: inline-block;
    margin: 6px !important;
    font-size: 13px !important;
    width: 45%;
    font-weight: 300;
}
.option input {
    width: auto;
    margin-right: 5px;
}
textarea {
    width: 100%;
    padding: 12px;
    margin: 6px 0 4px;
    border: 1px solid #ccc;
    background: #fafafa;
    color: #000;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
    min-height: 100px;
    resize: vertical;
}
</style>