<template>
    <el-container>
        <el-aside width="200px">
            <el-menu :default-active="defaultActive">
                <router-link to="/salesman/home">
                    <el-menu-item index="home">
                        <i class="el-icon-house"></i>
                        <template #title>主页</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/salesman/user_list">
                    <el-menu-item index="userList">
                        <i class="el-icon-user"></i>
                        <template #title>用户列表</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/salesman/order_list/pending/1/today">
                    <el-menu-item index="orderList">
                        <i class="el-icon-tickets"></i>
                        <template #title>订单</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/salesman/validate_user_website">
                    <el-menu-item index="validateUserWebsite">
                        <i class="el-icon-circle-check"></i>
                        <template #title>验证网站</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/salesman/withdrawal_list/pending">
                    <el-menu-item index="withdrawalList">
                        <i class="el-icon-coin"></i>
                        <template #title>提现</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/salesman/ranking_list">
                    <el-menu-item index="rankingList">
                        <i class="el-icon-star-off"></i>
                        <template #title>排行榜</template>
                    </el-menu-item>
                </router-link>
            </el-menu>
        </el-aside>
        <el-container class="main-side">
            <el-header>
                <span class="server-time">{{serverTime}}</span>
                <span>{{username}}</span>
                <el-dropdown>
                    <i class="el-icon-user-solid" style="margin-left: 10px;color: #FFF;font-size: 30px;vertical-align: middle;"></i>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="logout">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-header>
            <el-main>
                <slot></slot>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        name: "SalesmanFrame",
        props: {
            defaultActive: String
        },
        data() {
            return {
                username: "",
                serverTime: "",
                intervalID: null
            }
        },
        mounted() {
            let $this = this;
            this.username = localStorage.getItem("salesmanName");
            this.getServerTime();
            document.addEventListener("visibilitychange", function() {
                if((document.visibilityState === "visible") || !document.hidden) {
                    clearInterval($this.intervalID);
                    $this.getServerTime();
                }
            });
        },
        methods: {
            logout() {
                localStorage.removeItem("salesmanName");
                document.location.href = "/salesman";
            },
            getServerTime() {
                let serverTime = Date.now() - 3600 * 1000;
                this.intervalID = setInterval(() => {
                    serverTime += 1000;
                    this.serverTime = this.formatDate(null, null, serverTime);
                }, 1000);
            }
        }
    }
</script>

<style scoped>
.server-time {
    font-size: 20px;
    float: left;
}
</style>