<template>
    <ManagerFrame default-active="payment-website">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        width="150"
                        align="center"
                        label="图片">
                    <el-image
                            :style="{width:'60px'}"
                            :hide-on-click-modal="true"
                            :src="scope.row.img"
                            :preview-src-list="[scope.row.img]">
                        <template #error>
                            <span :style="{color:'#909399', cursor:'pointer'}">无</span>
                        </template>
                    </el-image>
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="name"
                        label="名称">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="alias"
                        label="中文名称">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="revenueRate"
                        label="收益比例">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="fixedRevenue"
                        label="固定收益">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="fixedRevenueFloat"
                        label="固定浮动">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="price"
                        label="价格">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="validDays"
                        label="有效天数">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="quantityLimit"
                        label="限购数量">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="center"
                        label="能否续费">
                    <span v-if="scope.row.canRenew" :style="{color:'#67C23A'}">是</span>
                    <span v-else :style="{color:'#F56C6C'}">否</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="center"
                        label="状态">
                    <span v-if="scope.row.active" :style="{color:'#67C23A'}">正常</span>
                    <span v-else :style="{color:'#F56C6C'}">禁用</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="center"
                        label="上架">
                    <span v-if="scope.row.onSale" :style="{color:'#67C23A'}">正常</span>
                    <span v-else :style="{color:'#F56C6C'}">下架</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="center"
                        label="默认">
                    <span v-if="scope.row.clientDefault" :style="{color:'#67C23A'}">默认</span>
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="dailyMinimumRevenue"
                        label="最低收益">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="dailyMaximumRevenue"
                        label="最高收益">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="right">
                    <el-button size="mini" @click="handleEdit(scope.$index)">修改</el-button>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                :close-on-click-modal="!requesting"
                :close-on-press-escape="false"
                :show-close="!requesting"
                v-model="dialogVisible"
                width="500px">
            <el-form
                    ref="updateForm"
                    :rules="updateRules"
                    label-position="left"
                    label-width="100px"
                    :model="formData"
                    size="small"
                    v-loading="requesting">
                <el-form-item label="图片" prop="img">
                    <el-image
                            :style="{width:'60px'}"
                            :hide-on-click-modal="true"
                            :src="formData.img"
                            :preview-src-list="[formData.img]">
                        <template #error>
                            <span :style="{color:'#909399', cursor:'pointer'}">无</span>
                        </template>
                    </el-image>
                    <input @change="imgInputChange($event)" type="file" accept="image/jpeg, image/png, image/jpg" />
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.name"
                            placeholder="请输入名称"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="中文名称" prop="alias">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.alias"
                            placeholder="请输入中文名称"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="收益比例" prop="revenueRate">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.revenueRate"
                            placeholder="请输入收益比例"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.price"
                            placeholder="请输入价格"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="有效天数" prop="validDays">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model.number="formData.validDays"
                            placeholder="请输入有效天数"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="限购数量" prop="quantityLimit">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model.number="formData.quantityLimit"
                            placeholder="请输入限购数量"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="能否续费" prop="canRenew">
                    <el-switch v-model="formData.canRenew" active-color="#67C23A" inactive-color="#F56C6C" active-text="是" inactive-text="否" inline-prompt></el-switch>
                </el-form-item>
                <el-form-item label="状态" prop="active">
                    <el-switch v-model="formData.active" active-color="#67C23A" inactive-color="#F56C6C" active-text="正常" inactive-text="禁用" inline-prompt></el-switch>
                </el-form-item>
                <el-form-item label="是否上架" prop="onSale">
                    <el-switch v-model="formData.onSale" active-color="#67C23A" inactive-color="#F56C6C" active-text="上架" inactive-text="下架" inline-prompt></el-switch>
                </el-form-item>
                <el-form-item label="默认选中" prop="clientDefault">
                    <el-switch v-model="formData.clientDefault" active-color="#67C23A" inactive-color="#F56C6C" active-text="是" inactive-text="否" inline-prompt></el-switch>
                </el-form-item>
                <el-form-item label="最低收益" prop="dailyMinimumRevenue">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.dailyMinimumRevenue"
                            placeholder="请输入最低收益"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="最高收益" prop="dailyMaximumRevenue">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.dailyMaximumRevenue"
                            placeholder="请输入最高收益"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="固定收益" prop="fixedRevenue">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.fixedRevenue"
                            placeholder="请输入固定收益"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="固定收益浮动" prop="fixedRevenueFloat">
                    <el-input
                            @keyup.enter="handleSave"
                            v-model="formData.fixedRevenueFloat"
                            placeholder="请输入固定收益浮动"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="requesting" @click="dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="requesting" @click="handleSave" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
import ManagerFrame from "../components/ManagerFrame";
export default {
    name: "WebsitePrice",
    components: {ManagerFrame},
    mounted() {
        this.axios.post("/manager/website_price_setting").then((response) => {
            this.tableData = response.data;
            this.cardLoading = false;
        });
    },
    data() {
        return {
            cardLoading: true,
            tableData: [],
            requesting: false,
            formData: {},
            dialogVisible: false,
            selectedRowIndex: -1,
            updateRules: {
                name: [
                    { required: true, message: "请输入名称", trigger: "change" }
                ],
                alias: [
                    { required: true, message: "请输入中文名称", trigger: "change" }
                ],
                price: [
                    { required: true, message: "请输入价格", trigger: "change" },
                    { pattern: /^[0-9]{1,8}(\.[0-9]?[1-9])?$/, message: "只能输入 0 - 100000000 之间的数", trigger: "change" }
                ],
                validDays: [
                    { required: true, message: "请输入有效天数", trigger: "change" },
                    { type: "integer", message: "只能输入整数", trigger: "change" }
                ],
                quantityLimit: [
                    { required: true, message: "请输入限购数量", trigger: "change" },
                    { type: "integer", message: "只能输入整数", trigger: "change" }
                ],
                dailyMinimumRevenue: [
                    { required: true, message: "请输入最低收益", trigger: "change" },
                    { pattern: /(^0\.[0-9]?[1-9]$)|(^[1-9][0-9]{0,6}(\.[0-9]?[1-9])?$)/, message: "只能输入 0 - 100000000 之间的数", trigger: "change" }
                ],
                dailyMaximumRevenue: [
                    { required: true, message: "请输入最低收益", trigger: "change" },
                    { pattern: /(^0\.[0-9]?[1-9]$)|(^[1-9][0-9]{0,6}(\.[0-9]?[1-9])?$)/, message: "只能输入 0 - 100000000 之间的数", trigger: "change" }
                ],
            }
        }
    },
    methods: {
        handleEdit(index) {
            this.selectedRowIndex = index;
            this.formData = JSON.parse(JSON.stringify(this.tableData[index]));
            this.dialogVisible = true;
        },
        imgInputChange(e) {
            let $this = this;
            let input = e.target;
            let file = input.files[0];
            if(file.size / 1024 /1024 > 1) {
                this.$message.error({
                    message: "图片大小不能大于1M",
                    type: "error"
                });
                input.value = "";
                return;
            }
            let imgFile = new FileReader();
            imgFile.readAsDataURL(file);
            imgFile.onload = function() {
                $this.formData.img = this.result;
            }
        },
        handleSave() {
            this.$refs["updateForm"].validate((valid) => {
                if(valid) {
                    this.requesting = true;
                    this.axios.post("/manager/update_website_price_setting", this.formData).then((response) => {
                        this.requesting = false;
                        this.dialogVisible = false;
                        if(response.data.result) {
                            this.tableData[this.selectedRowIndex] = this.formData;
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>