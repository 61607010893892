<template>
    <ManagerFrame default-active="invite-spread">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="名字">
                    <span>{{scope.row.alias}}({{scope.row.price}} - {{scope.row.revenueRate}})</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="销售奖">
                    <el-input v-if="scope.row.edit" v-model="scope.row.salesAward" @keyup.enter="handleSave(scope.row)" size="mini"></el-input>
                    <span v-else>{{scope.row.salesAward}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="拉新奖">
                    <el-input v-if="scope.row.edit" v-model="scope.row.inviteMemberAward" @keyup.enter="handleSave(scope.row)" size="mini"></el-input>
                    <span v-else>{{scope.row.inviteMemberAward}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="首购奖">
                    <el-input v-if="scope.row.edit" v-model="scope.row.firstPurchaseAward" @keyup.enter="handleSave(scope.row)" size="mini"></el-input>
                    <span v-else>{{scope.row.firstPurchaseAward}}</span>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="clickAddBtn" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button v-if="!scope.row.edit"
                                   size="mini"
                                   @click="handleEdit(scope.row)">修改</el-button>
                        <el-button v-else size="mini" type="success" @click="handleSave(scope.row)">保存</el-button>
                        <el-button v-if="scope.row.edit"
                                   size="mini"
                                   @click="handleCancel(scope.row)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                :close-on-click-modal="!requesting"
                :close-on-press-escape="false"
                :show-close="!requesting"
                title="添加奖励" v-model="addDialogVisible"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    size="small"
                    v-loading="requesting">
                <el-form-item label="网站" prop="websiteTypeId">
                    <el-select v-model="formData.websiteTypeId" placeholder="请选择网站类型">
                        <el-option v-for="row in websiteTypeList" :key="row.id" :value="row.id" :label="row.alias + '(' +row.price + ' - ' + row.revenueRate + ')'">
                            {{row.alias}}({{row.price}} - {{row.revenueRate}})
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="销售奖"  prop="salesAward">
                    <el-input
                            @keyup.enter="addSpreadAward('addForm')"
                            v-model="formData.salesAward"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="拉新奖"  prop="inviteMemberAward">
                    <el-input
                            @keyup.enter="addSpreadAward('addForm')"
                            v-model="formData.inviteMemberAward"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="首购奖"  prop="firstPurchaseAward">
                    <el-input
                            @keyup.enter="addSpreadAward('addForm')"
                            v-model="formData.firstPurchaseAward"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="requesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="requesting" @click="addSpreadAward('addForm')" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";

    export default {
        name: "SpreadAwardSetting",
        components: {ManagerFrame},
        data() {
            return {
                cardLoading: false,
                requesting: false,
                tableData: [],
                addDialogVisible: false,
                formData: {},
                websiteTypeList: [],
                rules: {
                    websiteTypeId: [
                        {
                            required: true,
                            message: "请选择网站",
                            trigger: "blur"
                        },
                    ],
                    salesAward: [
                        {
                            required: true,
                            message: "请输入金额",
                            trigger: "blur"
                        },
                        {
                            pattern: /^[0-9]{1,6}\.?[0-9]{0,2}$/,
                            message: "请输入正确金额",
                            trigger: "blur"
                        },
                    ],
                    inviteMemberAward: [
                        {
                            required: true,
                            message: "请输入金额",
                            trigger: "blur"
                        },
                        {
                            pattern: /^[0-9]{1,6}\.?[0-9]{0,2}$/,
                            message: "请输入正确金额",
                            trigger: "blur"
                        },
                    ],
                    firstPurchaseAward: [
                        {
                            required: true,
                            message: "请输入金额",
                            trigger: "blur"
                        },
                        {
                            pattern: /^[0-9]{1,6}\.?[0-9]{0,2}$/,
                            message: "请输入正确金额",
                            trigger: "blur"
                        },
                    ]
                }
            }
        },
        mounted() {
            this.axios.post("/manager/get_spread_award_configs_list").then((response) => {
                this.tableData = response.data;
            });
        },
        methods: {
            handleEdit(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true;
            },
            handleCancel(data) {
                data.salesAward = data.oldData.salesAward;
                data.inviteMemberAward = data.oldData.inviteMemberAward;
                data.firstPurchaseAward = data.oldData.firstPurchaseAward;
                data.edit = false;
            },
            handleSave(data) {
                let salesAwardStr = data.salesAward;
                let inviteMemberAwardStr = data.inviteMemberAward;
                let firstPurchaseAward = data.firstPurchaseAward;
                if(salesAwardStr.toString().trim() === "" || isNaN(salesAwardStr) || inviteMemberAwardStr.toString().trim() === "" || isNaN(inviteMemberAwardStr) || firstPurchaseAward.toString().trim() === "" || isNaN(firstPurchaseAward)) {
                    this.$message.error({
                        message: "请输入正确金额!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(salesAwardStr) < 0) || (parseFloat(salesAwardStr) > 99999999.99) || (parseFloat(inviteMemberAwardStr) < 0) || (parseFloat(inviteMemberAwardStr) > 99999999.99)|| (parseFloat(firstPurchaseAward) < 0) || (parseFloat(firstPurchaseAward) > 99999999.99)) {
                    this.$message.error({
                        message: "金额的范围只能是 0 - 99999999.99 !",
                        type: "error"
                    });
                    return;
                }
                this.axios.post("/manager/save_spread_award", {
                    "id": data.id,
                    "salesAward": data.salesAward,
                    "inviteMemberAward": data.inviteMemberAward,
                    "firstPurchaseAward": data.firstPurchaseAward
                }).then((response) => {
                    if(response.data.result) {
                        data.edit = false
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            },
            clickAddBtn() {
                this.axios.post("/manager/get_spread_website_type_list").then((response) => {
                    this.websiteTypeList = response.data;
                    this.addDialogVisible = true;
                });
            },
            addSpreadAward(formName) {
                this.$refs[formName].validate((value) => {
                    if(value) {
                        this.requesting = true;
                        this.axios.post("/manager/add_spread_award", this.formData).then((response) => {
                            this.requesting = false;
                            if(response.data.result) {
                                this.addDialogVisible = false;
                                this.tableData.unshift(response.data.data);
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>