<template>
    <SalesmanFrame default-active="orderList">
        <el-card id="s-order-list" shadow="always" v-loading="cardLoading">
            <div class="order-list-header">
                <el-menu
                        :default-active="activeStatus"
                        active-text-color="#409EFF"
                        mode="horizontal"
                        @select="handleSelect">
                    <el-menu-item index="pending">待付款</el-menu-item>
                    <el-menu-item index="paying">付款中</el-menu-item>
                    <el-menu-item index="timeout">付款超时</el-menu-item>
                    <el-menu-item index="fail">失败</el-menu-item>
                    <el-menu-item index="orderClose">订单关闭</el-menu-item>
                    <el-menu-item index="success">成功</el-menu-item>
                    <el-menu-item index="all">全部</el-menu-item>
                </el-menu>
                <div class="time-filter">
                    <el-input
                            @keyup.enter="handleSearch"
                            placeholder="搜索"
                            clearable
                            size="small"
                            :style="{width:'200px',marginRight:'20px'}"
                            v-model="searchWord">
                        <template #prefix>
                            <i class="el-input__icon el-icon-search"></i>
                        </template>
                    </el-input>
                    <el-radio-group size="small" v-model="timeFilter" @change="radioChange">
                        <el-radio-button label="today">今天</el-radio-button>
                        <el-radio-button label="all">全部</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <el-table
                    :data="tableData"
                    stripe="true"
                    show-overflow-tooltip="true"
                    :row-class-name="tableRowClassName"
                    style="width: 100%">
                <el-table-column
                        label="用户名"
                        prop="username">
                </el-table-column>
                <el-table-column
                        label="订单号">
                    <template #default="scope">
                        {{scope.row.platformOrderId === "" ? scope.row.orderId : scope.row.platformOrderId}}
                    </template>
                </el-table-column>
                <el-table-column
                        v-if="activeStatus === 'all'"
                        #default="scope"
                        width="70"
                        label="状态">
                    <span v-if="scope.row.status === '-1'" :style="{color: '#F56C6C'}">订单关闭</span>
                    <span v-else-if="scope.row.status === 0" :style="{color: '#909399'}">待付款</span>
                    <span v-else-if="scope.row.status === 1" :style="{color: '#E6A23C'}">支付中</span>
                    <span v-else-if="scope.row.status === 2" :style="{color: '#F56C6C'}">超时</span>
                    <span v-else-if="scope.row.status === 3" :style="{color: '#F56C6C'}">失败</span>
                    <span v-else-if="scope.row.status === 4" :style="{color: '#67C23A'}">成功</span>
                </el-table-column>
                <el-table-column
                        label="支付平台"
                        width="80"
                        prop="paymentMethod">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="类型">
                    {{scope.row.goodsType}}: {{scope.row.goodsName}}
                </el-table-column>
                <el-table-column
                        width="100"
                        label="金额"
                        prop="amount">
                </el-table-column>
                <el-table-column
                        width="100"
                        label="折扣"
                        prop="discount">
                </el-table-column>
                <el-table-column
                        width="60"
                        label="数量"
                        prop="goodsQuantity">
                </el-table-column>
                <el-table-column
                        label="创建时间"
                        prop="createTime">
                </el-table-column>
                <el-table-column
                        label="更新时间"
                        prop="updateTime">
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
    </SalesmanFrame>
</template>

<script>
    import SalesmanFrame from "../components/SalesmanFrame";
    export default {
        name: "OrderList",
        components: {SalesmanFrame},
        mounted() {
            this.activeStatus = this.$route.params.status;
            this.timeFilter = this.$route.params.timeFilter;
            this.currentPage = this.$route.params.currentPage;
            if(this.$route.path.indexOf("/salesman/order_list/") > -1) {
                this.loadData();
            } else {
                this.activeStatus = "all";
                this.timeFilter = "all";
                this.searchWord = this.$route.params.searchWord;
                this.searchData();
            }
        },
        data() {
            return {
                cardLoading: false,
                tableData: [],
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                activeStatus: "pending",
                timeFilter: "today",
                searchWord: ""
            }
        },
        methods: {
            loadData() {
                this.cardLoading = true;
                let $this = this;
                this.axios.post("/salesman/order_list", {
                    "currentPage": this.currentPage,
                    "status": this.activeStatus,
                    "timeFilter": this.timeFilter
                }).then(function(response) {
                    $this.tableData = response.data.dataList;
                    $this.rowCount = response.data.rowCount;
                    $this.pageSize = response.data.pageSize;
                    $this.currentPage = response.data.currentPage;
                    $this.cardLoading = false;
                });
            },
            tableRowClassName(row) {
                if(row.row.isRepeatCoupon) {
                    return "danger-row";
                }
                return "";
            },
            handleSearch() {
                if(this.searchWord.trim() === "") {
                    return;
                }
                this.currentPage = 1;
                this.activeStatus = "all";
                this.timeFilter = "all";
                this.$router.push("/salesman/search_order/" + this.searchWord + "/1");
                this.searchData();
            },
            searchData() {
                this.cardLoading = true;
                this.axios.post("/salesman/search_order", {
                    "currentPage": this.currentPage,
                    "searchWord": this.searchWord
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.cardLoading = false;
                });
            },
            handleSelect(key) {
                document.location.href = "/salesman/order_list/" + key + "/1/" + this.timeFilter;
            },
            handleCurrentChange(currentPage) {
                if(this.$route.path.indexOf("/salesman/order_list/") > -1) {
                    document.location.href = "/salesman/order_list/" + this.activeStatus + "/" + currentPage + "/" + this.timeFilter;
                } else {
                    document.location.href = "/salesman/search_order/" + this.searchWord + "/" + currentPage;
                }
            },
            radioChange(value) {
                document.location.href = "/salesman/order_list/" + this.activeStatus + "/" + this.currentPage + "/" + value;
            }
        }
    }
</script>

<style>
#s-order-list thead th {
    padding: 5px 0;
}
.order-list-header {
    position: relative;
}
.time-filter {
    position: absolute;
    top: 0;
    right: 0;
}
.el-table .danger-row td {
    background-color: #fde2e2 !important;
}
</style>