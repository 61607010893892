<template>
    <el-container>
        <el-aside width="200px">
            <el-menu :default-active="defaultActive">
                <router-link to="/manager/salesman_list">
                    <el-menu-item index="salesmanList">
                        <i class="el-icon-user"></i>
                        <template #title>销售列表</template>
                    </el-menu-item>
                </router-link>
                <el-submenu index="ad">
                    <template #title>
                        <i class="el-icon-s-flag"></i>
                        <span>广告设置</span>
                    </template>
                    <router-link to="/manager/ad_word">
                        <el-menu-item index="ad-word">
                            <i class="el-icon-star-off"></i>
                            <template #title>广告词</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/ad_link">
                        <el-menu-item index="ad-link">
                            <i class="el-icon-link"></i>
                            <template #title>广告链接</template>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="payment">
                    <template #title>
                        <i class="el-icon-coin"></i>
                        <span>支付设置</span>
                    </template>
                    <router-link to="/manager/payment_method">
                        <el-menu-item index="payment-settings">
                            <i class="el-icon-s-grid"></i>
                            <template #title>支付方式</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/recharge_setting">
                        <el-menu-item index="payment-recharge">
                            <i class="el-icon-time"></i>
                            <template #title>时间充值</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/website_price">
                        <el-menu-item index="payment-website">
                            <i class="el-icon-goods"></i>
                            <template #title>网站价格</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/website_upgrade_price">
                        <el-menu-item index="payment-website-upgrade">
                            <i class="el-icon-sell"></i>
                            <template #title>网站升级</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/website_ram_setting">
                        <el-menu-item index="payment-website-ram">
                            <i class="fa fa-microchip" aria-hidden="true"></i>
                            <template #title>内存设置</template>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <router-link to="/manager/website_setting">
                    <el-menu-item index="websiteSetting">
                        <i class="el-icon-collection"></i>
                        <template #title>添加网站</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/manager/user_hierarchies">
                    <el-menu-item index="userHierarchies">
                        <i class="el-icon-connection"></i>
                        <template #title>会员层级关系</template>
                    </el-menu-item>
                </router-link>
                <el-submenu index="membership_level">
                    <template #title>
                        <i class="el-icon-top"></i>
                        <span>等级设置</span>
                    </template>
                    <router-link to="/manager/membership_level_setting">
                        <el-menu-item index="membershipLevel">
                            <i class="el-icon-medal"></i>
                            <template #title>会员等级</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/condition_profit_name">
                        <el-menu-item index="membershipLevelConditionProfit">
                            <i class="el-icon-document"></i>
                            <template #title>条件与权益</template>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <el-submenu index="invite">
                    <template #title>
                        <i class="el-icon-share"></i>
                        <span>邀请设置</span>
                    </template>
                    <router-link to="/manager/invite_configs">
                        <el-menu-item index="invite-configs">
                            <i class="el-icon-s-operation"></i>
                            <template #title>设置</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/spread_award_setting">
                        <el-menu-item index="invite-spread">
                            <i class="el-icon-present"></i>
                            <template #title>直推奖励</template>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <router-link to="/manager/withdrawal_configs">
                    <el-menu-item index="withdrawalConfigs">
                        <i class="el-icon-bank-card"></i>
                        <template #title>提现设置</template>
                    </el-menu-item>
                </router-link>
                <el-submenu index="coupon">
                    <template #title>
                        <i class="el-icon-discount"></i>
                        <span>优惠券</span>
                    </template>
                    <router-link to="/manager/coupon_settings">
                        <el-menu-item index="coupon-settings">
                            <i class="el-icon-plus"></i>
                            <template #title>添加</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/coupon_distribution">
                        <el-menu-item index="coupon-distribution">
                            <i class="el-icon-s-promotion"></i>
                            <template #title>发布</template>
                        </el-menu-item>
                    </router-link>
                    <router-link to="/manager/auto_distribute_coupon">
                        <el-menu-item index="coupon-auto">
                            <i class="el-icon-refresh"></i>
                            <template #title>自动发布</template>
                        </el-menu-item>
                    </router-link>
                </el-submenu>
                <router-link to="/manager/system_configs">
                    <el-menu-item index="systemConfigs">
                        <i class="el-icon-setting"></i>
                        <template #title>系统设置</template>
                    </el-menu-item>
                </router-link>
                <router-link to="/manager/poster_settings">
                    <el-menu-item index="posterSettings">
                        <i class="el-icon-picture-outline"></i>
                        <template #title>海报设置</template>
                    </el-menu-item>
                </router-link>
            </el-menu>
        </el-aside>
        <el-container class="main-side">
            <el-header>
                <span>{{username}}</span>
                <el-dropdown>
                    <i class="el-icon-user-solid" style="margin-left: 10px;color: #FFF;font-size: 30px;vertical-align: middle;"></i>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="logout">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-header>
            <el-main>
                <slot></slot>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    name: "ManagerFrame",
    props: {
        defaultActive: String
    },
    data() {
        return {
            username: ""
        }
    },
    mounted() {
        this.username = localStorage.getItem("managerName");
    },
    methods: {
        logout() {
            localStorage.removeItem("managerName");
            document.location.href = "/manager";
        }
    }
};
</script>

<style scoped>
.fa {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
}
</style>
