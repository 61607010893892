<template>
    <user-header></user-header>
    <section id="s-1">
        <div>
            <el-row class="s-1-row" :gutter="10">
                <el-col :xs="14">
                    <div class="s1-content">
                        <h1 class="title-1">Ops is a tool developed by ADOPSANDADTECH SDN. BHD. to display Google Ads.</h1>
                        <h1>Coolest Google Ads Tool</h1>
                    </div>
                </el-col>
                <el-col :xs="10">
                    <a :href="androidUrl" download="ops-release.apk"><el-button type="success" icon="fab fa-android" size="small" :style="{color:'#fff' ,width:'110px'}"> For Android</el-button></a>
                    <a v-if="iosUrl !== ''" :href="iosUrl" target="_blank"><el-button size="small" :style="{backgroundColor:'#000', borderColor:'#000', color:'#fff', width:'110px'}" icon="fab fa-apple"> For IOS</el-button></a>
                    <a href="/app_web_redirect"><el-button type="primary" size="small" :style="{width:'110px'}" icon="fab fa-brands fa-chrome"> For Web</el-button></a>
                </el-col>
            </el-row>
        </div>
    </section>
    <section id="s-2">
        <el-row class="s-2-content">
            <el-col :xs="24" :md="12">
                <div class="card-block">
                    <div class="card-block-img">
                        <img src="../../assets/Projects.png">
                    </div>
                    <div class="card-block-content">
                        <h3 class="card-block-title">Ad Operations</h3>
                        <p class="card-block-subtitle">Our highly-experienced team offers buy and sell-side support completely scalable to your specific ad ops needs.</p>
<!--                        <div class="card-block-btn">-->
<!--                            <a>How We Work ></a>-->
<!--                        </div>-->
                    </div>
                </div>
            </el-col>
            <el-col :xs="24" :md="12">
                <div class="card-block">
                    <div class="card-block-img">
                        <img src="../../assets/A&A+icons_ad+tech.png">
                    </div>
                    <div class="card-block-content">
                        <h3 class="card-block-title">Ad Technology</h3>
                        <p class="card-block-subtitle">Our ad tech experts offer support, troubleshooting, migration, optimisation, planning, implementation and much more.</p>
<!--                        <div class="card-block-btn">-->
<!--                            <a>Platforms We Support ></a>-->
<!--                        </div>-->
                    </div>
                </div>
            </el-col>
        </el-row>
    </section>
    <section id="s-3">
        <el-row>
            <el-col :xs="24" :md="12">
                <div class="s-3-img">
                    <img src="../../assets/Image_2402x.png">
                </div>
            </el-col>
            <el-col :xs="24" :md="12">
                <div>
                    <h2>We’ve Been Hand-Picked as a Google Certified Publishing Partner and Reseller</h2>
                    <p>As a Certified Publishing Partner, you can feel confident knowing we’ve been carefully vetted by Google<strong> as proven product and support experts</strong>. We are the only Google Ad Manager reseller in Asia-Pacific and we’re one of nine globally.</p>
                    <p><strong>We can provide you with access to Google’s best of breed advertising technology platforms.</strong></p>
<!--                    <div class="card-block-btn">-->
<!--                        <a> Find Out More > </a>-->
<!--                    </div>-->
                </div>
            </el-col>
        </el-row>
    </section>
    <section id="s-4">
        <div>
            <h2>What We Do</h2>
            <p>With <strong>over 20 years</strong> in the industry, we are a group of <strong>highly-skilled experts</strong> who currently support some of <strong>Australia and New Zealand’s biggest companies</strong> in the delivery of their digital campaigns. We offer <strong>both buy and sell-side</strong> ad operations and technology, with the capacity to work over <strong>international timelines</strong> offering a <strong>fast turnaround</strong> and <strong>emergency support</strong>. We’re a local <strong>Australian-based</strong> company and extremely proud to be known as the <strong>industry leaders</strong> for almost a decade now.</p>
        </div>
    </section>
    <section id="s-5">
        <el-row class="s-5-content">
            <el-col>
                <el-col :xs="24" :md="12">
                    <div class="card-block">
                        <div class="card-block-img">
                            <img src="../../assets/Rectangle_7@2x.png">
                        </div>
                        <div class="card-block-content">
                            <h3 class="card-block-title">I’m a Publisher</h3>
                            <p class="card-block-subtitle">We provide expert support in the delivery of campaigns through efficient campaign management and an optimal ad tech stack to help you increase revenue.</p>
<!--                            <div class="card-block-btn">-->
<!--                                <a>Find Out More ></a>-->
<!--                            </div>-->
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :md="12">
                    <div class="card-block">
                        <div class="card-block-img">
                            <img src="../../assets/Rectangle_7_bp@2x.png">
                        </div>
                        <div class="card-block-content">
                            <h3 class="card-block-title">I’m an Agency</h3>
                            <p class="card-block-subtitle">We offer flexible ad ops solutions and ad tech support so your digital media plan fulfilment becomes seamless and ultimately, pain-free.</p>
<!--                            <div class="card-block-btn">-->
<!--                                <a>Find Out More ></a>-->
<!--                            </div>-->
                        </div>
                    </div>
                </el-col>
            </el-col>
        </el-row>
    </section>
    <section id="s-6">
        <el-row>
            <el-col :xs="24" :md="12">
                <div class="card-block-img">
                    <img src="../../assets/Guide2x.png">
                </div>
            </el-col>
            <el-col :xs="24" :md="12">
                <h3>Our Support Comes in Many Shapes & Sizes</h3>
                <p>If you need help with a specific problem, our flexible solutions and highly-skilled support services cover <strong>ad operations, consulting, training, programmatic </strong>and<strong> reporting</strong>. Please take a look at our extensive list of capabilities.</p>
                <router-link to="contact">
                    <div class="card-block-btn">
                        <a>Contact Us ></a>
                    </div>
                </router-link>
            </el-col>
        </el-row>
    </section>
<!--    <section id="s-7">-->
<!--        <el-row>-->
<!--            <el-col :xs="24" :md="6">-->
<!--                <div class="card-block-img">-->
<!--                    <img src="../../assets/iab-member.png">-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :md="6">-->
<!--                <div class="card-block-img">-->
<!--                    <img src="../../assets/GooglePartner2x.png">-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :md="6">-->
<!--                <div class="card-block-img">-->
<!--                    <img src="../../assets/GMP_Certified_Badge_2.jpg">-->
<!--                </div>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :md="6">-->
<!--                <div class="card-block-img">-->
<!--                    <img src="../../assets/Image+2x.png">-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->
<!--    </section>-->
    <user-footer></user-footer>
</template>

<script>
    import UserHeader from "./UserHeader";
    import UserFooter from "./UserFooter";
    export default {
        name: "index_bak",
        components: {UserFooter, UserHeader},
        data() {
            return {
                androidUrl: "",
                iosUrl: ""
            }
        },
        mounted() {
            //获取下载链接
            this.axios.post("/user/get_app_download_url").then((response) => {
                this.androidUrl = response.data["androidUrl"];
                this.iosUrl = response.data["iosUrl"];
                this.webApp = response.data["webApp"];
            });
        }
    }
</script>

<style>
.card-block-btn a {
    display: inline-block;
    padding: 1.3em 2.171em;
    color: #de1783;
    border: 2px solid #de1783;
    font-family: itc-avant-garde-gothic-pro;
    font-style: normal;
    font-weight: 500;
    letter-spacing: .05em;
    text-transform: none;
    font-size: 0.9rem;
}
</style>
<style scoped>
h1 {
    font-family: 'Rozha One',RozhaOne-Regular,serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: .2px;
    text-transform: none;
    line-height: 1.1;
    font-size: calc(26px);
    color: #2f2861 !important;
}
h2,h3 {
    font-family: sofia-pro;
    font-weight: 900;
    font-style: normal;
    letter-spacing: .2px;
    text-transform: none;
}
p {
    font-family: sofia-pro;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
    text-transform: none;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0.9rem;
}
#s-1 {
    background-color: #eee;
}
#s-1>div {
    background-image: url("../../assets/web-bg-1.png");
    background-size: 100%;
    background-repeat: no-repeat;
}
#s-1 h1 {
    color: #fff !important;
}
.card-block {
    background-color: #fff;
    padding: 8%;
    filter: drop-shadow(3px 3px 12px rgba(0,0,0,.161));
}
#s-2,#s-3 {
    background-color: #f2f2f2;
}
.s-2-content,.s-5-content {
    padding-right: 6vw;
    padding-left: 6vw;
}
#s-2 h3 {
    color: #2f2861 !important;
}
#s-3 h2 {
    color: #de1783;
    margin-top: 30px;
    margin-bottom: 25px;
}
#s-4 {
    text-align: center;
}
#s-4 h2 {
    color: #de1783;
    margin-bottom: 20px;
}
#s-6 {
    background-color: #eb5692;
}
#s-6 h3, #s-6 p {
    color: #fff;
}
#s-6 .card-block-btn a {
    color: #fff;
    border-color: #fff;
}
@media only screen and (min-width: 992px) {
/*#s-1>div {*/
/*    height: 500px;*/
/*    padding: 150px 80px 0 80px;*/
/*    background-position: center;*/
/*}*/
/*.s-1-row {*/
/*    width: 50%;*/
/*}*/
/*.s-1-row a {*/
/*    display: inline-block;*/
/*    margin-right: 20px;*/
/*    float: right;*/
/*}*/
/*.s1-content h1 {*/
/*    font-size: 50px;*/
/*    margin-bottom: 50px;*/
/*}*/
/*#s-2 {*/
/*    position: relative;*/
/*}*/
/*.s-2-content {*/
/*    top: -80px;*/
/*}*/
/*.s-2-content .card-block,.s-5-content .card-block {*/
/*    margin: 0 20px;*/
/*}*/
/*#s-3 {*/
/*    padding: 0 50px 50px 50px;*/
/*}*/
/*.s-3-img {*/
/*    text-align: center;*/
/*}*/
/*#s-4 {*/
/*    padding: 35px 6vw;*/
/*}*/
/*#s-3 h2,#s-4 h2 {*/
/*    font-size: 40px;*/
/*}*/
/*#s-5 {*/
/*    padding-bottom: 50px;*/
/*}*/
/*#s-6 {*/
/*    padding: 50px 18vw;*/
/*}*/
/*#s-6 h3 {*/
/*    font-size: 28px;*/
/*    margin-bottom: 25px;*/
/*}*/
/*#s-6 .card-block-btn {*/
/*    margin-top: 35px;*/
/*}*/
/*#s-7 {*/
/*    padding: 30px 12vw;*/
/*}*/
/*#s-7 .card-block-img {*/
/*    text-align: center;*/
/*    padding: 0 20px;*/
/*}*/
/*#s-7 img {*/
/*    width: 100%;*/
/*}*/
}

@media only screen and (max-width: 992px) {
h3 {
    font-size: calc(22px);
}
#s-1>div {
    padding: 90px 6vw 13.2vmax;
}
.s-1-row a {
    display: inline-block;
    margin-bottom: 20px;
}
.title-1 {
    margin-bottom: 15%;
}
.card-block {
    margin-bottom: 9vw;
}
#s-2 .card-block {
    margin-top: -15%;
    margin-bottom: 22vw;
}
.card-block-img {
    text-align: center;
}
.card-block-img img {
    width: 200px;
}
.card-block-content {
    margin-top: 6%;
}
.card-block-subtitle {
    margin-top: 6%;
}
.card-block-btn {
    margin-top: 8%;
}
#s-3 {
    padding: calc(30vmax / 10) 6vw 9vw 6vw;
    margin-top: -50px;
}
.s-3-img {
    text-align: center;
}
.s-3-img img {
    width: 200px;
}
#s-4 {
    padding: 35px 6vw 9vw 6vw;
}
#s-6 {
    padding: 3.3vmax 6vw 6vmax 6vw;
}
#s-6 .card-block-img, #s-7 .card-block-img {
    padding-top: 17px;
    padding-bottom: 17px;
}
#s-6 h3 {
    margin-bottom: 2rem;
}
#s-6 p {
    font-size: 14px;
}
#s-7 {
    padding: calc(19vmax / 10) 6vw;
}
}
</style>