<template>
    <SalesmanFrame default-active="home">
        <div v-loading="loading" :style="{float:'right'}">
            <el-date-picker
                    v-model="dateRange"
                    @change="dateRangeChange"
                    :style="{marginRight: '20px'}"
                    value-format="YYYY-MM-DD"
                    size="small"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            <el-radio-group @change="shortcutsChange" v-model="shortcuts" size="small">
                <el-radio-button label="month">当前月份</el-radio-button>
                <el-radio-button label="week">当前星期</el-radio-button>
                <el-radio-button label="yesterday">昨天</el-radio-button>
                <el-radio-button label="today">今天</el-radio-button>
            </el-radio-group>
        </div>
        <el-row class="data-summary" :gutter="20" :style="{marginTop:'60px', fontSize:'14px'}">
            <el-col :span="4">
                <el-card shadow="always">
                    <p>新增用户: {{homeData.newUserCount}}</p>
                    <p>体验: {{homeData.trialCount}}({{homeData.completedTrialCount}})</p>
                    <p>成为会员: {{homeData.memberCount}}({{homeData.memberCountByCreateTime}})</p>
                    <p>登录软件: {{homeData.loginCount}}</p>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="always">
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">成功收入: <span class="text-green">{{homeData.income}}</span></el-col>
                            <el-col :span="12">网站人数: {{homeData.buyWebsiteUserCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">购买网站: {{homeData.websiteIncome}}</el-col>
                            <el-col :span="12">网站数量: {{homeData.websiteCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">升级网站: {{homeData.upgradeIncome}}({{homeData.upgradeBalanceIncome}})</el-col>
                            <el-col :span="12">升级人数: {{homeData.upgradeUserCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">购买内存: {{homeData.ramTotalIncome}}({{homeData.ramBalanceIncome}})</el-col>
                            <el-col :span="12">内存人数: {{homeData.buyRamUserCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">代付款: {{homeData.waitPay}}</el-col>
                            <el-col :span="12">升级网站数: {{homeData.upgradeWebsiteCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">总折扣: {{homeData.totalDiscount}}</el-col>
                            <el-col :span="12">优惠券BUG: {{homeData.couponRepeatCount}}</el-col>
                        </el-row>
                    </p>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="always">
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">成功提现: <span class="text-red">{{homeData.successWithdraw}}</span></el-col>
                            <el-col :span="12">总额: {{homeData.totalWithdraw}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">等待通过: {{homeData.pendingWithdraw}}</el-col>
                            <el-col :span="12">总提现人数: {{homeData.withdrawUserCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">平台付款中: {{homeData.platformPayingWithdraw}}</el-col>
                            <el-col :span="12">成功提现人数: {{homeData.successWithdrawUserCount}}</el-col>
                        </el-row>
                    </p>
                    <p>
                        <el-row :gutter="30">
                            <el-col :span="12">提现失败: {{homeData.failWithdraw}}</el-col>
                            <el-col :span="12">薅羊毛人数: {{homeData.fakeWithdrawUserCount}}</el-col>
                        </el-row>
                    </p>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="always">
                    <p>总用户数: {{homeData.totalUserCount}}</p>
                    <p>总会员数: {{homeData.totalMemberCount}}</p>
                    <p>总收入: {{homeData.totalIncome}}</p>
                    <p>总支出: {{homeData.totalExpenditure}}</p>
                </el-card>
            </el-col>
        </el-row>
        <el-card  shadow="always" :style="{marginTop: '30px'}">
            <el-row>
                <el-col :span="16"><div id="user-line-chart"></div></el-col>
                <el-col :span="8">
                    <div id="trial-platform-chart"></div>
                    <div id="member-platform-chart"></div>
                </el-col>
            </el-row>
            <el-row :style="{marginTop: '50px'}">
                <el-col :span="12"><div id="income-line-chart"></div></el-col>
                <el-col :span="12"><div id="expenditure-line-chart"></div></el-col>
            </el-row>
        </el-card>
    </SalesmanFrame>
</template>

<script>
    import SalesmanFrame from "../components/SalesmanFrame";
    import * as echarts from "echarts";
    export default {
        name: "SalesmanHome",
        components: {SalesmanFrame},
        mounted() {
            this.shortcuts = this.$route.params.shortcuts;
            if(this.shortcuts === "") {
                this.shortcuts = "today";
            }
            let dateStart = this.$route.params.dateStart;
            let dateEnd = this.$route.params.dateEnd;
            this.dateRange = [dateStart, dateEnd];

            this.createCharts();
            this.loadData();
        },
        data() {
            return {
                loading: false,
                shortcuts: "",
                dateRange: [],
                homeData: {},
                userLineChart: Object,
                trialPlatformChart: Object,
                memberPlatformChart: Object,
                incomeLineChart: Object,
                expenditureLineChart: Object
            }
        },
        methods: {
            shortcutsChange(value) {
                this.dateRange = [];
                this.$router.push("/salesman/home/" + value);
                this.loadData();
            },
            dateRangeChange(value) {
                this.shortcuts = "custom";
                this.$router.push("/salesman/home/custom/" + value[0] + "/" + value[1]);
                this.loadData();
            },
            loadData() {
                let $this = this;
                this.loading = true;
                this.axios.post("/salesman/home_data",{
                    "shortcuts": this.shortcuts,
                    "dateStart": this.dateRange[0],
                    "dateEnd": this.dateRange[1]
                }).then(function(response) {
                    $this.loading = false;
                    $this.homeData = response.data;

                    let userLineChartOption = {
                        title: {
                            text: "用户数据"
                        },
                        tooltip: {},
                        legend: {
                            data:["新用户", "体验", "会员"]
                        },
                        xAxis: {
                            data: response.data.lineChartXAxis
                        },
                        yAxis: {},
                        series: [{
                            name: "新用户",
                            type: "line",
                            data: response.data.newUserLineChartData
                        }, {
                            name: "体验",
                            type: "line",
                            data: response.data.trialUserLineChartData
                        }, {
                            name: "会员",
                            type: "line",
                            data: response.data.memberLineChartData
                        }]
                    };
                    let incomeLineChartOption = {
                        title: {
                            text: "收入数据"
                        },
                        tooltip: {},
                        legend: {
                            data:["收入"]
                        },
                        xAxis: {
                            data: response.data.lineChartXAxis
                        },
                        yAxis: {},
                        series: [{
                            name: "收入",
                            type: "line",
                            color: "#91cc75",
                            data: response.data.incomeLineChartData
                        }]
                    };
                    let expenditureLineChartOption = {
                        title: {
                            text: "支出数据"
                        },
                        tooltip: {},
                        legend: {
                            data:["支出"]
                        },
                        xAxis: {
                            data: response.data.lineChartXAxis
                        },
                        yAxis: {},
                        series: [{
                            name: "支出",
                            type: "line",
                            color: "#ee6666",
                            data: response.data.expenditureLineChartData
                        }]
                    };

                    let trialPlatformPieChartOption = {
                        title: {
                            text: "体验用户手机平台",
                            textStyle: {
                                color: "#909399",
                                fontSize: 12,
                                fontWeight: 300
                            }
                        },
                        series : [
                            {
                                name: "体验用户手机平台",
                                type: "pie",
                                radius: "55%",
                                label: {
                                    normal: {
                                        formatter: "{b}:{c}" + "\n\r" + "({d}%)",
                                        show: true,
                                    }
                                },
                                color: ["#67C23A", "#000", "#409EFF"],
                                data: [
                                    {value:response.data.trialAndroidUserCount, name:"Android"},
                                    {value:response.data.trialIosUserCount, name:"IOS"},
                                    {value:response.data.trialWebUserCount, name:"Web"}
                                ]
                            }
                        ]
                    };

                    let memberPlatformPieChartOption = {
                        title: {
                            text: "会员手机平台",
                            textStyle: {
                                color: "#909399",
                                fontSize: 12,
                                fontWeight: 300
                            }
                        },
                        series : [
                            {
                                name: "会员手机平台",
                                type: "pie",
                                radius: "55%",
                                label: {
                                    normal: {
                                        formatter: "{b}:{c}" + "\n\r" + "({d}%)",
                                        show: true,
                                    }
                                },
                                color: ["#67C23A", "#000", "#409EFF"],
                                data: [
                                    {value:response.data.memberAndroidUserCount, name:"Android"},
                                    {value:response.data.memberIosUserCount, name:"IOS"},
                                    {value:response.data.memberWebUserCount, name:"Web"}
                                ]
                            }
                        ]
                    };
                    setTimeout(() => {
                        $this.userLineChart.setOption(userLineChartOption);
                        $this.trialPlatformChart.setOption(trialPlatformPieChartOption);
                        $this.memberPlatformChart.setOption(memberPlatformPieChartOption);
                        $this.incomeLineChart.setOption(incomeLineChartOption);
                        $this.expenditureLineChart.setOption(expenditureLineChartOption);
                    }, 500);
                });
            },
            createCharts() {
                // 基于准备好的dom，初始化echarts实例
                this.userLineChart = echarts.init(document.getElementById("user-line-chart"));
                this.trialPlatformChart = echarts.init(document.getElementById("trial-platform-chart"));
                this.memberPlatformChart = echarts.init(document.getElementById("member-platform-chart"));
                this.incomeLineChart = echarts.init(document.getElementById("income-line-chart"));
                this.expenditureLineChart = echarts.init(document.getElementById("expenditure-line-chart"));
            }
        }
    }
</script>

<style scoped>
.data-summary p {
    margin-bottom: 20px;
}
.data-summary p:last-child {
    margin-bottom: 0;
}
#user-line-chart, #income-line-chart, #expenditure-line-chart {
    height: 350px;
}
#trial-platform-chart,#member-platform-chart {
    height: 175px;
}
</style>