<template>
    <ManagerFrame default-active="salesmanList">
        <el-card class="main" shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        label="用户名"
                        prop="username">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="状态"
                        prop="status">
                    <span v-if="scope.row.status === 0">正常</span>
                    <span :style="{ color: '#F56C6C'}" v-else-if="scope.row.status === 1">封禁</span>
                </el-table-column>
                <el-table-column
                        :formatter="formatDate"
                        label="最后登录时间"
                        prop="lastLoginTime">
                </el-table-column>
                <el-table-column
                        label="最后登录IP"
                        prop="lastLoginIp">
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="dialogVisible = !dialogVisible" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default>
                        <i class="el-icon-setting" :style="{ fontSize: '20px'}"></i>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
            />
        </el-card>
        <el-dialog
                :close-on-click-modal="!formRequesting"
                :close-on-press-escape="false"
                :show-close="!formRequesting"
                title="添加销售" v-model="dialogVisible"
                width="400px">
            <el-form
                    ref="addSalesmanForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    size="small"
                    v-loading="formRequesting">
                <el-form-item label="用户名"  prop="username">
                    <el-input
                        @keyup.enter="addSalesman('addSalesmanForm')"
                        v-model="formData.username"
                        clearable
                    />
                </el-form-item>
                <el-form-item label="密码"  prop="password">
                    <el-input
                        @keyup.enter="addSalesman('addSalesmanForm')"
                        v-model="formData.password"
                        clearable
                    />
                </el-form-item>
                <el-form-item label="重复密码"  prop="passwordConfirm">
                    <el-input
                        @keyup.enter="addSalesman('addSalesmanForm')"
                        v-model="formData.passwordConfirm"
                        clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="formRequesting" @click="dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="formRequesting" @click="addSalesman('addSalesmanForm')" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
import ManagerFrame from "../components/ManagerFrame";
export default {
    name: "SalesmanList",
    components: {ManagerFrame},
    mounted() {
        this.loadData(1);
    },
    methods: {
        loadData(currentPage) {
            let $this = this;
            this.cardLoading = true;
            this.axios.post("/manager/salesman_list",{
                "currentPage": currentPage
            }).then(function(response) {
                $this.tableData = response.data.dataList;
                $this.rowCount = response.data.rowCount;
                $this.pageSize = response.data.pageSize;
                $this.currentPage = response.data.currentPage;
                $this.cardLoading = false;
            });
        },
        addSalesman(formName) {
            this.$refs[formName].validate((valid) => {
                if(valid) {
                    this.formRequesting = true;
                    let $this = this;
                    this.axios.post("/manager/add_salesman",{
                        "username": this.formData.username,
                        "password": this.formData.password
                    }).then(function(response) {
                        $this.formRequesting = false;
                        $this.dialogVisible = false;
                        if(response.data.result) {
                            $this.$message.success({
                                message: '添加成功',
                                type: 'success'
                            });
                            if($this.tableData.length === $this.pageSize) {
                                $this.tableData.pop();
                            }
                            $this.tableData.unshift(response.data.data);
                            $this.rowCount++;
                        } else {
                            $this.$message.error({
                                message: '添加失败,' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        handleCurrentChange(currentPage) {
            this.loadData(currentPage);
        }
    },
    data() {
        let passwordConfirmCheck = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formData.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        }
        return {
            cardLoading: true,
            dialogVisible: false,
            formRequesting: false,
            tableData: [],
            rowCount: 0,
            pageSize: 10,
            currentPage: 1,
            formData: {
                username: "",
                password: "",
                passwordConfirm: ""
            },
            rules: {
                username: [
                    { required: true, message: "账号不能为空", trigger: "change" },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'change' }
                ],
                password: [
                    { required: true, message: "密码不能为空", trigger: "change" },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'change' }
                ],
                passwordConfirm: [
                    { required: true, message: "请再次输入密码", trigger: "change" },
                    { validator: passwordConfirmCheck, trigger: "change" }
                ],
            },
        }
    }
}
</script>

<style scoped>

</style>