<template>
    <ManagerFrame default-active="ad-word">
        <el-card class="main" shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="广告词"
                        prop="word">
                    <el-input v-if="scope.row.edit" v-model="scope.row.word" class="ad-word-input" size="mini"></el-input>
                    <span v-else>{{scope.row.word}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="价钱"
                        prop="price">
                    <el-input v-if="scope.row.edit" v-model="scope.row.price" size="mini"></el-input>
                    <span v-else>{{scope.row.price}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="价格浮动"
                        prop="priceFloat">
                    <el-input v-if="scope.row.edit" v-model="scope.row.priceFloat" size="mini"></el-input>
                    <span v-else>{{scope.row.priceFloat}}</span>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="dialogVisible = !dialogVisible" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button v-if="!scope.row.edit"
                                   size="mini"
                                   @click="handleEdit(scope.row)">修改</el-button>
                        <el-button v-else size="mini" type="success" @click="handleSave(scope.row)">保存</el-button>
                        <el-button v-if="scope.row.edit"
                                   size="mini"
                                   @click="handleCancel(scope.row)">取消</el-button>
                        <el-button
                                v-else
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                title="添加广告词"
                v-model="dialogVisible"
                :close-on-click-modal="!formRequesting"
                :close-on-press-escape="false"
                :show-close="!formRequesting"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    v-loading="formRequesting"
                    size="small">
                <el-form-item label="关键词" prop="word">
                    <el-input
                        @keyup.enter="addAdWord"
                        v-model="formData.word"
                        clearable
                    />
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input
                        @keyup.enter="addAdWord"
                        v-model="formData.price"
                        clearable
                    />
                </el-form-item>
                <el-form-item label="价格浮动" prop="priceFloat">
                    <el-input
                        @keyup.enter="addAdWord"
                        v-model="formData.priceFloat"
                        clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" @click="addAdWord" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "AdWord",
        components: {ManagerFrame},
        mounted() {
            let $this = this;
            this.axios.post("/manager/ad_word_list").then(function(response) {
                $this.tableData = response.data;
                $this.cardLoading = false;
            });
        },
        methods: {
            addAdWord() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        this.formRequesting = true;
                        let $this = this;
                        this.axios.post("/manager/add_ad_word", {
                            "word": this.formData.word,
                            "price": this.formData.price,
                            "priceFloat": this.formData.priceFloat
                        }).then(function(response) {
                            $this.formRequesting = false;
                            $this.dialogVisible = false;
                            if(response.data.result) {
                                $this.$message.success({
                                    message: '添加成功',
                                    type: 'success'
                                });
                                $this.tableData.unshift(response.data.data);
                            } else {
                                $this.$message.error({
                                    message: '添加失败,' + response.data.message,
                                    type: 'error'
                                });
                            }
                        });
                    }
                });
            },
            handleEdit(data) {
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true
            },
            handleCancel(data) {
                data.wore = data.oldData.word;
                data.price = data.oldData.price;
                data.priceFloat = data.oldData.priceFloat;
                data.edit = false
            },
            handleSave(data) {
                let $this = this;
                this.cardLoading = true;
                this.axios.post("/manager/update_ad_word", data).then(function(response) {
                    $this.cardLoading = false;
                    if(response.data.result) {
                        data.edit = false;
                        $this.$message.success({
                            message: '修改成功',
                            type: 'success'
                        });
                    } else {
                        $this.$message.error({
                            message: '修改失败,' + response.data.message,
                            type: 'error'
                        });
                    }
                });
            },
            handleDelete(index, data) {
                let $this = this;
                this.$confirm('此操作将删除该广告词和广告链接, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/delete_ad_word", data).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.tableData.splice(index, 1);
                            $this.$message.success({
                                message: '删除成功',
                                type: 'success'
                            });
                        } else {
                            $this.$message.error({
                                message: '删除失败! ' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                });
            }
        },
        data() {
            let priceCheck = (rule, value, callback) => {
                let i = parseFloat(value);
                if (value === "") {
                    callback(new Error("请输入价格"));
                } else if (isNaN(value)) {
                    callback(new Error("只能输入数字"));
                } else if((i <= 0) || (i > 99999.99)) {
                    callback(new Error("数字必须大于 0 ,小于 100000"));
                }else {
                    callback();
                }
            }
            return {
                dialogVisible: false,
                formRequesting: false,
                cardLoading: true,
                tableData: [],
                formData: {
                    word: "",
                    price: "",
                    priceFloat: ""
                },
                rules: {
                    word: [
                        { required: true, message: "不能为空", trigger: "change" },
                        { max: 20, message: "长度在不能超过 50 个字符", trigger: "change" }
                    ],
                    price: [
                        { required: true, message: "不能为空", trigger: "change" },
                        { validator: priceCheck, trigger: "change" }
                    ],
                    priceFloat: [
                        { required: true, message: "不能为空", trigger: "change" },
                        { pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/, message: "只能输入数字", trigger: "change" }
                    ],
                }
            }
        }
    }
</script>

<style>
    .main .el-input__inner {
        width: 80px;
        text-align: center;
    }
    .main .ad-word-input input {
        width: 150px;
    }
</style>