<template>
    <ManagerFrame default-active="posterSettings">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        width="250"
                        label="名称"
                        prop="name">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="图片">
                    <el-image
                            :style="{width:'60px', verticalAlign:'middle', marginRight:'10px'}"
                            :hide-on-click-modal="true"
                            :src="scope.row.imgBase64"
                            :preview-src-list="[scope.row.imgBase64]">
                        <template #error>
                            <span :style="{color:'#909399', cursor:'pointer'}">无</span>
                        </template>
                    </el-image>
                    <el-button @click="scope.row.imgBase64 = null" v-show="scope.row.edit && scope.row.imgBase64" type="danger" size="small">删除</el-button>
                    <input @change="imgInputChange($event, scope.$index)" type="file" v-show="scope.row.edit" :style="{width:'130px', marginLeft:'10px'}" accept="image/jpeg, image/png, image/jpg" />
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="持续时间(秒)">
                    <el-input v-if="scope.row.edit" v-model="scope.row.duration" size="mini"></el-input>
                    <span v-else>{{scope.row.duration}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="路由">
                    <el-input v-if="scope.row.edit" v-model="scope.row.route" size="mini"></el-input>
                    <span v-else>{{scope.row.route}}</span>
                </el-table-column>
                <el-table-column
                        width="150"
                        #default="scope"
                        align="right">
                    <el-button v-if="!scope.row.edit"
                               size="mini"
                               @click="handleEdit(scope.row)">修改</el-button>
                    <el-button v-else size="mini" type="success" @click="handleSave(scope.row)">保存</el-button>
                    <el-button v-if="scope.row.edit"
                               size="mini"
                               @click="handleCancel(scope.row)">取消</el-button>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";

    export default {
        name: "PosterSettings",
        components: {ManagerFrame},
        data() {
            return {
                cardLoading: false,
                tableData: [],
                rowCount: 0,
                pageSize: 10,
                currentPage: 1
            }
        },
        mounted() {
            this.currentPage = this.$route.params.currentPage;
            this.loadData();
        },
        methods: {
            handleEdit(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true;
            },
            handleCancel(data) {
                data.name = data.oldData.name;
                data.imgBase64 = data.oldData.imgBase64;
                data.edit = false;
            },
            imgInputChange(e, index) {
                let $this = this;
                let input = e.target;
                let file = input.files[0];
                if(file.size / 1024 /1024 > 1) {
                    this.$message.error({
                        message: "图片大小不能大于1M",
                        type: "error"
                    });
                    input.value = "";
                    return;
                }
                let imgFile = new FileReader();
                imgFile.readAsDataURL(file);
                imgFile.onload = function() {
                    $this.tableData[index].imgBase64 = this.result;
                }
            },
            loadData() {
                this.cardLoading = true;
                this.axios.post("/manager/poster_settings",{
                    "currentPage": this.currentPage
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.cardLoading = false;
                });
            },
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.$router.push("/salesman/poster_settings/" + currentPage);
                this.loadData();
            },
            handleSave(data) {
                if(data.duration !== null) {
                    let temp = (data.duration + "").trim();
                    if(/^[0-9]+$/.test(temp)) {
                        data.duration = parseInt(temp);
                    } else {
                        data.duration = null;
                    }
                }
                this.cardLoading = true;
                this.axios.post("/manager/update_poster_setting", data, {"type": "text"}).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        data.edit = false;
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>