<template>
    <el-dialog
            :model="model"
            @open="openCallback"
            width="400px">
        <template #title>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-select v-model="selectedHierarchyId" placeholder="请选择制度" size="small">
                        <el-option v-for="row in hierarchyList" :key="row.id" :value="row.id" :label="row.name">
                            {{row.name}}
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="12">
                    <el-button @click="changeHierarchy" type="primary" size="small">更改</el-button>
                </el-col>
            </el-row>
        </template>
        <el-table
                :data="tableData"
                v-loading="loading"
                stripe="true"
                style="width: 100%">
            <el-table-column
                    label="标题"
                    prop="title">
            </el-table-column>
            <el-table-column
                    label="层级"
                    prop="level">
            </el-table-column>
            <el-table-column
                    label="收益"
                    prop="revenueRate">
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
    export default {
        name: "UserHierarchyDialog",
        props: {
            model: Boolean,
            userData: Object,
            changeHierarchyCallBack: Function()
        },
        data() {
            return {
                loading: true,
                tableData: [],
                hierarchyList: [],
                selectedHierarchyId: ""
            }
        },
        methods: {
            openCallback() {
                this.tableData = [];
                this.loading = true;
                this.axios.post("/salesman/get_hierarchy_list").then((response) => {
                    this.hierarchyList = response.data;
                    this.loading = false;
                });
                this.loadHierarchyList();
            },
            changeHierarchy() {
                if(isNaN(this.selectedHierarchyId)) {
                    return;
                }
                this.axios.post("/salesman/change_user_hierarchy", {
                    "userId": this.userData.id,
                    "hierarchyId": this.selectedHierarchyId
                }).then((response) => {
                    if(response.data.result) {
                        this.$message({
                            type: "success",
                            message: response.data.message
                        });
                        this.changeHierarchyCallBack(response.data.data);
                        this.loadHierarchyList();
                    }
                });
            },
            loadHierarchyList() {
                this.loading = true;
                this.axios.post("/salesman/get_user_hierarchy_level_list", {
                    "userId": this.userData.id
                }).then((response) => {
                    this.tableData = response.data;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>