import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login";
import AdWord from "../views/AdWord";
import AdLink from "../views/AdLink";
import SystemConfigs from "../views/SystemConfigs";
import SalesmanList from "../views/SalesmanList";
import UserList from "../views/UserList";
import WebsiteSetting from "../views/WebsiteSetting";
import WithdrawalList from "../views/WithdrawalList";
import PaymentMethod from "../views/PaymentMethod";
import RechargeSetting from "../views/RechargeSetting";
import WebsitePrice from "../views/WebsitePrice";
import OrderList from "../views/OrderList";
import ValidateUserWebsite from "../views/ValidateUserWebsite";
import SalesmanHome from "../views/SalesmanHome";
import WebsiteUpgradePrice from "../views/WebsiteUpgradePrice";
import PaySuccess from "../views/PaySuccess";
import UserRegister from "../views/UserRegister";
import InviteConfigs from "../views/InviteConfigs";
import SpreadAwardSetting from "../views/SpreadAwardSetting";
import RegisterSuccess from "../views/RegisterSuccess";
import MembershipLevelSetting from "../views/MembershipLevelSetting";
import ConditionAndProfitName from "../views/ConditionAndProfitName";
import RankingList from "../views/RankingList";
import PosterSettings from "../views/PosterSettings";
import WithdrawalConfigs from "../views/WithdrawalConfigs";
import UserHierarchies from "../views/UserHierarchies";
import WebsiteRamSettings from "../views/WebsiteRamSettings";
import ContactUs from "../views/user/ContactUs";
import Index from "../views/user/Index";
import CouponSettings from "../views/CouponSettings";
import CouponDistribution from "../views/CouponDistribution";
import GetCoupon from "../views/user/GetCoupon";
import AutoDistributeCoupon from "../views/AutoDistributeCoupon";
import GoogleOAuth from "../views/user/GoogleOAuth";
import PrivacyPolicy from "../views/PrivacyPolicy";
import AppWebRedirect from "../views/user/AppWebRedirect";

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/home",
    component: Index
  },
  {
    path: "/index/:str?",
    component: Index
  },
  {
    path: "/about_us",
    component: Index
  },
  {
    path: "/pay_success",
    component: PaySuccess
  },
  {
    path: "/register/:authCode",
    component: UserRegister
  },
  {
    path: "/register_success",
    component: RegisterSuccess
  },
  {
    path: "/app_web_redirect",
    component: AppWebRedirect
  },
  {
    path: "/coupon/:randomCode/:username?",
    component: GetCoupon
  },
  {
    path: "/contact",
    component: ContactUs
  },
  {
    path: "/google_oauth",
    component: GoogleOAuth
  },
  {
    path: "/privacy_policy",
    component: PrivacyPolicy
  },
  {
    path: "/manager",
    redirect: "/manager/salesman_list"
  },
  {
    path: "/manager/salesman_list",
    component: SalesmanList,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/ad_word",
    component: AdWord,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/ad_link",
    component: AdLink,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/website_setting",
    component: WebsiteSetting,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/user_hierarchies",
    component: UserHierarchies,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/membership_level_setting",
    component: MembershipLevelSetting,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/condition_profit_name",
    component: ConditionAndProfitName,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/invite_configs",
    component: InviteConfigs,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/spread_award_setting",
    component: SpreadAwardSetting,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/withdrawal_configs",
    component: WithdrawalConfigs,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/system_configs",
    component: SystemConfigs,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/poster_settings/:currentPage?",
    component: PosterSettings,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/payment_method",
    component: PaymentMethod,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/recharge_setting",
    component: RechargeSetting,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/website_price",
    component: WebsitePrice,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/website_upgrade_price",
    component: WebsiteUpgradePrice,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/website_ram_setting",
    component: WebsiteRamSettings,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/coupon_settings",
    component: CouponSettings,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/coupon_distribution/:currentPage?",
    component: CouponDistribution,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/auto_distribute_coupon/:currentPage?",
    component: AutoDistributeCoupon,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/manager/login",
    component: Login
  },
  {
    path: "/salesman",
    redirect: "/salesman/home"
  },
  {
    path: "/salesman/login",
    component: Login
  },
  {
    path: "/salesman/home/:shortcuts?/:dateStart?/:dateEnd?",
    component: SalesmanHome,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/salesman/user_list/:currentPage?/:searchWord?",
    component: UserList,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/salesman/order_list/:status?/:currentPage?/:timeFilter?",
    component: OrderList,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/salesman/search_order/:searchWord?/:currentPage?",
    component: OrderList,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/salesman/validate_user_website/:currentPage?/:searchWord?",
    component: ValidateUserWebsite,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/salesman/withdrawal_list/:status?/:currentPage?/:searchWord?",
    component: WithdrawalList,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/salesman/ranking_list/:shortcuts?/:dateStart?/:dateEnd?",
    component: RankingList,
    meta: {
      requireAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});
router.beforeEach((to, from, next) => {
  let path = to.path;
  let managerName = localStorage.getItem("managerName");
  let salesmanName = localStorage.getItem("salesmanName");
  let username = localStorage.getItem("username");
  if(path === "/manager/login") {
    if(managerName != null) {
      document.location.href = "./";
    } else {
      next();
    }
  } else if(path === "/salesman/login") {
    if(salesmanName != null) {
      document.location.href = "./";
    } else {
      next();
    }
  } else if(path === "/user/login") {
    if(username != null) {
      document.location.href = "./";
    } else {
      next();
    }
  } else {
    if(to.meta.requireAuth) {
      if(path.indexOf("/manager/") > -1) {
        if(managerName == null) {
          next("/manager/login");
        } else {
          next();
        }
      } else if(path.indexOf("/salesman/") > -1) {
        if(salesmanName == null) {
          next("/salesman/login");
        } else {
          next();
        }
      } else if(path.indexOf("/user/") > -1) {
        if(username == null) {
          next("/user/login");
        } else {
          next();
        }
      } else {
        document.location.href = "/";
      }
    } else {
      next();
    }
  }
});

export default router;