<template>
    <SalesmanFrame default-active="validateUserWebsite">
        <el-card shadow="always" v-loading="cardLoading">
            <el-row :gutter="10">
                <el-col :span="3" :offset="17" :style="{textAlign:'right',marginBottom:'20px'}">
                    <el-button @click="batchAdopt" size="small" type="primary">批量通过</el-button>
                </el-col>
                <el-col :span="4">
                    <el-input
                            @keyup.enter="handleSearch"
                            placeholder="搜索"
                            clearable
                            size="small"
                            v-model="searchWord">
                        <template #prefix>
                            <i class="el-input__icon el-icon-search"></i>
                        </template>
                    </el-input>
                </el-col>
            </el-row>
            <el-collapse accordion @change="collapseChange">
                <el-collapse-item v-for="(data, index) in dataList" :key="data.id" :name="index">
                    <template #title>
                        <el-row :style="{width:'100%'}">
                            <el-col :span="12">{{data.username}}</el-col>
                            <el-col :span="12">数量:{{data.count}}</el-col>
                        </el-row>
                    </template>
                    <el-table
                            :data="data.tableData"
                            :show-header="false"
                            stripe="true"
                            style="width: 100%">
                        <el-table-column type="index"></el-table-column>
                        <el-table-column
                                label="url"
                                prop="url">
                        </el-table-column>
                        <el-table-column
                                label="创建时间"
                                :formatter="formatDate"
                                prop="createTime">
                        </el-table-column>
                        <el-table-column
                                #default="scope"
                                align="right"
                                label="操作">
                            <el-button @click="adopt(index, scope.$index)" size="small" type="success">通过</el-button>
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
    </SalesmanFrame>
</template>

<script>
    import SalesmanFrame from "../components/SalesmanFrame";
    export default {
        name: "ValidateUserWebsite",
        components: {SalesmanFrame},
        mounted() {
            this.searchWord = this.$route.params.searchWord;
            this.currentPage = this.$route.params.currentPage;
            this.loadData();
        },
        data() {
            return {
                cardLoading: true,
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                searchWord: "",
                dataList: []
            }
        },
        methods: {
            loadData() {
                let $this = this;
                this.cardLoading = true;
                this.axios.post("/salesman/pending_website_user_list",{
                    "currentPage": this.currentPage,
                    "searchWord": this.searchWord
                }).then(function(response) {
                    $this.dataList = response.data.dataList
                    $this.rowCount = response.data.rowCount;
                    $this.pageSize = response.data.pageSize;
                    $this.currentPage = response.data.currentPage;
                    $this.cardLoading = false;
                });
            },
            handleSearch() {
                document.location.href = "/salesman/validate_user_website/1/" + this.searchWord;
            },
            handleCurrentChange(currentPage) {
                this.$router.push("/salesman/validate_user_website/" + currentPage);
                this.currentPage = currentPage;
                this.loadData();
            },
            collapseChange(index) {
                if(index === "") {
                    return;
                }
                if(this.dataList[index].tableData == null) {
                    let $this = this;
                    this.axios.post("/salesman/user_pending_website_list",{
                        "id": this.dataList[index].id
                    }).then(function(response) {
                        $this.dataList[index].tableData = response.data
                    });
                }
            },
            adopt(dataIndex, scopeIndex) {
                this.$confirm("确定通过审核吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    let $this = this;
                    this.cardLoading = true;
                    this.axios.post("/salesman/adopt_user_website",{
                        "id": this.dataList[dataIndex].tableData[scopeIndex].id
                    }).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                            if($this.dataList[dataIndex].tableData.length === 1) {
                                $this.dataList.splice(dataIndex, 1);
                                $this.rowCount--;
                            } else {
                                $this.dataList[dataIndex].tableData.splice(scopeIndex, 1);
                            }
                        } else {
                            $this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            batchAdopt() {
                this.$confirm("所有用户各审核一个网站,但当天购买的网站不会通过审核,确定操作吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    let $this = this;
                    this.cardLoading = true;
                    this.axios.post("/salesman/batch_adopt_user_website").then(function(response) {
                        $this.cardLoading = false;
                        $this.$alert(response.data.message, "提示", {
                            confirmButtonText: "确定",
                            callback: () => {
                                if(response.data.result) {
                                    document.location.href = "/salesman/validate_user_website";
                                }
                            }
                        });
                    });
                });
            }
        }
    }
</script>

<style scoped>
</style>