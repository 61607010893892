import { createApp } from "vue";
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from "./App.vue";
import router from "./router";
import qs from "qs"
import utils from "@/utils";

const app = createApp(App).use(ElementPlus, {
    locale: zhCn,
}).use(router).use(VueAxios, axios).use(utils);

//axios.defaults.baseURL = "http://localhost:8080/leap_server_war/api";
axios.defaults.baseURL = "https://www.adopsandadtech.in:8443/api";
axios.defaults.withCredentials = true;
axios.interceptors.response.use(function(response) {
    if(response.data.statusCode === undefined) {
        return response;
    }
    if(response.data.statusCode === 0) {
        response.data = response.data.body;
        return response;
    } else if(response.data.statusCode === 1) {
        //没有登录
        localStorage.clear();
        app.config.globalProperties.$alert("登录超时，请重新登录!", "提示", {
            confirmButtonText: "确定",
            showClose: false,
            type: "error"
        }).then(() => {
            document.location.href = "login";
        });
    } else {
        app.config.globalProperties.$alert(response.data.message, "提示", {
            confirmButtonText: "确定",
            showClose: false,
            type: "error"
        })
    }
});
axios.interceptors.request.use(function(config) {
    switch(config["type"]) {
        case "file":
            config.headers["Content-Type"] = "multipart/form-data";
            break;
        case "json":
            config.headers["Content-Type"] = "application/json;charset=UTF-8";
            break;
        case "text":
            config.headers["Content-Type"] = "text/plain;charset=UTF-8";
            break;
        default:
            if(config.data) {
                config.data["lang"] = navigator.language;
            } else {
                config.data = {"lang": navigator.language};
            }
            config.data = qs.stringify(config.data);
    }
    return config;
});
app.mount("#app");