<template>
    <el-skeleton v-if="status === 0" :rows="5" animated />
    <el-result
            v-else-if="status === 1"
            icon="error"
            title="Error Tips"
            :sub-title="errorMsg"
    ></el-result>
    <el-result
            v-else-if="status === 2"
            icon="warning"
            title="Warning Tips"
            sub-title="Your Google account is not sign up for Google Adsense.">
        <template #extra>
            <div class="step-content">
                <el-steps direction="vertical" :active="stepActiveIndex">
                    <el-step title="Step 1: Sign up for Google Adsense">
                        <template #description>
                            <p>Click the "Sign Up" button below to log in to Google and sign up for Google Adsense.</p>
                            <el-button @click="redirectAdsense" class="step-btn" type="primary" size="small">Sign Up</el-button>
                            <a id="redirect-adsense" target="_blank" href="https://www.google.com/adsense/login3?sourceid=aso&marketing=true&noaccount=false"></a>
                        </template>
                    </el-step>
                    <el-step title="Step 2: License">
                        <template #description>
                            <p>Click the "License" button below to license your Google AdSense to OPS.</p>
                            <el-button @click="bindAdsenseCode" class="step-btn" type="primary" size="small">License</el-button>
                        </template>
                    </el-step>
                </el-steps>
            </div>
            <div class="video-tutorial">
                <p>The video below shows you how to sign up for Google AdSense using Gmail.</p>
                <img :style="{width:'100%'}" src="../../assets/signup_adsense.gif">
            </div>
        </template>
    </el-result>
    <el-result
            v-else-if="status === 3"
            icon="success"
            title="Success Tips"
            sub-title="Licensed Successfully"
    ></el-result>
</template>

<script>
    export default {
        name: "GoogleOAuth",
        mounted() {
            let params = new URLSearchParams(location.search);
            this.googleCode = params.get("code")
            this.username = params.get("state")
            if(!(this.googleCode && this.username)) {
                this.errorMsg = this.noOAuthErrorMsg;
                this.status = 1;
            } else {
                this.bindAdsenseCode();
            }
        },
        data() {
            return {
                status: 0,
                googleCode: null,
                username: null,
                tokenKey: "token",
                tokenExpireKey: "tokenExpire",
                errorMsg: "",
                noOAuthErrorMsg: "Please license your Google Adsense to OPS.",
                stepActiveIndex: 1
            }
        },
        methods: {
            async bindAdsenseCode() {
                this.status = 0;
                await this.initGoogleToken();
                let token = window.sessionStorage.getItem(this.tokenKey);
                if(!token) {
                    this.errorMsg = this.noOAuthErrorMsg;
                    this.status = 1;
                    return;
                }
                try {
                    let response = await this.axios.get("https://adsense.googleapis.com/v2/accounts?access_token=" + token, {withCredentials: false});
                    if(!response.data["accounts"]) {
                        this.status = 2;
                        return;
                    }

                    let code = response.data["accounts"][0]["name"];
                    this.axios.post("/user/license_ad_sense_code", {
                        "username": this.username,
                        "code": code
                    }).then((response) => {
                        if(response.data["result"]) {
                            this.status = 3;
                        } else {
                            this.errorMsg = response.data.message;
                            this.status = 1;
                        }
                    });
                } catch (err) {
                    this.errorMsg = this.noOAuthErrorMsg;
                    this.status = 1;
                }
            },
            async initGoogleToken() {
                let token = window.sessionStorage.getItem(this.tokenKey);
                if(token) {
                    let tokenExpire = parseInt(window.sessionStorage.getItem(this.tokenExpireKey));
                    if(new Date().getTime() > tokenExpire) {
                        await this.requestGoogleToken();
                    }
                } else {
                    await this.requestGoogleToken();
                }
            },
            async requestGoogleToken() {
                let response = await this.axios.post("/user/get_google_token_params", {
                    "code": this.googleCode
                });
                if(response.data.result) {
                    let params = response.data.data;
                    try {
                        response = await this.axios.post("https://oauth2.googleapis.com/token", params, {withCredentials: false});
                    } catch(e) {
                        window.sessionStorage.clear();
                        this.errorMsg = this.noOAuthErrorMsg;
                        this.status = 1;
                        return;
                    }
                    if(response.data["access_token"]) {
                        let expire = response.data["expires_in"] - 60;
                        window.sessionStorage.setItem(this.tokenKey, response.data["access_token"]);
                        window.sessionStorage.setItem(this.tokenExpireKey, (new Date().getTime() + expire * 1000).toString());
                    } else {
                        window.sessionStorage.clear();
                        this.errorMsg = this.noOAuthErrorMsg;
                        this.status = 1;
                    }
                } else {
                    this.errorMsg = response.data.message;
                    this.status = 1;
                }
            },
            redirectAdsense() {
                this.stepActiveIndex = 2;
                let aEle = document.getElementById("redirect-adsense");
                aEle.click();
            }
        }
    }
</script>

<style scoped>
    #redirect-adsense {
        display: none;
    }
    .step-content p {
        color: #333;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    @media only screen and (min-width: 992px) {
        .step-content .step-btn {
            display: inline-block;
            margin-bottom: 50px;
            margin-top: 20px;
            color: #fff;
            width: 100px;
        }
        .video-tutorial {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }
        .video-tutorial p {
            margin-bottom: 20px;
        }
    }
    @media only screen and (max-width: 992px) {
        .step-content .step-btn {
            display: inline-block;
            margin-bottom: 20px;
            color: #fff;
            width: 100px;
        }
        .video-tutorial {
            padding: 20px 20px;
        }
        .video-tutorial p {
            margin-bottom: 20px;
        }
    }
</style>