<template>
    <span @click="handleClick" :class="spanClass">{{mobileNumber}}</span>
</template>

<script>
    export default {
        name: "MobileNumberSpan",
        props: {
            mobileNumber: String
        },
        mounted() {
            this.spanClass = this.getSpanClass();
            this.waUrl = "https://wa.me/+91" + this.mobileNumber;
        },
        data() {
            return {
                waUrl: "",
                spanClass: ""
            }
        },
        watch: {
            mobileNumber: {
                handler(newVal) {
                    this.waUrl = "https://wa.me/+91" + newVal;
                    this.spanClass = this.getSpanClass();
                },
                deep: true
            }
        },
        methods: {
            handleClick() {
                let input = document.createElement("textarea");
                input.style.opacity = "0";
                input.style.position = "absolute";
                input.value = this.waUrl;
                document.body.appendChild(input);
                input.select();
                input.setSelectionRange(0, this.waUrl.length);
                document.execCommand("copy");
                document.body.removeChild(input);
                window.localStorage.setItem(this.mobileNumber, "true");
                this.spanClass = this.getSpanClass();
                this.$message.success({
                    message: "成功复制 " + this.mobileNumber,
                    type: "success"
                });
            },
            getSpanClass() {
                return window.localStorage.getItem(this.mobileNumber) === "true" ? "mobile-number history" : "mobile-number";
            }
        }
    }
</script>

<style scoped>
.mobile-number {
    text-decoration: underline;
    color: #409EFF;
    cursor: pointer;
}
.history {
    color: #F56C6C;
}
</style>