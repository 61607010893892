<template>
    <ManagerFrame default-active="membershipLevel">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="名称">
                    <el-input v-if="scope.row.edit" v-model="scope.row.name" size="mini"></el-input>
                    <span v-else>{{scope.row.name}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="别名">
                    <el-input v-if="scope.row.edit" v-model="scope.row.alias" size="mini"></el-input>
                    <span v-else>{{scope.row.alias}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        width="80"
                        label="升级奖励">
                    <el-input v-if="scope.row.edit && (scope.row.level > 0)" v-model="scope.row.levelUpAward" size="mini"></el-input>
                    <span v-else>{{scope.row.levelUpAward}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        width="60"
                        label="状态">
                    <el-switch v-if="scope.row.edit && (scope.row.level > 0)" v-model="scope.row.active" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="scope.row.active" :style="{color:'#67C23A'}">正常</span>
                        <span v-else :style="{color:'#F56C6C'}">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="条件">
                    <div @click="showConditionDialog(scope.$index)" :style="{cursor:'pointer'}">
                        <span v-if="scope.row.conditionList.length === 0">无</span>
                        <template v-else>
                            <p v-for="(row) in scope.row.conditionList" :key="row.id">
                                {{row.alias}}: {{row.amount}}
                            </p>
                        </template>
                    </div>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="权益">
                    <div @click="showProfitDialog(scope.$index)" :style="{cursor:'pointer'}">
                        <span v-if="scope.row.profitList.length === 0">无</span>
                        <template v-else>
                            <p v-for="(row) in scope.row.profitList" :key="row.id">
                                {{row.alias}}: {{row.amount}}
                            </p>
                        </template>
                    </div>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="addFormData.dialogVisible = true" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button v-if="(scope.$index == tableData.length - 1) && (scope.row.level > 1) && (!scope.row.edit)"
                                   size="mini"
                                   type="danger"
                                   @click="handleDeleteMembershipLevel(scope.$index, scope.row)">删除</el-button>
                        <el-button v-if="!scope.row.edit"
                                   size="mini"
                                   @click="handleEdit(scope.row)">修改</el-button>
                        <template v-else>
                            <el-button size="mini" type="success" @click="handleSaveMembershipLevel(scope.row)">保存</el-button>
                            <el-button size="mini" @click="handleCancelMembershipLevel(scope.row)">取消</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                title="添加等级"
                v-model="addFormData.dialogVisible"
                :close-on-click-modal="!addFormData.requesting"
                :close-on-press-escape="false"
                :show-close="!addFormData.requesting"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="addRules"
                    :label-position="left"
                    label-width="80px"
                    :model="addFormData"
                    size="small">
                <el-form-item label="名称" prop="name">
                    <el-input
                            @keyup.enter="addMembershipLevel"
                            v-model="addFormData.name"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="别名" prop="alias">
                    <el-input
                            @keyup.enter="addMembershipLevel"
                            v-model="addFormData.alias"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="升级奖励" prop="levelUpAward">
                    <el-input
                            @keyup.enter="addMembershipLevel"
                            v-model="addFormData.levelUpAward"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addFormData.requesting" @click="addFormData.dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addFormData.requesting" @click="addMembershipLevel" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
                :title=conditionAndProfitTitle
                v-model="conditionAndProfitDialogVisible"
                :close-on-click-modal="!conditionAndProfitRequesting"
                :close-on-press-escape="false"
                :show-close="!conditionAndProfitRequesting"
                v-loading="conditionAndProfitRequesting"
                width="800px">
            <el-table
                    :data="conditionAndProfitTableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        label="名称"
                        prop="name">
                </el-table-column>
                <el-table-column
                        label="别名"
                        prop="alias">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="数量">
                    <el-input v-if="scope.row.edit" v-model="scope.row.amount" size="mini"></el-input>
                    <span v-else>{{scope.row.amount}}</span>
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="showAddConditionAndProfitDialog" type="primary" size="small">添加</el-button>
                    </template>
                    <template #default="scope">
                        <template v-if="!scope.row.edit">
                            <el-button size="mini" @click="handleEdit(scope.row)">修改</el-button>
                            <el-button size="mini" type="danger" @click="handleDeleteConditionAndProfit(scope.$index, scope.row)">删除</el-button>
                        </template>
                        <template v-else>
                            <el-button size="mini" type="success" @click="handleSaveConditionAndProfit(scope.row)">保存</el-button>
                            <el-button size="mini" @click="handleCancelConditionAndProfit(scope.row)">取消</el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
                :title=addConditionAndProfitTitle
                v-model="addConditionAndProfitFormData.dialogVisible"
                :close-on-click-modal="!addConditionAndProfitFormData.requesting"
                :close-on-press-escape="false"
                :show-close="!addConditionAndProfitFormData.requesting"
                width="400px">
            <el-form
                    ref="addConditionAndProfitForm"
                    :rules="addConditionAndProfitRules"
                    :label-position="left"
                    label-width="80px"
                    :model="addConditionAndProfitFormData"
                    size="small"
                    v-loading="addConditionAndProfitFormData.requesting">
                <el-form-item label="类型" prop="type">
                    <el-select v-model="addConditionAndProfitFormData.type" placeholder="请选择类型" :style="{width:'100%'}">
                        <el-option v-for="row in conditionAndProfitList" :key="row.type" :value="row.type" :label="row.name + '(' +row.alias + ')'">
                            {{row.name}}({{row.alias}})
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数量"  prop="amount">
                    <el-input
                            @keyup.enter="addConditionAndProfit"
                            v-model="addConditionAndProfitFormData.amount"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addConditionAndProfitFormData.requesting" @click="addConditionAndProfitFormData.dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addConditionAndProfitFormData.requesting" @click="addConditionAndProfit" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";

    export default {
        name: "MembershipLevelSetting",
        components: {ManagerFrame},
        mounted() {
            this.loadData();
        },
        data() {
            return {
                cardLoading: false,
                tableData: [],
                conditionAndProfitDialogVisible: false,
                conditionAndProfitRequesting: false,
                conditionAndProfitTitle: "",
                handleConditionOrProfit: "",
                conditionAndProfitTableData: [],
                conditionAndProfitList: [],
                addConditionAndProfitTitle: "",
                handleRowIndex: -1,
                addFormData: {
                    name: "",
                    alias: "",
                    levelUpAward: 0,
                    requesting: false,
                    dialogVisible: false
                },
                addConditionAndProfitFormData: {
                    type: "",
                    amount: "",
                    requesting: false,
                    dialogVisible: false
                },
                addRules: {
                    name: [
                        { required: true, message: "名称不能为空", trigger: "change" },
                        { max: 49, message: "长度在不能超过 49 个字符", trigger: "change" }
                    ],
                    alias: [
                        { required: true, message: "别名不能为空", trigger: "change" },
                        { max: 49, message: "长度在不能超过 49 个字符", trigger: "change" }
                    ],
                    levelUpAward: [
                        {
                            pattern: /^[0-9]{1,6}\.?[0-9]{0,2}$/,
                            message: "升级奖励范围只能是 0 - 999999.99",
                            trigger: "blur"
                        },
                    ]
                },
                addConditionAndProfitRules: {
                    type: [
                        { required: true, message: "请选择类型", trigger: "change" }
                    ],
                    amount: [
                        {
                            pattern: /^[0-9]{1,6}\.?[0-9]{0,4}$/,
                            message: "数量范围只能是 0 - 999999.9999",
                            trigger: "blur"
                        }
                    ]
                },
            }
        },
        methods: {
            loadData() {
                this.cardLoading = true;
                this.axios.post("/manager/get_membership_level_list").then((response) => {
                    this.cardLoading = false;
                    this.tableData = response.data;
                });
            },
            handleEdit(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true
            },
            handleCancelMembershipLevel(data) {
                data.name = data.oldData.name;
                data.alias = data.oldData.alias;
                data.levelUpAward = data.oldData.levelUpAward;
                data.active = data.oldData.active;
                data.edit = false
            },
            handleSaveMembershipLevel(data) {
                let levelUpAwardStr = data.levelUpAward;
                if(levelUpAwardStr.toString().trim() === "" || isNaN(levelUpAwardStr)) {
                    this.$message.error({
                        message: "请输入正确金额!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(levelUpAwardStr) < 0) || (parseFloat(levelUpAwardStr) > 999999.99)) {
                    this.$message.error({
                        message: "升级奖励的范围只能是 0 - 99999999.99 !",
                        type: "error"
                    });
                    return;
                }
                this.cardLoading = true;
                this.axios.post("/manager/update_membership_level", data).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        data.edit = false;
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            },
            addMembershipLevel() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        this.addFormData.requesting = true;
                        this.axios.post("/manager/add_membership_level", this.addFormData).then((response) => {
                            this.addFormData.requesting = false;
                            if(response.data.result) {
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                                this.loadData();
                                this.addFormData.dialogVisible = false;
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },
            handleDeleteMembershipLevel(index, data) {
                this.$confirm("删除等级将影响用户的收益,是否继续?", '提示', {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_membership_level", data).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.tableData.splice(index,1);
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            showConditionDialog(index) {
                if(this.tableData[index].level === 0) {
                    this.$message.warning({
                        message: "体验等级不能添加条件!",
                        type: "warning"
                    });
                    return;
                }
                this.handleConditionOrProfit = "condition";
                this.conditionAndProfitTitle = this.tableData[index].name + " - " + this.tableData[index].alias;
                this.conditionAndProfitDialogVisible = true;
                this.conditionAndProfitTableData = this.tableData[index].conditionList;
                this.handleRowIndex = index;
                this.addConditionAndProfitTitle = "添加条件";
            },
            showProfitDialog(index) {
                if(this.tableData[index].level === 0) {
                    this.$message.warning({
                        message: "体验等级不能添加权益!",
                        type: "warning"
                    });
                    return;
                }
                this.handleConditionOrProfit = "profit";
                this.conditionAndProfitTitle = this.tableData[index].name + " - " + this.tableData[index].alias;
                this.conditionAndProfitDialogVisible = true;
                this.conditionAndProfitTableData = this.tableData[index].profitList;
                this.handleRowIndex = index;
                this.addConditionAndProfitTitle = "添加权益";
            },
            showAddConditionAndProfitDialog() {
                this.addConditionAndProfitFormData.dialogVisible = true;
                this.addConditionAndProfitFormData.type = "";
                this.addConditionAndProfitFormData.amount = "";
                this.axios.post("/manager/get_membership_condition_and_profit_list").then((response) => {
                    if(this.handleConditionOrProfit === "condition") {
                        this.conditionAndProfitList = response.data.conditionList;
                    } else if(this.handleConditionOrProfit === "profit") {
                        this.conditionAndProfitList = response.data.profitList;
                    }
                });
            },
            addConditionAndProfit() {
                this.$refs["addConditionAndProfitForm"].validate((value) => {
                    if(value) {
                        this.addConditionAndProfitFormData.requesting = true;
                        let params = {
                            "id": this.tableData[this.handleRowIndex].id,
                            "type": this.addConditionAndProfitFormData.type,
                            "amount": this.addConditionAndProfitFormData.amount
                        }
                        if(this.handleConditionOrProfit === "condition") {
                            this.axios.post("/manager/add_membership_level_up_condition", params).then((response) => {
                                this.addConditionAndProfitFormData.requesting = false;
                                if(response.data.result) {
                                    this.tableData[this.handleRowIndex].conditionList.unshift(response.data.data);
                                    this.addConditionAndProfitFormData.dialogVisible = false;
                                    this.$message.success({
                                        message: response.data.message,
                                        type: "success"
                                    });
                                } else {
                                    this.$message.error({
                                        message: response.data.message,
                                        type: "error"
                                    });
                                }
                            });
                        } else if(this.handleConditionOrProfit === "profit") {
                            this.axios.post("/manager/add_membership_level_profit", params).then((response) => {
                                this.addConditionAndProfitFormData.requesting = false;
                                if(response.data.result) {
                                    this.tableData[this.handleRowIndex].profitList.unshift(response.data.data);
                                    this.addConditionAndProfitFormData.dialogVisible = false;
                                    this.$message.success({
                                        message: response.data.message,
                                        type: "success"
                                    });
                                } else {
                                    this.$message.error({
                                        message: response.data.message,
                                        type: "error"
                                    });
                                }
                            });
                        }
                    }
                });
            },
            handleCancelConditionAndProfit(data) {
                data.amount = data.oldData.amount;
                data.edit = false;
            },
            handleDeleteConditionAndProfit(index, data) {
                this.$confirm("确定要删除吗?", '提示', {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.conditionAndProfitRequesting = true;
                    if(this.handleConditionOrProfit === "condition") {
                        this.axios.post("/manager/del_membership_level_up_condition", data).then((response) => {
                            this.conditionAndProfitRequesting = false;
                            if (response.data.result) {
                                this.tableData[this.handleRowIndex].conditionList.splice(index, 1);
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else if(this.handleConditionOrProfit === "profit") {
                        this.axios.post("/manager/del_membership_level_profit", data).then((response) => {
                            this.conditionAndProfitRequesting = false;
                            if (response.data.result) {
                                this.tableData[this.handleRowIndex].profitList.splice(index, 1);
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },
            handleSaveConditionAndProfit(data) {
                let amountStr = data.amount;
                if(amountStr.toString().trim() === "" || isNaN(amountStr)) {
                    this.$message.error({
                        message: "请输入正确的数量!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(amountStr) < 0) || (parseFloat(amountStr) > 999999.9999)) {
                    this.$message.error({
                        message: "数量范围只能是 0 - 999999.9999 !",
                        type: "error"
                    });
                    return;
                }
                this.conditionAndProfitRequesting = true;
                let url = "";
                if(this.handleConditionOrProfit === "condition") {
                    url = "/manager/update_membership_level_up_condition";
                } else if(this.handleConditionOrProfit === "profit") {
                    url = "/manager/update_membership_level_profit";
                }
                this.axios.post(url, {
                    "id": data.id,
                    "amount": data.amount
                }).then((response) => {
                    this.conditionAndProfitRequesting = false;
                    if(response.data.result) {
                        data.edit = false;
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>