<template>
    <el-skeleton v-if="status === 0" animated />
    <div v-else-if="status === 1" class="main-content">
        <el-row>
            <el-col :span="12">
                <div :style="{marginBottom:'10px'}"><router-link to="/"><img :style="{width:'100px'}" src="../../assets/aad_logo_pink.png"/></router-link></div>
                <div><img :style="{width:'100px'}" src="../../assets/ga.png" /></div>
            </el-col>
            <el-col :span="12" :style="{textAlign:'right'}">
                <el-badge :value="1" type="danger"><el-button class="customer-service-btn" @click="getCustomerService" size="small" icon="fab fa-whatsapp"> CHAT WITH US</el-button></el-badge>
            </el-col>
        </el-row>
        <div class="content-body">
            <p class="title">{{pageData.remark}}</p>
            <div class="countdown">
                <span>{{h}}</span>
                <strong>:</strong>
                <span>{{m}}</span>
                <strong>:</strong>
                <span>{{s}}</span>
            </div>
            <el-form
                    class="form"
                    ref="form"
                    :model="formData"
                    :rules="rules"
                    v-loading="loading"
            >
                <el-form-item prop="username">
                    <el-input
                            type="text"
                            placeholder="Please enter your OPS account."
                            v-model.trim="formData.username"
                            clearable
                    />
                </el-form-item>
                <el-button @click="onSubmit" class="submit-btn pink-btn">Submit</el-button>
            </el-form>
            <el-image
                    v-if="pageData.posterBase64"
                    :src="pageData.posterBase64"
                    :preview-src-list="[pageData.posterBase64]"
            />
            <el-row v-else class="coupon">
                <el-col class="coupon-left" :span="16">
                    <p class="coupon-title">{{pageData.description}}</p>
                    <p class="coupon-scope">{{pageData.scope}}</p>
                </el-col>
                <el-col class="coupon-right" :span="8">
                    <p><span>RM</span> {{pageData.discountAmount}}</p>
                </el-col>
            </el-row>
        </div>
    </div>
    <el-result
            v-else-if="status === 2"
            icon="error"
            title="Error Tip"
            :sub-title=errorSubTitle
    />
    <el-result
            id="result-success"
            v-else-if="status === 3"
            icon="success"
            title="Success Tip"
            :sub-title=successTitle
    >
        <template #extra>
            <el-row class="coupon" :style="{width:'300px'}">
                <el-col class="coupon-left" :span="16">
                    <p class="coupon-title">{{receivedCoupon.description}}</p>
                    <p class="coupon-scope">{{receivedCoupon.scope}}</p>
                    <p class="coupon-expire">{{receivedCoupon.remark}}</p>
                </el-col>
                <el-col class="coupon-right" :span="8">
                    <p><span>₹</span> {{receivedCoupon.discountAmount}}</p>
                </el-col>
            </el-row>
        </template>
    </el-result>
</template>

<script>
    export default {
        name: "GetCoupon",
        mounted() {
            this.axios.post("/user/coupon_distribution", {
                "code": this.$route.params.randomCode
            }).then((response) => {
                if(response.data.result) {
                    this.pageData = response.data.data;
                    //倒计时
                    this.countdown();
                    setInterval(this.countdown, 1000);

                    if(this.$route.params.username) {
                        this.formData.username = this.$route.params.username;
                        this.getCoupon();
                    } else {
                        this.status = 1;
                    }
                } else {
                    this.errorSubTitle = response.data.message;
                    this.status = 2;
                }
            });
        },
        data() {
            return {
                status: 0,
                pageData: {},
                h: "",
                m: "",
                s: "",
                formData: {},
                loading: false,
                errorSubTitle: "",
                successTitle: "",
                receivedCoupon: {},
                rules: {
                    username: [
                        { required: true, message: "Please enter your email/mobile number.", trigger: "change" }
                    ]
                }
            }
        },
        methods: {
            getCustomerService() {
                this.loading = true;
                let customerService = localStorage.getItem("customerService");
                this.axios.post("/user/get_customer_service", {
                    "customerService": customerService
                }).then((response) => {
                    this.loading = false;
                    if(response.data.result) {
                        customerService = response.data.data;
                        localStorage.setItem("customerService", customerService);
                        document.location.href = customerService;
                    } else {
                        this.$message.error(response.data.message);
                    }
                });
            },
            countdown() {
                let times = this.pageData.id;
                let h = parseInt(times / 3600);
                this.h = h < 10 ? "0" + h : h;
                let m = parseInt((times % 3600) / 60);
                this.m = m < 10 ? "0" + m : m;
                let s = parseInt(times % 60);
                this.s = s < 10 ? "0" + s : s;

                this.pageData.id--;
            },
            onSubmit() {
                this.$refs["form"].validate((valid) => {
                    if(valid) {
                        this.getCoupon();
                    } else {
                        return false;
                    }
                });
            },
            getCoupon() {
                this.loading = true;
                this.axios.post("/user/receive_coupon", {
                    "code": this.$route.params.randomCode,
                    "username": this.formData.username
                }).then((response) => {
                    this.loading = false;
                    if(response.data.result) {
                        this.status = 3;
                        this.receivedCoupon = response.data.data;
                    } else {
                        this.status = 1;
                        this.$message({
                            type: "error",
                            message: response.data.message
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .main-content {
        padding: 48px 40px 48px 40px;
    }
    .content-body {
        text-align: center;
    }
    .title {
        margin-top: 50px;
        margin-bottom: 20px;
        color: #333;
    }
    .countdown span {
        display: inline-block;
        width: 40px;
        padding: 10px 0;
        background-color: #2f2861;
        color: #fff;
        margin: 0 10px;
        border-radius: 4px;
    }
    .form {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .submit-btn {
        margin-top: 30px;
        width: 100%;
    }
    .coupon {
        padding: 10px 0 10px 20px;
        background-color: #eb5893;
        border-radius: 10px;
    }
    .coupon-left {
        text-align: left;
        padding: 10px 20px 10px 0;
        border-right: 1px #fdf6ec dashed;
        color: #fff;
    }
    .coupon-title {
        margin-bottom: 20px;
    }
    .coupon-scope {
        font-size: 13px;
        font-weight: 100;
    }
    .coupon-expire {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 200;
    }
    .coupon-right {
        display: table;
        text-align: center;
    }
    .coupon-right p {
        display: table-cell;
        vertical-align: middle;
        font-size: 24px;
        color: #fff;
    }
    .coupon-right p span {
        font-size: 16px;
    }
</style>