<template>
    <el-dialog
            :model="model"
            @open="openCallback"
            v-loading="loading"
            width="700px">
        <template
            #title>
           <span>{{title}}</span>
            <el-radio-group @change="userHierarchyLevelChange" v-model="selectedHierarchyLevel" :style="{marginLeft:'40px'}" size="small">
                <template v-for="(row) in userHierarchyLevelList" :key="row.id">
                    <el-radio-button :label="row.level">{{row.title}}</el-radio-button>
                </template>
            </el-radio-group>
        </template>
        <div :style="{textAlign:'center',paddingBottom:'30px', borderBottom:'1px solid #EBEEF5'}">
            <el-date-picker
                    :clearable="false"
                    :editable="false"
                    v-model="dateRange"
                    @change="dateRangeChange"
                    :style="{marginRight: '20px'}"
                    value-format="YYYY-MM-DD"
                    size="small"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            <el-radio-group @change="shortcutsChange" v-model="shortcuts" size="small">
                <el-radio-button label="lastWeek">上周</el-radio-button>
                <el-radio-button label="thisWeek">本周</el-radio-button>
                <el-radio-button label="yesterday">昨天</el-radio-button>
                <el-radio-button label="all">全部</el-radio-button>
            </el-radio-group>
        </div>
        <div class="statistics-content">
            <p>
                <span>新增会员: {{teamStatisticsData.memberIncrease}}</span>
                <span>新增注册: {{teamStatisticsData.registrationIncrease}}</span>
                <span>新会员金额: {{teamStatisticsData.newMemberPurchaseAmount}}</span>
                <span>总金额: {{teamStatisticsData.totalPurchaseAmount}}</span>
            </p>
            <template v-for="(row) in teamStatisticsData.websiteIncreaseList" :key="row.websiteName">
                <span>{{row.websiteName}}({{row.price}}): {{row.count}}</span>
            </template>
        </div>
        <div v-if="!isShowUserList" :style="{textAlign:'center', paddingTop:'30px'}">
            <el-button @click="showUserList" size="small" type="primary">显示用户列表</el-button>
        </div>
        <template v-else>
            <div :style="{textAlign:'center', paddingTop:'30px'}">
                <el-radio-group v-model="isMember" size="small" @change="changeHandle">
                    <el-radio-button :label="true">会员</el-radio-button>
                    <el-radio-button :label="false">体验</el-radio-button>
                </el-radio-group>
            </div>
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="用户名">
                    <a :href="'/salesman/user_list/1/' + scope.row.username" target="_blank">{{scope.row.username}}</a>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="手机号码">
                    <MobileNumberSpan :mobile-number="scope.row.mobileNumber"></MobileNumberSpan>
                </el-table-column>
                <el-table-column
                        width="80"
                        label="网站数量"
                        prop="websiteCount">
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :total="rowCount"
                    :page-size="pageSize"
                    :current-page="currentPage"
            />
        </template>
    </el-dialog>
</template>

<script>
    import MobileNumberSpan from "./MobileNumberSpan";
    export default {
        name: "TeamMemberDialog",
        components: {MobileNumberSpan},
        props: {
            model: Boolean,
            userId: Number,
            title: String
        },
        data() {
            return {
                loading: false,
                teamStatisticsData: {
                    memberIncrease: "-",
                    registrationIncrease: "-",
                    newMemberPurchaseAmount: "-",
                    totalPurchaseAmount: "-",
                    websiteIncreaseList: []
                },
                userHierarchyLevelList: [],
                selectedHierarchyLevel: 0,
                tableData: [],
                isShowUserList: false,
                isMember: true,
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                dateRange: [],
                shortcuts: "lastWeek"
            }
        },
        methods: {
            loadData() {
                this.loading = true;
                this.axios.post("/salesman/user_team_statistics", {
                    "userId": this.userId,
                    "level": this.selectedHierarchyLevel,
                    "dateStart": this.dateRange[0],
                    "dateEnd": this.dateRange[1]
                }).then((response) => {
                    this.teamStatisticsData = response.data;
                    this.loading = false;
                });
                this.currentPage = 1;
                this.isShowUserList = false;
                this.isMember = true;
            },
            showUserList() {
                this.isShowUserList = true;
                this.loadUserList();
            },
            loadUserList() {
                this.loading = true;
                this.axios.post("/salesman/get_team_member", {
                    "userId": this.userId,
                    "level": this.selectedHierarchyLevel,
                    "dateStart": this.dateRange[0],
                    "dateEnd": this.dateRange[1],
                    "currentPage": this.currentPage,
                    "isMember": this.isMember
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.loading = false;
                });
            },
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.loadUserList();
            },
            openCallback() {
                this.tableData = [];
                this.loading = true;
                this.userHierarchyLevelList = [];
                this.axios.post("/salesman/get_user_hierarchy_level_list", {
                    "userId": this.userId
                }).then((response) => {
                    this.userHierarchyLevelList = response.data;
                    if(this.userHierarchyLevelList.length > 0) {
                        this.selectedHierarchyLevel = this.userHierarchyLevelList[0].level;
                    } else {
                        this.selectedHierarchyLevel = 0;
                    }
                    this.loading = false;
                    this.shortcuts = "lastWeek";
                    this.shortcutsChange("lastWeek");
                });
            },
            changeHandle() {
                this.handleCurrentChange(1);
            },
            shortcutsChange(value) {
                this.dateRange = [];
                switch(value) {
                    case "lastWeek": {
                        this.dateRange[0] = this.getSpecialDate("firstDayOfLastWeek");
                        this.dateRange[1] = this.getSpecialDate("lastDayOfLastWeek");
                        break;
                    }
                    case "thisWeek": {
                        this.dateRange[0] = this.getSpecialDate("firstDayOfWeek");
                        this.dateRange[1] = this.dateToString(new Date());
                        break;
                    }
                    case "yesterday": {
                        this.dateRange[0] = this.getSpecialDate("yesterday");
                        this.dateRange[1] = this.getSpecialDate("yesterday");
                        break;
                    }
                    default: {
                        this.dateRange[0] = "1970-01-01";
                        this.dateRange[1] = this.dateToString(new Date());
                        break;
                    }
                }
                this.loadData();
            },
            userHierarchyLevelChange(value) {
                this.selectedHierarchyLevel = value;
                this.loadData();
            },
            dateRangeChange(value) {
                if(value) {
                    this.shortcuts = "";
                    this.loadData();
                }
            }
        }
    }
</script>

<style scoped>
.statistics-content {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEEF5;
}
.statistics-content span {
    display: inline-block;
    margin: 30px;
}
</style>