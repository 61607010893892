<template>
    <ManagerFrame default-active="invite-configs">
        <el-card shadow="always" v-loading="cardLoading">
            <el-descriptions title="系统设置" :column="1" border>
                <template #extra>
                    <el-button v-if="!edit" @click="handleEdit" size="small">修改</el-button>
                    <template v-else>
                        <el-button @click="handleSave" type="success" size="small">保存</el-button>
                        <el-button @click="handleCancel" size="small">取消</el-button>
                    </template>
                </template>
                <el-descriptions-item>
                    <template #label>开放邀请</template>
                    <el-switch v-if="edit" v-model="inviteConfigs.canInvite" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="inviteConfigs.canInvite">是</span>
                        <span v-else :style="{color:'#F56C6C'}">否</span>
                    </template>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>体验用户发送邀请</template>
                    <el-switch v-if="edit" v-model="inviteConfigs.trialUserCanInvite" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="inviteConfigs.trialUserCanInvite">是</span>
                        <span v-else :style="{color:'#F56C6C'}">否</span>
                    </template>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>体验网站</template>
                    <el-select v-if="edit" v-model="inviteConfigs.websiteTypeId" placeholder="请选择网站类型">
                        <el-option label="无" value="-1"></el-option>
                        <el-option v-for="row in websiteTypeList" :key="row.id" :value="row.id" :label="row.alias + '(' +row.price + ' - ' + (row.revenueRate ? row.revenueRate : row.fixedRevenue) + ')'">
                            {{row.alias}}({{row.price}} - {{row.revenueRate ? row.revenueRate : row.fixedRevenue}})
                        </el-option>
                    </el-select>
                    <span v-else>{{trialWebsite}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>新用户送金额</template>
                    <el-input v-if="edit" v-model="inviteConfigs.newUserAward" size="mini"></el-input>
                    <span v-else>{{inviteConfigs.newUserAward}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>同时奖励(拉新和销售)</template>
                    <el-switch v-if="edit" v-model="inviteConfigs.dualAward" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="inviteConfigs.dualAward">是</span>
                        <span v-else :style="{color:'#F56C6C'}">否</span>
                    </template>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>邀请活动说明</template>
                    <el-input type="textarea" v-if="edit" v-model="inviteConfigs.promotionDetails" size="mini"></el-input>
                    <span v-else>{{inviteConfigs.promotionDetails}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>复制信息</template>
                    <el-input type="textarea" v-if="edit" v-model="inviteConfigs.copyMessage" size="mini"></el-input>
                    <span v-else>{{inviteConfigs.copyMessage}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>客服链接</template>
                    <el-input type="textarea" v-if="edit" v-model="inviteConfigs.customerService" size="mini"></el-input>
                    <span v-else>{{inviteConfigs.customerService}}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template #label>客服工作时间</template>
                    <el-input v-if="edit" v-model="inviteConfigs.customerServiceWorkHours" size="mini"></el-input>
                    <span v-else>{{inviteConfigs.customerServiceWorkHours}}</span>
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";

    export default {
        name: "InviteConfigs",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/invite_configs").then((response) => {
                this.cardLoading = false;
                this.inviteConfigs = response.data;
                if(response.data.websiteTypeId != null) {
                    this.axios.post("/manager/get_website_type", {
                        "id": response.data.websiteTypeId
                    }).then((response) => {
                        this.trialWebsite = response.data.alias + "(" + response.data.price + " - " + (response.data.revenueRate ? response.data.revenueRate : response.data.fixedRevenue) + ")";
                    });
                }
            });
        },
        data() {
            return {
                inviteConfigs: {},
                oldInviteConfigs: {},
                cardLoading: true,
                edit: false,
                trialWebsite: "",
                websiteTypeList: []
            }
        },
        methods: {
            handleEdit() {
                this.axios.post("/manager/get_trial_website_type_list").then((response) => {
                    this.websiteTypeList = response.data;
                });
                this.oldInviteConfigs = null;
                this.oldInviteConfigs = JSON.parse(JSON.stringify(this.inviteConfigs));
                this.edit = true
            },
            handleCancel() {
                this.inviteConfigs = this.oldInviteConfigs;
                this.edit = false;
            },
            handleSave() {
                this.cardLoading = true;
                this.axios.post("/manager/save_invite_configs", this.inviteConfigs).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        this.edit = false;
                        if(this.inviteConfigs.websiteTypeId === "-1") {
                            this.trialWebsite = "";
                        } else {
                            this.trialWebsite = response.data.data.alias + "(" + response.data.data.price + " - " + (response.data.data.revenueRate ? response.data.data.revenueRate : response.data.data.fixedRevenue) + ")";
                        }
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>