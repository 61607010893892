export default {
    install: (app) => {
        app.config.globalProperties.dateTimeToString = (date) => {
            if(date == null) {
                return null;
            }
            let yyyy = date.getFullYear();
            let MM = (date.getMonth() + 1).toString().padStart(2, '0');
            let dd = date.getDate().toString().padStart(2, '0');
            let hh = date.getHours().toString().padStart(2, '0');
            let mm = date.getMinutes().toString().padStart(2, '0');
            let ss = date.getSeconds().toString().padStart(2, '0');
            return yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss;
        }
        app.config.globalProperties.dateToString = (date) => {
            if(date == null) {
                return null;
            }
            let yyyy = date.getFullYear();
            let MM = (date.getMonth() + 1).toString().padStart(2, '0');
            let dd = date.getDate().toString().padStart(2, '0');
            return yyyy + '-' + MM + '-' + dd;
        }
        app.config.globalProperties.formatDate = (row, column, cellValue) => {
            if(cellValue == null) {
                return null;
            }
            let date = new Date(cellValue);
            return app.config.globalProperties.dateTimeToString(date);
        };
        app.config.globalProperties.formatExpireTime = (row, column, cellValue) => {
            if(cellValue == null) {
                return null;
            }
            let expireDate = new Date(cellValue);
            let now = new Date();
            let expireTime = expireDate.getTime() - now.getTime();
            if(expireTime <= 0) {
                return "已过期";
            }
            if(expireTime < 24 * 3600 * 1000) {
                return (expireTime / (3600 * 1000)).toFixed(0) + "小时";
            } else {
                return (expireTime / (24 * 3600 * 1000)).toFixed(0) + "天";
            }
        };
        app.config.globalProperties.imgToBase64 = (imgInputId, callback) => {
            let img = document.getElementById(imgInputId);
            let imgFile = new FileReader();
            imgFile.readAsDataURL(img.files[0]);
            imgFile.onload = function() {
                callback(this.result);
            }
        };
        app.config.globalProperties.base64ToFile = (base64Data) => {
            if(!base64Data) {
                return "";
            }
            let arr = base64Data.split(','),
                fileType = arr[0].match(/:(.*?);/)[1],
                bytes = window.atob(arr[1]),
                ab = new ArrayBuffer(bytes.length),
                ia = new Uint8Array(ab);
            for(let i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i);
            }
            let blob = new Blob([ab], {
                type: fileType
            });

            let now = new Date();
            blob.lastModifiedDate = now;
            blob.name = now.getMilliseconds();
            return blob;
        }
        app.config.globalProperties.getSpecialDate = (name) => {
            let date = new Date();
            let dayOffset = 0;
            switch(name) {
                case "yesterday":
                    dayOffset = -1;
                    break;
                case "firstDayOfWeek": {
                    let week = date.getDay();
                    if (week === 0) {
                        dayOffset = -6;
                    } else {
                        dayOffset = -(week - 1);
                    }
                    break;
                }
                case "firstDayOfMonth": {
                    let dayOfMonth = date.getDate();
                    dayOffset = -(dayOfMonth - 1);
                    break;
                }
                case "firstDayOfLastWeek": {
                    let week = date.getDay();
                    if (week === 0) {
                        dayOffset = - 6 - 7;
                    } else {
                        dayOffset = -(week - 1) - 7;
                    }
                    break;
                }
                case "lastDayOfLastWeek": {
                    let week = date.getDay();
                    if (week === 0) {
                        dayOffset = - 6 - 1;
                    } else {
                        dayOffset = -(week - 1) - 1;
                    }
                    break;
                }
            }
            let timeOffset = dayOffset * 24 * 3600 * 1000;
            date.setTime(date.getTime() + timeOffset);

            let yyyy = date.getFullYear();
            let MM = (date.getMonth() + 1).toString().padStart(2, '0');
            let dd = date.getDate().toString().padStart(2, '0');
            return yyyy + '-' + MM + '-' + dd;
        }
        app.config.globalProperties.copyText = (text) => {
            let input = document.createElement("textarea");
            input.style.opacity = "0";
            input.style.position = "absolute";
            input.value = text;
            document.body.appendChild(input);
            input.select();
            input.setSelectionRange(0, text.length);
            document.execCommand("copy");
            document.body.removeChild(input);
        }
    }
}