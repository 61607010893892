<template>
    <el-dialog
            @open="dialogOpen"
            :close-on-click-modal="canClose"
            :show-close="false"
            :close-on-press-escape="false"
            :model="model"
            width="400px">
        <el-form
                ref="form"
                :rules="formRules"
                label-position="left"
                label-width="80px"
                :model="formData"
                v-loading="!canClose"
                size="small">
            <el-form-item label="优惠券" prop="couponTypeId">
                <el-select v-model="formData.couponTypeId" placeholder="请选择" :style="{width:'100%'}">
                    <el-option v-for="row in couponTypeList" :key="row.id" :value="row.id" :label="row.discountAmount + '(' + row.scope + ')' + ' - ' + row.remark"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="有效期至" prop="expireTime">
                <el-date-picker v-model="formData.expireTime" type="datetime" :editable="false" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择时间" :style="{width:'100%'}"></el-date-picker>
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="!canClose" @click="cancelCallback" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="!canClose" @click="handleClickOk" size="small">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "AddUserCouponDialog",
        props: {
            model: Boolean,
            cancelCallback: Function,
            confirmCallback: Function[Object]
        },
        data() {
            return {
                canClose: true,
                formData: {},
                couponTypeList: [],
                formRules: {
                    couponTypeId: [
                        { required: true, message: "请选择优惠券", trigger: "change" }
                    ],
                    expireTime: [
                        { required: true, message: "请选择有效时间", trigger: "change" }
                    ]
                }
            }
        },
        methods: {
            handleClickOk() {
                this.$refs["form"].validate((value) => {
                    if(value) {
                        this.canClose = false;
                        this.confirmCallback(this.formData);
                    } else {
                        return false;
                    }
                });
            },
            dialogOpen() {
                this.canClose = true;
                this.addFormData = {};
                this.axios.post("/salesman/coupon_type_list").then((response) => {
                    this.cardLoading = false;
                    this.couponTypeList = response.data;
                });
            }
        }
    }
</script>

<style scoped>

</style>