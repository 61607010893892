<template>
    <el-skeleton v-if="status === 0" animated />
    <div v-else-if="status === 1" class="main-content">
        <el-row>
            <el-col :md="12">
                <el-form
                        ref="registerForm"
                        :model="formData"
                        :rules="rules"
                        v-loading="loading"
                >
                    <el-row>
                        <el-col :span="12">
                            <div :style="{marginBottom:'10px'}"><router-link to="/"><img :style="{width:'100px'}" src="../assets/aad_logo_pink.png"/></router-link></div>
                            <div><img :style="{width:'100px'}" src="../assets/ga.png" /></div>
                        </el-col>
                        <el-col :span="12" :style="{textAlign:'right'}">
                            <el-badge :value="1" type="danger"><el-button class="customer-service-btn" @click="getCustomerService" size="small" icon="fab fa-whatsapp"> CHAT WITH US</el-button></el-badge>
                            <div><a :href="androidUrl" download="ops-release.apk"><el-button class="download-btn" size="small">Download OPS</el-button></a></div>
                            <div><a href="/app_web_redirect"><el-button type="primary" size="small" :style="{width:'120px', marginTop: '10px'}" icon="fab fa-brands fa-chrome"> For Web</el-button></a></div>
                            <div v-if="false"><a href="/download/ops-release.apk" download="ops-release.apk"><el-button class="download-btn" size="small">Download OPS</el-button></a></div>
                        </el-col>
                    </el-row>
                    <h2 class="register-title">Create Your OPS Account</h2>
                    <el-form-item prop="email">
                        <el-input
                                type="text"
                                placeholder="E-mail"
                                v-model.trim="formData.email"
                                @keyup.enter="onSubmit('registerForm')"
                                clearable
                        />
                    </el-form-item>
                    <el-form-item prop="mobileNumber">
                        <el-input
                                type="text"
                                maxlength="12"
                                minlength="8"
                                placeholder="Mobile Number"
                                v-model.trim="formData.mobileNumber"
                                @keyup.enter="onSubmit('registerForm')"
                                clearable
                        >
                            <template #prepend>+91</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                                :type=inputType
                                placeholder="Password"
                                v-model.trim="formData.password"
                                @keyup.enter="onSubmit('registerForm')"
                                clearable
                        />
                    </el-form-item>
                    <el-form-item prop="confirm">
                        <el-input
                                :type=inputType
                                placeholder="Confirm Password"
                                v-model.trim="formData.confirm"
                                @keyup.enter="onSubmit('registerForm')"
                                clearable
                        />
                    </el-form-item>
                    <el-checkbox v-model="inputType" true-label="text" false-label="password" label="Show Password"></el-checkbox>
                    <el-form-item>
                        <el-button class="pink-btn" :style="{width:'100%', marginTop:'50px', marginBottom:'50px'}" v-on:click="onSubmit('registerForm')">Sign up</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :md="12" class="step-content">
                <el-steps direction="vertical">
                    <el-step status="process">
                        <template #title><p>Sign up to OPS with your email.</p></template>
                    </el-step>
                    <el-step status="process">
                        <template #title><p>Download OPS application.</p></template>
                    </el-step>
                    <el-step status="process">
                        <template #title><p>Sign in to OPS application and get a website for free.</p></template>
                    </el-step>
                    <el-step status="process">
                        <template #title><p>Make money every day by showing Google ads on OPS application.</p></template>
                    </el-step>
                </el-steps>
            </el-col>
        </el-row>
<!--        <div :style="{textAlign: 'center'}">-->
<!--            <h2 :style="{marginTop: '50px', marginBottom: '20px'}">How to use OPS?</h2>-->
<!--            <p>Video ini menunjukkan cara menggunakan OPS di ponsel Anda.</p>-->
<!--            <video src="../assets/1646409728577.mp4"-->
<!--                   loop="loop"-->
<!--                   muted="muted"-->
<!--                   autoplay="autoplay" controls="controls"></video>-->
<!--        </div>-->
    </div>
    <el-result
            v-else-if="status === 2"
            icon="error"
            title="Error Tip"
            :sub-title=errorSubTitle
    >
        <template #extra>
            <router-link to="/index"><el-button type="primary" size="medium">Homepage</el-button></router-link>
        </template>
    </el-result>
</template>

<script>
    export default {
        name: "UserRegister",
        mounted() {
            this.authCode = this.$route.params.authCode;
            this.validateRegister();

            //获取下载链接
            this.axios.post("/user/get_app_download_url").then((response) => {
                this.androidUrl = response.data["androidUrl"];
            });
        },
        data() {
            return {
                status: 0,
                loading: false,
                authCode: "",
                androidUrl: "",
                formData: {
                    email: "",
                    mobileNumber: "",
                    password: "",
                    confirm: ""
                },
                inputType: "password",
                showPassword: false,
                errorSubTitle: "",
                rules: {
                    email: [
                        {
                            required: true,
                            type: "email",
                            message: "Choose an email address",
                            trigger: "change"
                        },
                        {
                            min: 6,
                            max: 99,
                            message: "Use 6 characters or more as email",
                            trigger: "change",
                        },
                    ],
                    mobileNumber: [
                        {
                            required: true,
                            message: "Mobile number required",
                            trigger: "change"
                        },
                        {
                            pattern: /^[0-9]{8,12}$/,
                            message: "Wrong phone number",
                            trigger: "change"
                        },
                    ],
                    password: [
                        {
                            required: true,
                            type: "string",
                            message: "Use 6 characters or more as password",
                            trigger: "change"
                        },
                        {
                            min: 6,
                            max: 20,
                            message: "Use 6 characters or more as password",
                            trigger: "change",
                        },
                        {
                            pattern: /^[^ \f\n\r\t\v]+$/,
                            message: "Password cannot contain special characters",
                            trigger: "change"
                        },
                    ],
                    confirm: [
                        {
                            type: "string",
                            required: true,
                            trigger: "change",
                            validator: (rule, value) => {
                                return new Promise((resolve, reject) => {
                                    if(value !== this.formData.password) {
                                        reject("The password is different, please try again.");
                                    } else {
                                        resolve();
                                    }
                                });
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            validateRegister() {
                this.axios.post("/user/register_page", {
                    "authCode": this.authCode
                }).then((response) => {
                    if(response.data.result) {
                        this.status = 1;
                    } else {
                        this.status = 2;
                        this.errorSubTitle = response.data.message;
                    }
                });
            },
            onSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if(valid) {
                        this.loading = true;
                        this.axios.post("/user/register", {
                            "email": this.formData.email.trim(),
                            "mobileNumber": this.formData.mobileNumber.trim(),
                            "password": this.formData.password.trim(),
                            "authCode": this.authCode
                        }).then((response) => {
                            this.loading = false;
                            if(response.data.result) {
                                document.location.href = "/register_success";
                            } else {
                                this.$message.error(response.data.message);
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            getCustomerService() {
                this.loading = true;
                let customerService = localStorage.getItem("customerService");
                this.axios.post("/user/get_customer_service", {
                    "customerService": customerService
                }).then((response) => {
                    this.loading = false;
                    if(response.data.result) {
                        customerService = response.data.data;
                        localStorage.setItem("customerService", customerService);
                        document.location.href = customerService;
                    } else {
                        this.$message.error(response.data.message);
                    }
                });
            }
        }
    }
</script>
<style>
.customer-service-btn {
    background-color: rgb(68,146,141);
    color: #fff;
    border-color: rgb(68,146,141);
}
.pink-btn {
    background-color: #de1783;
    border-color: #de1783;
    color: #fff;
}
.pink-btn:hover {
    background-color: #eb5893;
    border-color: #eb5893;
    color: #fff;
}
</style>

<style scoped>
p {
    margin-bottom: 20px;
}
.main-content {
    padding: 48px 40px 48px 40px;
}
.register-title {
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 600;
    color: #2f2861;
}
.download-btn {
    width: 120px;
    margin-top: 10px;
    background-color: rgb(45,42,94);
    color: #fff;
    border-color: rgb(45,42,94);
}
.step {
    padding-bottom: 10px;
}
.step-content p {
    font-weight: 400 !important;
}
.send-btn-text {
    display: inline-block;
    text-align: center;
    width: 34px;
}
video {
    width: 100%;
}
@media only screen and (min-width: 880px) {
    .main-content {
        width: 800px;
        margin: 80px auto 50px auto;
        border: 1px solid #dadce0;
        border-radius: 8px;
    }
    .step-content {
        padding-left: 50px;
    }
    .download-btn {
        margin-bottom: 0;
    }
    video {
        width: 50%;
    }
}
</style>