<template>
    <ManagerFrame default-active="payment-website-ram">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        width="100"
                        label="头像">
                    <el-image
                            :style="{width:'60px'}"
                            :hide-on-click-modal="true"
                            :src="scope.row.img"
                            :preview-src-list="[scope.row.img]">
                        <template #error>
                            <span :style="{color:'#909399', cursor:'pointer'}">无</span>
                        </template>
                    </el-image>
                    <input @change="imgInputChange($event, scope.$index, 'img')" type="file" v-show="scope.row.edit" :style="{width:'130px'}" accept="image/jpeg, image/png, image/jpg" />
                </el-table-column>
                <el-table-column
                        #default="scope"
                        width="100"
                        label="销售图片">
                    <el-image
                            :style="{width:'60px'}"
                            :hide-on-click-modal="true"
                            :src="scope.row.saleImg"
                            :preview-src-list="[scope.row.saleImg]">
                        <template #error>
                            <span :style="{color:'#909399', cursor:'pointer'}">无</span>
                        </template>
                    </el-image>
                    <input @change="imgInputChange($event, scope.$index, 'saleImg')" type="file" v-show="scope.row.edit" :style="{width:'130px'}" accept="image/jpeg, image/png, image/jpg" />
                </el-table-column>
                <el-table-column
                        #default="scope"
                        width="150"
                        label="海报">
                    <el-image
                            :style="{width:'60px'}"
                            :hide-on-click-modal="true"
                            :src="scope.row.poster"
                            :preview-src-list="[scope.row.poster]">
                        <template #error>
                            <span :style="{color:'#909399', cursor:'pointer'}">无</span>
                        </template>
                    </el-image>
                    <input @change="imgInputChange($event, scope.$index, 'poster')" type="file" v-show="scope.row.edit" :style="{width:'130px'}" accept="image/jpeg, image/png, image/jpg" />
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="名称">
                    <el-input v-if="scope.row.edit" v-model="scope.row.name" size="mini"></el-input>
                    <span v-else>{{scope.row.name}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="网站类型">
                    <span>{{scope.row.websiteTypeAlias}}({{scope.row.websiteTypePrice}} - {{scope.row.websiteTypeRevenueRate}})</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="价格">
                    <el-input v-if="scope.row.edit" v-model="scope.row.price" size="mini"></el-input>
                    <span v-else>{{scope.row.price}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="真实收益率">
                    <el-input v-if="scope.row.edit" v-model="scope.row.revenueRate" size="mini"></el-input>
                    <span v-else>{{scope.row.revenueRate}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="显示收益率">
                    <el-input v-if="scope.row.edit" v-model="scope.row.revenueRatePerDay" size="mini"></el-input>
                    <span v-else>{{scope.row.revenueRatePerDay}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="状态">
                    <el-switch v-if="scope.row.edit" v-model="scope.row.active" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="scope.row.active" :style="{color:'#67C23A'}">正常</span>
                        <span v-else :style="{color:'#F56C6C'}">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="上架">
                    <el-switch v-if="scope.row.edit" v-model="scope.row.onSale" active-color="#67C23A" inactive-color="#F56C6C"></el-switch>
                    <template v-else>
                        <span v-if="scope.row.onSale" :style="{color:'#67C23A'}">正常</span>
                        <span v-else :style="{color:'#F56C6C'}">下架</span>
                    </template>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="数量限制">
                    <el-input v-if="scope.row.edit" v-model="scope.row.numLimit" size="mini"></el-input>
                    <span v-else>{{scope.row.numLimit}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="库存">
                    <el-input v-if="scope.row.edit" v-model="scope.row.stock" size="mini"></el-input>
                    <span v-else>{{scope.row.stock}}</span>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="right">
                    <el-button v-if="!scope.row.edit"
                               size="mini"
                               @click="handleEdit(scope.row)">修改</el-button>
                    <el-button v-else size="mini" type="success" @click="handleSave(scope.row)">保存</el-button>
                    <el-button v-if="scope.row.edit"
                               size="mini"
                               @click="handleCancel(scope.row)">取消</el-button>
                </el-table-column>
            </el-table>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "WebsiteRamSettings",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/website_ram_list").then((response) => {
                this.tableData = response.data;
                this.cardLoading = false;
            });
        },
        data() {
            return {
                cardLoading: true,
                tableData: []
            }
        },
        methods: {
            handleEdit(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true;
            },
            handleCancel(data) {
                data.img = data.oldData.img;
                data.name = data.oldData.name;
                data.price = data.oldData.price;
                data.revenueRate = data.oldData.revenueRate;
                data.revenueRatePerDay = data.oldData.revenueRatePerDay;
                data.active = data.oldData.active;
                data.onSale = data.oldData.onSale;
                data.numLimit = data.oldData.numLimit;
                data.poster = data.oldData.poster;
                data.saleImg = data.oldData.saleImg;
                data.stock = data.oldData.stock;
                data.edit = false;
            },
            imgInputChange(e, index, type) {
                let $this = this;
                let input = e.target;
                let file = input.files[0];
                if(file.size / 1024 /1024 > 1) {
                    this.$message.error({
                        message: "图片大小不能大于1M",
                        type: "error"
                    });
                    input.value = "";
                    return;
                }
                let imgFile = new FileReader();
                imgFile.readAsDataURL(file);
                imgFile.onload = function() {
                    $this.tableData[index][type] = this.result;
                }
            },
            handleSave(data) {
                if(!data.img || !data.poster || !data.saleImg) {
                    this.$message.error({
                        message: "修改失败,请上传图片!",
                        type: "error"
                    });
                    return;
                }
                if((data.name == null) || (data.name.toString().trim() === "")) {
                    this.$message.error({
                        message: "修改失败,名称不能留空!",
                        type: "error"
                    });
                    return;
                }
                if(isNaN(data.price) || isNaN(data.revenueRate) || isNaN(data.revenueRatePerDay) || isNaN(data.numLimit)) {
                    this.$message.error({
                        message: "修改失败,只能输入数字!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(data.price) <= 0) || (parseFloat(data.price) > 99999999.99)) {
                    this.$message.error({
                        message: "修改失败,价格必须大于零!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(data.revenueRate) < 0.0001) || (parseFloat(data.revenueRate) > 99.9999)) {
                    this.$message.error({
                        message: "修改失败,收益必须大于零!",
                        type: "error"
                    });
                    return;
                }
                if((parseFloat(data.revenueRatePerDay) < 0.0001) || (parseFloat(data.revenueRatePerDay) > 99.9999)) {
                    this.$message.error({
                        message: "修改失败,每天收益率必须大于零!",
                        type: "error"
                    });
                    return;
                }
                if(!(/^[1-9][0-9]*$/).test(data.numLimit)) {
                    this.$message.error({
                        message: "修改失败,数量限制必须为正整数!",
                        type: "error"
                    });
                    return;
                }
                if((parseInt(data.numLimit) < 1) || (parseInt(data.numLimit) > 99999999)) {
                    this.$message.error({
                        message: "修改失败,数量限制必须大于零!",
                        type: "error"
                    });
                    return;
                }
                if((parseInt(data.stock) < 0) || (parseInt(data.stock) > 99999)) {
                    this.$message.error({
                        message: "修改失败,请输入正确的库存!",
                        type: "error"
                    });
                    return;
                }
                this.cardLoading = true;
                this.axios.post("/manager/update_website_ram", data, {"type": "text"}).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        data.edit = false;
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>