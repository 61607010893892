<template>
    <div id="content">
        <h1>Privacy Policy</h1>
        <p>Welcome to visit our products. OPS (including services provided by products such as websites, hereinafter referred to as "products and services") is developed and operated by ADOPSANDADTECH LIMITED (hereinafter referred to as "we"). Ensuring user data security and privacy protection is our top priority. This Privacy Policy sets out the data collected and how it is processed when you access and use our products and services.</p>
        <p>Please read carefully and confirm that you fully understand all the rules and points of this privacy policy before continuing to use our products. Once you choose to use it, you are deemed to agree to the entire content of this privacy policy and agree to our collection and use of your information. related information. If you have any questions about this policy during the reading process, you can contact our customer service for consultation. Please contact us through manager@adopsandadtech.com.my or the feedback method in the product. If you do not agree to the relevant agreement or any of its terms, you should stop using our products and services.</p>
        <p>This Privacy Policy helps you understand the following:</p>
        <ul>
            <li>How we collect and use your personal information;</li>
            <li>How we store and protect your personal information;</li>
            <li>How we share, transfer and publicly disclose your personal information;</li>
            <li>How we use cookies and other tracking technologies;</li>
        </ul>
        <h2>How we collect and use your personal information.</h2>
        <p>Personal information refers to various information recorded electronically or in other ways that can identify a specific natural person or reflect the activities of a specific natural person, either alone or in combination with other information. We will strictly follow the principles of legitimacy, legality, and necessity, and collect and use your personal information, including but not limited to email addresses, for your use of the services and/or products we provide.</p>
        <p>In order to receive our comprehensive product services, you should first register a user account, through which we will record relevant data. All information provided by you is derived from the data provided by yourself during registration. The account name, password, and your own contact information you are going to use, we may verify whether your identity is valid by sending text messages or emails.</p>
        <h2>How we store and protect your personal information.</h2>
        <p>As a general rule, we only retain your personal information for as long as necessary to fulfill the purposes for which it was collected. We retain your personal information for as long as is strictly necessary to manage our relationship with you (for example, when you open an account, obtain services from our products). We may need to retain your personal information on file beyond the expiration of the above periods to comply with a legal obligation or to demonstrate that a right or contract satisfies applicable statute of limitations requirements and cannot be deleted at your request. We ensure that your personal data is completely deleted or anonymized when it is no longer necessary for the purposes or archives corresponding to our statutory obligations or statutory statute of limitations.</p>
        <p>We use industry-standard security measures to protect the personal information you provide, and encrypt key data in it to prevent unauthorized access, public disclosure, use, modification, damage or loss. We will take all reasonable and feasible measures to protect your personal information. We use encryption technology to ensure the confidentiality of data. We use trusted protection mechanisms to protect data from malicious attacks.</p>
        <h2>How we share, transfer and publicly disclose your personal information.</h2>
        <p>When it is necessary to manage our daily business activities, we will use your personal information in a compliant and appropriate manner in order to pursue legitimate interests and better serve customers. For comprehensive consideration of business and various aspects, we only use these data for ourselves and do not share them with any third parties.</p>
        <p>We may share your personal information externally in accordance with laws and regulations, or in accordance with the mandatory requirements of government authorities. Under the premise of complying with laws and regulations, when we receive the above-mentioned request for information disclosure, we will require to issue corresponding legal documents, such as subpoenas or investigation letters. We firmly believe that we should be as transparent as the law allows about the information we are asked to provide.</p>
        <p>In the following situations, sharing, transferring, and publicly disclosing your personal information does not require your prior authorization and consent:</p>
        <ul>
            <li>Directly related to national security and national defense security;</li>
            <li>Directly related to criminal investigation, prosecution, trial and execution of judgments;</li>
            <li>For the protection of your or other personal life, property and other major legal rights, but it is difficult to obtain the consent of the individual;</li>
            <li>Personal information that you disclose to the public by yourself;</li>
            <li>Personal information is collected from legally disclosed information, such as legal news reports, government information disclosure and other channels;</li>
            <li>It is necessary to sign and perform a contract according to the requirements of the personal information subject;</li>
            <li>Necessary for maintaining the safe and stable operation of the products or services provided, such as discovering and handling product or service failures;</li>
            <li>Other circumstances stipulated by laws and regulations.</li>
        </ul>
        <h2>How we use cookies and other tracking technologies.</h2>
        <p>In order to ensure the normal operation of the product, we will store a small data file called a cookie on your computer or mobile device.Cookies usually contain identifiers, product names and some numbers and characters. With the help of cookies, we can store data such as your preferences or products, and use them to determine whether registered users have logged in, improve service and product quality, and optimize user experience.</p>
        <p>We use various cookies for different purposes, including: strictly necessary cookies, performance cookies, marketing cookies and functional cookies. Certain cookies may be served by external third parties to provide additional functionality to our products.We will not use cookies for any purpose other than those stated in this policy. You can manage or delete cookies according to your own preferences. You can clear all cookies saved on your computer or mobile phone, and most web browsers have the function of blocking or disabling cookies, and you can configure the browser. After blocking or disabling the cookie function, your use of our products and services may be affected or cannot be fully used.</p>
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicy"
    }
</script>

<style scoped>
    @media only screen and (min-width: 992px) {
        #content {
            max-width: 1160px;
            padding-top: 44px;
            padding-bottom: 44px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @media only screen and (max-width: 992px) {
        #content {
            padding: 44px 36px;
        }
    }
    h1 {
        margin-bottom: 32px;
    }
    p {
        text-indent: 24px;
        font-weight: 300;
        margin-bottom: 16px;
        line-height: 24px;
    }
    ul {
        margin-left: 24px;
        margin-bottom: 16px;
    }
    li {
        font-weight: 300;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 16px;
        font-weight: 400;
    }
</style>