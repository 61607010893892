<template>
    <ManagerFrame default-active="ad-link">
        <el-card class="main" shadow="always" v-loading="cardLoading">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-table
                            class="ad-word-table"
                            :data="adWordTableData"
                            highlight-current-row
                            @current-change="handleAdWordChange"
                            style="width: 100%">
                        <el-table-column
                                property="word"
                                label="广告词">
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col v-if="selectedAdWord !== '' " :span="18">
                    <el-table
                            stripe="true"
                            :data="adLinkTableData">
                        <el-table-column
                                property="url"
                                show-overflow-tooltip="true"
                                label="链接">
                        </el-table-column>
                        <el-table-column
                                width="80px"
                                align="right">
                            <template #header>
                                <el-button @click="handleAdd" size="small" type="primary">添加</el-button>
                            </template>
                            <template #default="scope">
                                <el-button
                                        size="mini"
                                        type="danger"
                                        @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            v-if="adLinkTableData.length > 0"
                            background
                            @current-change="handleCurrentChange"
                            layout="total, prev, pager, next, jumper"
                            :total="rowCount"
                            :page-size="pageSize"
                            :current-page="currentPage">
                    </el-pagination>
                </el-col>
            </el-row>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "AdLink",
        components: {ManagerFrame},
        mounted() {
            let $this = this;
            this.axios.post("/manager/ad_word_list").then(function(response) {
                $this.adWordTableData = response.data;
                $this.cardLoading = false;
            });
        },
        data() {
            return {
                cardLoading: true,
                selectedAdWord: "",
                pageSize: 10,
                rowCount: 0,
                currentPage: 1,
                adWordTableData: [],
                adLinkTableData: []
            }
        },
        methods: {
            handleAdWordChange(currentRow) {
                this.selectedAdWord = currentRow;
                this.loadAdUrl(1);
            },
            handleCurrentChange(currentPage) {
                this.loadAdUrl(currentPage);
            },
            loadAdUrl(currentPage) {
                this.cardLoading = true;
                let $this = this;
                this.axios.post("/manager/get_ad_link", {
                    "id": this.selectedAdWord.id,
                    "currentPage": currentPage
                }).then(function(response) {
                    $this.cardLoading = false;
                    $this.adLinkTableData = response.data.dataList;
                    $this.rowCount = response.data.rowCount;
                    $this.pageSize = response.data.pageSize;
                    $this.currentPage = response.data.currentPage;
                });
            },
            handleAdd() {
                this.$prompt('请输入链接', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/,
                    inputErrorMessage: '链接格式不正确'
                }).then(({ value }) => {
                    this.cardLoading = true;
                    let $this = this;
                    this.axios.post("/manager/add_ad_link",{
                        "adWordId": this.selectedAdWord.id,
                        "url": value
                    }).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.$message({
                                type: "success",
                                message: "添加成功!"
                            });
                            if($this.adLinkTableData.length === $this.pageSize) {
                                $this.adLinkTableData.pop();
                            }
                            $this.adLinkTableData.unshift(response.data.data);
                            $this.rowCount++;
                        } else {
                            $this.$message.error({
                                message: '添加失败,' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                })
            },
            handleDelete(index, data) {
                let $this = this;
                this.$confirm('确定删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/delete_ad_link", data).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.adLinkTableData.splice(index, 1);
                            $this.$message.success({
                                message: '删除成功',
                                type: 'success'
                            });
                            $this.rowCount--;
                        } else {
                            $this.$message.error({
                                message: '删除失败! ' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>
.ad-word-table:hover {
    cursor: pointer;
}
</style>