<template>
    <el-skeleton v-if="status === 0" animated />
    <el-result
            v-else-if="status === 1"
            icon="error"
            title="Error Tips"
            :sub-title=errorSubTitle
    >
        <template #extra>
            <router-link to="/"><el-button type="primary" size="medium">Home</el-button></router-link>
        </template>
    </el-result>
</template>

<script>
    export default {
        name: "AppWebRedirect",
        mounted() {
            console.log(navigator.userAgent);
            if((navigator.userAgent.indexOf("iPhone") > -1) || (navigator.userAgent.indexOf("Android") > -1)) {
                if((navigator.userAgent.indexOf("Safari") > -1) || (navigator.userAgent.indexOf("Chrome") > -1)) {
                    document.location.href = "https://app.adopsandadtech.in";
                } else {
                    this.status = 1;
                    this.errorSubTitle = "Please use Google Chrome to run on your mobile phone.";
                }
            } else {
                this.status = 1;
                this.errorSubTitle = "Please use Google Chrome to run on your mobile phone.";
            }
        },
        data() {
            return {
                status: 0,
                errorSubTitle: ""
            }
        }
    }
</script>

<style scoped>

</style>