<template>
    <ManagerFrame default-active="coupon-settings">
        <el-card v-loading="cardLoading">
            <div :style="{textAlign: 'right'}">
                <el-button @click="addDialogVisible = true" size="small" type="success">添加</el-button>
            </div>
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        align="center"
                        label="图片">
                    <el-upload
                            :auto-upload="true"
                            :show-file-list="false"
                            :http-request="onUpLoad"
                            :before-upload="beforeUpload"
                            class="avatar-uploader"
                            :data="{'id': scope.row.id, 'index': scope.$index}"
                    >
                        <div v-if="scope.row.posterBase64" class="avatar">
                            <img :src="scope.row.posterBase64" />
                        </div>
                        <div v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></div>
                    </el-upload>
                </el-table-column>
                <el-table-column
                        prop="scope"
                        align="center"
                        label="适用范围">
                </el-table-column>
                <el-table-column
                        prop="discountAmount"
                        align="center"
                        label="折扣">
                </el-table-column>
                <el-table-column
                        prop="description"
                        align="center"
                        label="描述">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        align="center"
                        label="备注">
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                :close-on-click-modal="!addRequesting"
                :close-on-press-escape="false"
                :show-close="!addRequesting"
                v-model="addDialogVisible"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="addRules"
                    :label-position="left"
                    label-width="110px"
                    :model="addFormData"
                    size="small"
                    v-loading="addRequesting">
                <el-form-item label="适用范围" prop="scope">
                    <el-select v-model="addFormData.scope" @change="scopeChanged" placeholder="请选择适用范围" :style="{width:'100%'}">
                        <el-option :key="0" :value="0" :label="'通用'"></el-option>
                        <el-option :key="1" :value="1" :label="'网站通用'"></el-option>
                        <el-option :key="2" :value="2" :label="'升级通用'"></el-option>
                        <el-option :key="3" :value="3" :label="'指定网站'"></el-option>
                        <el-option :key="4" :value="4" :label="'指定升级'"></el-option>
                        <el-option :key="5" :value="5" :label="'续费通用'"></el-option>
                        <el-option :key="6" :value="6" :label="'指定续费'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="showLimitedGoods" label="指定" prop="limitedGoodsId">
                    <el-select v-model="addFormData.limitedGoodsId" placeholder="请选择" :style="{width:'100%'}">
                        <el-option v-for="row in goodsList" :key="row.id" :value="row.id" :label="row.title"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="折扣类型" prop="discountType">
                    <el-select v-model="addFormData.discountType" placeholder="请选择折扣类型" :style="{width:'100%'}">
                        <el-option :key="0" :value="0" :label="'数值'"></el-option>
                        <el-option :key="1" :value="1" :label="'百分比'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="折扣" prop="discountAmount">
                    <el-input
                            @keyup.enter="addCoupon"
                            v-model="addFormData.discountAmount"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input
                            @keyup.enter="addCoupon"
                            v-model="addFormData.description"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                            @keyup.enter="addCoupon"
                            v-model="addFormData.remark"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addRequesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addRequesting" @click="addCoupon" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "CouponSettings",
        components: {ManagerFrame},
        data() {
            let limitedGoodsIdCheck = (rule, value, callback) => {
                if(this.addFormData.scope === 3 || this.addFormData.scope === 4 || this.addFormData.scope === 6) {
                    if(value) {
                        callback();
                    } else {
                        callback(new Error("请选择商品"));
                    }
                } else {
                    callback();
                }
            }
            let discountAmountCheck = (rule, value, callback) => {
                let i = parseFloat(value);
                if (value === "") {
                    callback(new Error("请输入价格"));
                } else if (isNaN(value)) {
                    callback(new Error("只能输入数字"));
                } else if(i <= 0) {
                    callback(new Error("折扣必须大于 0"));
                } else {
                    if(this.addFormData.discountType === 0) {
                        if(i > 99999999.99) {
                            callback(new Error("折扣必须小于 99999999.99"));
                        } else {
                            callback();
                        }
                    } else if(this.addFormData.discountType === 1) {
                        if(i > 0.9999) {
                            callback(new Error("折扣百分比必须小于 0.9999"));
                        } else {
                            callback();
                        }
                    } else {
                        callback(new Error("请选择折扣类型"));
                    }
                }
            }
            return {
                cardLoading: true,
                tableData: [],
                addDialogVisible: false,
                addRequesting: false,
                addFormData: {},
                showLimitedGoods: false,
                goodsList: [],
                addRules: {
                    scope: [
                        { required: true, message: "请选择适用范围", trigger: "change" }
                    ],
                    limitedGoodsId: [
                        { validator: limitedGoodsIdCheck, trigger: "change" }
                    ],
                    discountType: [
                        { required: true, message: "请选择折扣类型", trigger: "change" }
                    ],
                    discountAmount: [
                        { required: true, message: "请输入折扣", trigger: "change" },
                        { validator: discountAmountCheck, trigger: "change" }
                    ],
                    description: [
                        { required: true, message: "请输入描述", trigger: "change" },
                        { min: 1, max: 255, message: "长度在 1 到 255 个字符", trigger: "change" }
                    ]
                }
            }
        },
        mounted() {
            this.axios.post("/manager/coupon_type_list").then((response) => {
                this.cardLoading = false;
                this.tableData = response.data;
            });
        },
        methods: {
            scopeChanged(value) {
                this.goodsList = [];
                this.addFormData.limitedGoodsId = null;
                if(value === 3 || value === 4 || value === 6) {
                    this.showLimitedGoods = true;
                    this.getLimitedGoodsList(value);
                } else {
                    this.showLimitedGoods = false;
                }
            },
            getLimitedGoodsList(scope) {
                this.axios.post("/manager/coupon_limited_goods_list", {
                    "scope": scope
                }).then((response) => {
                    this.goodsList = response.data;
                });
            },
            addCoupon() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        this.addRequesting = true;
                        this.axios.post("/manager/add_coupon_type", this.addFormData).then((response) => {
                            this.addRequesting = false;
                            this.addDialogVisible = false;
                            if(response.data.result) {
                                this.$message.success({
                                    message: "添加成功",
                                    type: "success"
                                });
                                this.tableData.unshift(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            beforeUpload(rawFile) {
                if(rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
                    this.$message({
                        type: "error",
                        message: "只能上传图片!"
                    });
                    return false;
                } else {
                    if(rawFile.size / 1024 / 1024 > 1) {
                        this.$message({
                            type: "error",
                            message: "图片不能超过1MB!"
                        });
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            onUpLoad(option) {
                let formData = new FormData();
                formData.append("id", option.data.id);
                formData.append("poster", option.file);
                this.axios.post("/manager/update_coupon_type_poster", formData, {"type": "file"}).then((response) => {
                    if(response.data.result) {
                        this.tableData[option.data.index].posterBase64 = response.data.data;
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: 'error'
                        });
                    }
                });
            }
        }
    }
</script>
<style>
    .avatar-uploader {
        display: inline-block;
        border: 1px dashed #DCDFE6;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        text-align: center;
    }
    .avatar-uploader .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .avatar-uploader .avatar img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    .avatar-uploader-icon {
        display: table;
    }
    .avatar-uploader-icon i {
        display: table-cell;
        vertical-align: middle;
    }
</style>
<style scoped>
    .avatar-uploader,.avatar-uploader-icon,.avatar-uploader .avatar {
        width: 100px;
        height: 100px;
    }
</style>