<template>
    <el-card shadow="always" v-loading="cardLoading" :style="{fontSize:'14px'}">
        <el-row>
            <el-col :span="12" :style="{color:'#909399'}">{{title}}</el-col>
            <el-col :span="12" :style="{color:'#409EFF',cursor:'pointer',textAlign:'right'}" @click="clickViewHandle"><i class="el-icon-view"></i></el-col>
        </el-row>
        <el-table :data="tableData"
                  stripe style="width: 100%"
                  :show-header="false"
                  empty-text=" "
        >
            <el-table-column
                    width="50"
                    #default="scope">
                <span :style="{color:'#909399'}">{{getRanking(scope.$index)}}</span>
            </el-table-column>
            <el-table-column
                    #default="scope"
                    label="用户名"
                    prop="username">
                <a :href="'/salesman/user_list/1/' + scope.row.username" target="_blank">{{scope.row.username}}</a>
            </el-table-column>
            <el-table-column
                    label="数量"
                    align="right"
                    #default="scope">
                <span class="text-green">{{scope.row.amount}}</span>
            </el-table-column>
        </el-table>
        <el-pagination
                v-if="showPager"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                small
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
    </el-card>
</template>

<script>
    export default {
        name: "RankingListTable",
        props: {
            dateRange: [],
            type: String,
            title: String
        },
        data() {
            return {
                cardLoading: false,
                tableData: [],
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                showPager: false
            }
        },
        methods: {
            loadData() {
                this.cardLoading = true;
                this.axios.post("/salesman/ranking_list", {
                    "startTime": this.dateRange[0] + " 00:00:00",
                    "endTime": this.dateRange[1] + " 23:59:50",
                    "currentPage": this.currentPage,
                    "type": this.type
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.cardLoading = false;
                    this.showPager = true;
                });
            },
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.loadData();
            },
            getRanking(index) {
                return (this.currentPage - 1) * this.pageSize + index + 1;
            },
            clickViewHandle() {
                this.currentPage = 1;
                this.loadData();
            }
        }
    }
</script>

<style scoped>

</style>