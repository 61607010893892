<template>
    <ManagerFrame default-active="coupon-distribution">
        <el-card v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        align="center"
                        label="图片">
                    <el-upload
                            :auto-upload="true"
                            :show-file-list="false"
                            :http-request="onUpLoad"
                            :before-upload="beforeUpload"
                            class="avatar-uploader"
                            :data="{'id': scope.row.id, 'index': scope.$index}"
                    >
                        <div v-if="scope.row.posterBase64" class="avatar">
                            <img :src="scope.row.posterBase64" />
                        </div>
                        <div v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></div>
                    </el-upload>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="优惠券"
                        align="center">
                    <div>
                        <p>{{scope.row.couponTypeBean.scope}}</p>
                        <p>折扣: {{scope.row.couponTypeBean.discountAmount}}</p>
                        <p>{{scope.row.couponTypeBean.remark}}</p>
                    </div>
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="center"
                        label="结束时间">
                    <span v-if="new Date(scope.row.endTime).getTime() < new Date().getTime()" :style="{color:'#909399'}">已结束</span>
                    <span v-else>{{dateTimeToString(new Date(scope.row.endTime))}}</span>
                </el-table-column>
                <el-table-column
                        prop="couponExpireTime"
                        align="center"
                        label="优惠券到期">
                </el-table-column>
                <el-table-column
                        prop="forMembershipLevel"
                        align="center"
                        label="用户群体">
                </el-table-column>
                <el-table-column
                        prop="gotUserCount"
                        align="center"
                        label="已领取人数">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="center"
                        label="弹海报">
                    <el-switch
                            v-model="scope.row.isPopPoster"
                            inline-prompt="true"
                            active-text="是"
                            inactive-text="否"
                            active-color="#67C23A"
                            inactive-color="#F56C6C"
                            @change="isPopPosterChange(scope.row.id, scope.$index)"
                    />
                </el-table-column>
                <el-table-column
                        width="70"
                        align="center">
                    <template #header>
                        <el-button @click="addDialogVisible = true" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <div class="operate-btn-group">
                            <el-tooltip content="复制链接" placement="bottom" effect="light" :enterable="false">
                                <i @click="handleClickCopy(scope.row.url)" class="el-icon-document-copy" :style="{marginRight:'10px'}"></i>
                            </el-tooltip>
                            <el-tooltip content="删除活动" placement="bottom" effect="light" :enterable="false">
                                <i @click="handleDelete(scope.$index)" class="el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
        <el-dialog
                :close-on-click-modal="!addRequesting"
                :close-on-press-escape="false"
                :show-close="!addRequesting"
                v-model="addDialogVisible"
                @open="addDialogOpen"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="addRules"
                    :label-position="left"
                    label-width="80px"
                    :model="addFormData"
                    size="small"
                    v-loading="addRequesting">
                <el-form-item label="优惠券" prop="couponTypeId">
                    <el-select v-model="addFormData.couponTypeId" placeholder="请选择" :style="{width:'100%'}">
                        <el-option v-for="row in couponTypeList" :key="row.id" :value="row.id" :label="row.discountAmount + '(' + row.scope + ')' + ' - ' + row.remark"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker v-model="addFormData.endTime" type="datetime" :editable="false" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择时间" :style="{width:'100%'}"></el-date-picker>
                </el-form-item>
                <el-form-item label="到期类型" prop="discountType">
                    <el-select v-model="couponExpireType" @change="couponExpireTypeChange" placeholder="优惠券到期类型" :style="{width:'100%'}">
                        <el-option :key="0" :value="0" :label="'指定时间'"></el-option>
                        <el-option :key="1" :value="1" :label="'有效时长'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="couponExpireType === 0" label="结束时间" prop="couponExpireTime">
                    <el-date-picker v-model="addFormData.couponExpireTime" type="datetime" :editable="false" value-format="YYYY-MM-DD HH:mm:ss" placeholder="优惠券结束时间" :style="{width:'100%'}"></el-date-picker>
                </el-form-item>
                <el-form-item v-else label="有效时长" prop="couponEffectiveDay">
                    <el-input
                            @keyup.enter="addCouponDistribution"
                            v-model="addFormData.couponEffectiveDay"
                            placeholder="优惠券有效时长"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="群体等级" prop="forMembershipLevel">
                    <el-select v-model="forMembershipLevelList"
                               multiple collapse-tags collapse-tags-tooltip
                               placeholder="请选择目标群体等级"
                               :style="{width:'100%'}"
                    >
                        <el-option v-for="row in membershipLevelList" :key="row.id" :value="row.level" :label="row.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addRequesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addRequesting" @click="addCouponDistribution" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "CouponDistribution",
        components: {ManagerFrame},
        data() {
            let couponExpireTimeCheck = (rule, value, callback) => {
                if(this.couponExpireType === 0) {
                    if(value) {
                        callback();
                    } else {
                        callback(new Error("请选择优惠券到期时间"));
                    }
                } else {
                    callback();
                }
            }
            let couponEffectiveDayCheck = (rule, value, callback) => {
                if(this.couponExpireType === 1) {
                    if(value) {
                        if(/^[1-9][0-9]{0,2}$/.test(value)) {
                            callback();
                        } else {
                            callback(new Error("优惠券有效时长最多 999 天"));
                        }
                    } else {
                        callback(new Error("请输入优惠券有效时长"));
                    }
                } else {
                    callback();
                }
            }
            let forMembershipLevelCheck = (rule, value, callback) => {
                if(this.forMembershipLevelList.length < 1) {
                    callback(new Error("请选择目标群体等级"));
                } else {
                    callback();
                }
            }
            return {
                cardLoading: false,
                tableData: [],
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                addDialogVisible: false,
                addRequesting: false,
                addFormData: {},
                couponTypeList: [],
                couponExpireType: 0,
                membershipLevelList: [],
                forMembershipLevelList: [],
                addRules: {
                    couponTypeId: [
                        { required: true, message: "请选择优惠券", trigger: "change" }
                    ],
                    endTime: [
                        { required: true, message: "请选择结束时间", trigger: "change" }
                    ],
                    couponExpireTime: [
                        { validator: couponExpireTimeCheck, trigger: "change" }
                    ],
                    couponEffectiveDay: [
                        { validator: couponEffectiveDayCheck, trigger: "change" }
                    ],
                    forMembershipLevel: [
                        { validator: forMembershipLevelCheck, trigger: "change" }
                    ]
                }
            }
        },
        mounted() {
            this.currentPage = this.$route.params.currentPage;
            this.loadData();
        },
        methods: {
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.$router.push("/manager/coupon_distribution/" + currentPage);
                this.loadData();
            },
            loadData() {
                this.cardLoading = true;
                this.axios.post("/manager/coupon_distribution_list",{
                    "currentPage": this.currentPage
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.cardLoading = false;
                });
            },
            handleClickCopy(url) {
                this.copyText(url);
                this.$message.success({
                    message: "已复制: " + url,
                    type: "success"
                });
            },
            addDialogOpen() {
                this.addFormData = {};
                this.forMembershipLevelList = [];
                if(this.couponTypeList.length === 0) {
                    this.axios.post("/manager/coupon_type_list").then((response) => {
                        this.cardLoading = false;
                        this.couponTypeList = response.data;
                    });
                }
                if(this.membershipLevelList.length === 0) {
                    this.axios.post("/manager/active_membership_level_list").then((response) => {
                        this.cardLoading = false;
                        this.membershipLevelList = response.data;
                    });
                }
            },
            couponExpireTypeChange() {
                this.addFormData.couponExpireTime = null;
                this.addFormData.couponEffectiveDay = null;
            },
            addCouponDistribution() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        this.addRequesting = true;
                        let forMembershipLevel = "";
                        this.forMembershipLevelList.forEach((v) => {
                            forMembershipLevel += "," + v;
                        });
                        this.addFormData.forMembershipLevel = forMembershipLevel.substring(1);
                        this.axios.post("/manager/add_coupon_distribution", this.addFormData).then((response) => {
                            this.addRequesting = false;
                            this.addDialogVisible = false;
                            if(response.data.result) {
                                this.$message.success({
                                    message: "添加成功",
                                    type: "success"
                                });
                                this.tableData.unshift(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            beforeUpload(rawFile) {
                if(rawFile.type !== "image/jpeg" && rawFile.type !== "image/png") {
                    this.$message({
                        type: "error",
                        message: "只能上传图片!"
                    });
                    return false;
                } else {
                    if(rawFile.size / 1024 / 1024 > 1) {
                        this.$message({
                            type: "error",
                            message: "图片不能超过1MB!"
                        });
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            onUpLoad(option) {
                let formData = new FormData();
                formData.append("id", option.data.id);
                formData.append("poster", option.file);
                this.axios.post("/manager/update_coupon_distribution_poster", formData, {"type": "file"}).then((response) => {
                    if(response.data.result) {
                        this.tableData[option.data.index].posterBase64 = response.data.data;
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            },
            isPopPosterChange(id, index) {
                this.axios.post("/manager/update_coupon_distribution_pop_up", {
                    "id": id,
                    "isPopUp": this.tableData[index].isPopPoster
                }).then((response) => {
                    if(!response.data.result) {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                        this.tableData[index].isPopPoster = !this.tableData[index].isPopPoster;
                    }
                });
            },
            handleDelete(index) {
                this.$confirm('确定删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_coupon_distribution", {
                        "id": this.tableData[index].id
                    }).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.tableData.splice(index, 1);
                            this.$message.success({
                                message: "删除成功",
                                type: "success"
                            });
                            this.rowCount--;
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            }
        }
    }
</script>

<style>
    .el-switch__label--left.is-active {
        color: #F56C6C;
    }
    .el-switch__label--left {
        color: #DCDFE6;
    }
    .el-switch__label--right.is-active {
        color: #67C23A;
    }
    .el-switch__label--right {
        color: #DCDFE6;
    }
</style>
<style scoped>
    .avatar-uploader,.avatar-uploader-icon,.avatar-uploader .avatar {
        width: 100px;
        height: 100px;
    }
    .operate-btn-group i {
        cursor: pointer;
        font-size: 18px;
    }
</style>