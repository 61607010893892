<template>
    <ManagerFrame default-active="payment-recharge">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        label="名称"
                        prop="name">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="数量">
                    {{scope.row.quantity}}个月
                </el-table-column>
                <el-table-column
                        label="价格"
                        prop="price">
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="dialogVisible = !dialogVisible" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button @click="deleteRow(scope.$index, scope.row)" size="small" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
                title="添加充值选项"
                v-model="dialogVisible"
                :close-on-click-modal="!formRequesting"
                :close-on-press-escape="false"
                :show-close="!formRequesting"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    v-loading="formRequesting"
                    size="small">
                <el-form-item label="名称" prop="name">
                    <el-input
                            @keyup.enter="addRechargeSetting"
                            v-model="formData.name"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input
                            @keyup.enter="addRechargeSetting"
                            v-model="formData.price"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="数量(月)" prop="quantity">
                    <el-input
                            @keyup.enter="addRechargeSetting"
                            v-model="formData.quantity"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" @click="addRechargeSetting" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
import ManagerFrame from "../components/ManagerFrame";
export default {
    name: "RechargeSetting",
    components: {ManagerFrame},
    mounted() {
        let $this = this;
        this.axios.post("/manager/recharge_settings").then(function(response) {
            $this.tableData = response.data;
            $this.cardLoading = false;
        });
    },
    data() {
        return {
            dialogVisible: false,
            formRequesting: false,
            cardLoading: true,
            tableData: [],
            formData: {
                name: "",
                price: "",
                quantity: ""
            }
        }
    },
    methods: {
        addRechargeSetting() {
            this.$refs["addForm"].validate((valid) => {
                if(valid) {
                    this.formRequesting = true;
                    let $this = this;
                    this.axios.post("/manager/add_recharge_setting", {
                        "name": this.formData.name,
                        "price": this.formData.price,
                        "quantity": this.formData.quantity
                    }).then(function(response) {
                        $this.formRequesting = false;
                        $this.dialogVisible = false;
                        if(response.data.result) {
                            $this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                            $this.tableData.unshift(response.data.data);
                        } else {
                            $this.$message.error({
                                message: '添加失败,' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                }
            });
        },
        deleteRow(index, data) {
            let $this = this;
            this.$confirm("确定要删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: 'warning'
            }).then(() => {
                this.cardLoading = true;
                this.axios.post("/manager/del_recharge_setting", data).then(function(response) {
                    $this.cardLoading = false;
                    if(response.data.result) {
                        $this.tableData.splice(index, 1);
                        $this.$message.success({
                            message: response.data.message,
                            type: 'success'
                        });
                    } else {
                        $this.$message.error({
                            message: '删除失败! ' + response.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        }
    }
}
</script>

<style scoped>

</style>