<template>
    <ManagerFrame default-active="membershipLevelConditionProfit">
        <el-card shadow="always" v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        #default="scope"
                        label="名称">
                    <el-input v-if="scope.row.edit" v-model="scope.row.name" size="mini"></el-input>
                    <span v-else>{{scope.row.name}}</span>
                </el-table-column>
                <el-table-column
                        label="别名"
                        prop="alias">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        align="right">
                    <el-button v-if="!scope.row.edit"
                               size="mini"
                               @click="handleEdit(scope.row)">修改</el-button>
                    <template v-else>
                        <el-button size="mini" type="success" @click="handleSave(scope.row)">保存</el-button>
                        <el-button size="mini" @click="handleCancel(scope.row)">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";

    export default {
        name: "ConditionAndProfitName",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/get_condition_profit_name_list").then((response) => {
                this.cardLoading = false;
                this.tableData = response.data;
            });
        },
        data() {
            return {
                cardLoading: true,
                tableData: [],
            }
        },
        methods: {
            handleEdit(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true
            },
            handleCancel(data) {
                data.name = data.oldData.name;
                data.edit = false
            },
            handleSave(data) {
                if(data.name.trim() === "") {
                    this.$message.error({
                        message: "操作失败,名称不能为空!",
                        type: "error"
                    });
                    return;
                }
                this.cardLoading = true;
                this.axios.post("/manager/update_condition_profit_name", {
                    "id": data.id,
                    "name": data.name.trim()
                }).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        data.edit = false;
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>