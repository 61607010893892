<template>
    <el-result
            id="result-success"
            icon="success"
            title="Registered Successfully"
            sub-title="Click the button below to download OPS"
    >
        <template #extra>
            <p><a :href="androidUrl"><el-button class="android-btn" icon="fab fa-android"> For Android</el-button></a></p>
            <p v-if="iosUrl"><a :href="iosUrl" target="_blank"><el-button :style="{backgroundColor:'#333', borderColor:'#333', color:'#fff', width:'160px'}" icon="fab fa-apple"> For IOS</el-button></a></p>
            <p><a href="/app_web_redirect"><el-button type="primary" :style="{width:'160px'}" icon="fab fa-brands fa-chrome"> For Web</el-button></a></p>
            <p :style="{marginTop:'100px',fontSize:'14px'}">Can't download? Click the button below to contact us.</p>
            <p>
                <el-badge :value="1" type="danger"><el-button @click="getCustomerService" class="customer-service-btn" icon="fab fa-whatsapp"> CHAT WITH US</el-button></el-badge>
            </p>
        </template>
    </el-result>
</template>

<script>
    export default {
        name: "RegisterSuccess",
        mounted() {
            //获取下载链接
            this.axios.post("/user/get_app_download_url").then((response) => {
                this.androidUrl = response.data["androidUrl"];
                this.iosUrl = response.data["iosUrl"];
                this.webApp = response.data["webApp"];
            });
        },
        data() {
            return {
                androidUrl: "",
                iosUrl: "",
                webAppUrl: ""
            }
        },
        methods: {
            getCustomerService() {
                this.loading = true;
                let customerService = localStorage.getItem("customerService");
                this.axios.post("/user/get_customer_service", {
                    "customerService": customerService
                }).then((response) => {
                    this.loading = false;
                    if(response.data.result) {
                        customerService = response.data.data;
                        localStorage.setItem("customerService", customerService);
                        document.location.href = customerService;
                    } else {
                        this.$message.error(response.data.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>
p {
    margin-bottom: 20px;
}
.android-btn {
    background-color: rgb(45,42,94);
    color: #fff;
    border-color: rgb(45,42,94);
    width: 160px;
}
.customer-service-btn {
    background-color: rgb(68,146,141);
    color: #fff;
    border-color: rgb(68,146,141);
    width: 160px;
}
</style>
<style>
#result-success .icon-success {
    fill: #eb5893;
}
</style>