<template>
    <ManagerFrame default-active="userHierarchies">
        <el-card class="main" shadow="always" v-loading="cardLoading">
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-table
                            class="user-hierarchies-table"
                            :data="hierarchyTableData"
                            highlight-current-row
                            @current-change="handleHierarchyChange">
                        <el-table-column
                                #default="scope"
                                label="名称">
                            <span>{{scope.row.name}}</span>
                        </el-table-column>
                        <el-table-column
                                #default="scope"
                                align="center"
                                label="">
                            <span v-if="scope.row.clientDefault" :style="{color:'#67C23A', fontSize:'12px'}">默认</span>
                        </el-table-column>
                        <el-table-column
                                #default="scope"
                                align="center"
                                label="显示头衔">
                            <span v-if="scope.row.clientShowTitle" :style="{color:'#67C23A'}">是</span>
                            <span v-else :style="{color:'#F56C6C'}">否</span>
                        </el-table-column>
                        <el-table-column
                                prop="crownCount"
                                align="center"
                                label="皇冠数量">
                        </el-table-column>
                        <el-table-column
                                align="right">
                            <template #header>
                                <el-button @click="hierarchyDialogVisible = true" size="small" type="primary">添加</el-button>
                            </template>
                            <template #default="scope">
                                <el-button size="mini"
                                           type="danger"
                                           @click="handleDeleteHierarchy(scope.$index, scope.row)">删除</el-button>
                                <el-button size="mini"
                                           @click="handleEditHierarchy(scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col v-if="selectedHierarchy" :span="14">
                    <el-table
                            stripe="true"
                            :data="hierarchyLevelTableData">
                        <el-table-column
                                property="title"
                                show-overflow-tooltip="true"
                                #default="scope"
                                label="标题">
                            <el-input v-if="scope.row.edit" v-model="scope.row.title" size="mini"></el-input>
                            <span v-else>{{scope.row.title}}</span>
                        </el-table-column>
                        <el-table-column
                                property="level"
                                label="层级">
                        </el-table-column>
                        <el-table-column
                                property="revenueRate"
                                #default="scope"
                                label="收益">
                            <el-input v-if="scope.row.edit" v-model="scope.row.revenueRate" size="mini"></el-input>
                            <span v-else>{{scope.row.revenueRate}}</span>
                        </el-table-column>
                        <el-table-column
                                width="200px"
                                align="right">
                            <template #header>
                                <el-button v-if="!selectedHierarchy.clientDefault" @click="setDefault" size="small" type="success">设为默认</el-button>
                                <el-button @click="dialogVisible = !dialogVisible" size="small" type="primary">添加</el-button>
                            </template>
                            <template #default="scope">
                                <el-button v-if="(scope.$index === hierarchyLevelTableData.length - 1) && (!scope.row.edit)"
                                           size="mini"
                                           type="danger"
                                           @click="handleDeleteHierarchyLevel(scope.$index, scope.row)">删除</el-button>
                                <el-button v-if="!scope.row.edit"
                                           size="mini"
                                           @click="handleEdit(scope.row)">修改</el-button>
                                <template v-else>
                                    <el-button size="mini" type="success" @click="handleSaveHierarchyLevel(scope.row)">保存</el-button>
                                    <el-button size="mini" @click="handleCancelHierarchyLevel(scope.row)">取消</el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog
                title="添加团队规则"
                v-model="hierarchyDialogVisible"
                :close-on-click-modal="!hierarchyFormRequesting"
                :close-on-press-escape="false"
                :show-close="!hierarchyFormRequesting"
                @closed="hierarchyDialogClosed"
                width="400px">
            <el-form
                    ref="addHierarchyForm"
                    :rules="hierarchyRules"
                    :label-position="left"
                    label-width="80px"
                    :model="hierarchyFormData"
                    size="small">
                <el-form-item label="名称" prop="name">
                    <el-input
                            @keyup.enter="handleSubmitHierarchy"
                            v-model="hierarchyFormData.name"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="显示头衔" prop="clientShowTitle">
                    <el-switch v-model="hierarchyFormData.clientShowTitle" active-color="#67C23A" inactive-color="#F56C6C" active-text="是" inactive-text="否" inline-prompt></el-switch>
                </el-form-item>
                <el-form-item label="皇冠数量" prop="crownCount">
                    <el-input
                            @keyup.enter="handleSubmitHierarchy"
                            v-model.number="hierarchyFormData.crownCount"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="hierarchyFormRequesting" @click="hierarchyDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="hierarchyFormRequesting" @click="handleSubmitHierarchy" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
                title="添加层级"
                v-model="dialogVisible"
                :close-on-click-modal="!formRequesting"
                :close-on-press-escape="false"
                :show-close="!formRequesting"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="rules"
                    :label-position="left"
                    label-width="80px"
                    :model="formData"
                    size="small">
                <el-form-item label="标题" prop="title">
                    <el-input
                            @keyup.enter="addHierarchyLevel"
                            v-model="formData.title"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="收益" prop="revenueRate">
                    <el-input
                            @keyup.enter="addHierarchyLevel"
                            v-model="formData.revenueRate"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="formRequesting" @click="dialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="formRequesting" @click="addHierarchyLevel" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "UserHierarchies",
        components: {ManagerFrame},
        mounted() {
            this.axios.post("/manager/hierarchy_list").then((response) => {
                this.hierarchyTableData = response.data;
                this.cardLoading = false;
            });
        },
        data() {
            let rateCheck = (rule, value, callback) => {
                let i = parseFloat(value);
                if (value === "") {
                    callback(new Error("请输入收益"));
                } else if (isNaN(value)) {
                    callback(new Error("只能输入数字"));
                } else if((i <= 0) || (i > 100)) {
                    callback(new Error("数字必须大于 0 ,小于 100"));
                }else {
                    callback();
                }
            }
            return {
                cardLoading: true,
                selectedHierarchy: null,
                hierarchyTableData: [],
                hierarchyLevelTableData: [],
                dialogVisible: false,
                formRequesting: false,
                formData: {
                    hierarchyId: -1,
                    title: "",
                    revenueRate: ""
                },
                hierarchyDialogVisible: false,
                hierarchyFormRequesting: false,
                hierarchyFormData: {
                    name: "",
                    clientShowTitle: false,
                    crownCount: 0
                },
                rules: {
                    title: [
                        { required: true, message: "不能为空", trigger: "change" },
                        { max: 20, message: "长度在不能超过 50 个字符", trigger: "change" }
                    ],
                    revenueRate: [
                        { required: true, message: "不能为空", trigger: "change" },
                        { validator: rateCheck, trigger: "change" }
                    ],
                },
                hierarchyRules: {
                    name: [
                        { required: true, message: "不能为空", trigger: "change" }
                    ]
                }
            }
        },
        methods: {
            handleEdit(data) {
                data.oldData = null;
                data.oldData = JSON.parse(JSON.stringify(data));
                data.edit = true;
            },
            handleEditHierarchy(data) {
                this.hierarchyFormData = JSON.parse(JSON.stringify(data));
                this.hierarchyDialogVisible = true;
            },
            handleCancelHierarchyLevel(data) {
                data.title = data.oldData.title;
                data.revenueRate = data.oldData.revenueRate;
                data.edit = false;
            },
            handleSubmitHierarchy() {
                this.$refs["addHierarchyForm"].validate((valid) => {
                    if(!valid) return;
                    this.hierarchyFormRequesting = true;
                    if(this.hierarchyFormData.id) {
                        this.axios.post("/manager/update_hierarchy", this.hierarchyFormData).then((response) => {
                            if(response.data.result) {
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                                for(let i=0; i<this.hierarchyTableData.length; i++) {
                                    if(this.hierarchyTableData[i].id === this.hierarchyFormData.id) {
                                        this.hierarchyTableData[i] = JSON.parse(JSON.stringify(this.hierarchyFormData))
                                        break;
                                    }
                                }
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                            this.hierarchyDialogVisible = false;
                            this.hierarchyFormRequesting = false;
                        });
                    } else {
                        this.axios.post("/manager/add_hierarchy", this.hierarchyFormData).then((response) => {
                            this.hierarchyDialogVisible = false;
                            this.hierarchyFormRequesting = false;
                            if(response.data.result) {
                                this.$message({
                                    type: "success",
                                    message: response.data.message
                                });
                                this.hierarchyTableData.unshift(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },
            handleDeleteHierarchy(index, data) {
                this.$confirm("确定删除 \"" + data.name + "\" 吗?", '提示', {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_hierarchy", data).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.selectedHierarchy = null;
                            this.hierarchyTableData.splice(index,1);
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            handleHierarchyChange(currentRow) {
                if(currentRow) {
                    this.selectedHierarchy = currentRow;
                    this.cardLoading = true;
                    this.axios.post("/manager/hierarchy_level_list", {
                        "hierarchyId": currentRow.id
                    }).then((response) => {
                        this.hierarchyLevelTableData = response.data;
                        this.cardLoading = false;
                    });
                }
            },
            setDefault() {
                if(this.selectedHierarchy === "") {
                    this.$message.error({
                        message: "参数错误",
                        type: "error"
                    });
                    return;
                }
                this.$confirm("确定设置 \"" + this.selectedHierarchy.name + "\" 为默认吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    let data = JSON.parse(JSON.stringify(this.selectedHierarchy));
                    data.clientDefault = true;
                    this.axios.post("/manager/update_hierarchy",data).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.hierarchyTableData.forEach(function(value) {
                                value.clientDefault = false;
                            })
                            this.selectedHierarchy.clientDefault = true;
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            addHierarchyLevel() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        this.formRequesting = true;
                        this.formData.hierarchyId = this.selectedHierarchy.id;
                        this.axios.post("/manager/add_hierarchy_level", this.formData).then((response) => {
                            this.formRequesting = false;
                            this.dialogVisible = false;
                            if(response.data.result) {
                                this.$message.success({
                                    message: response.data.message,
                                    type: "success"
                                });
                                this.hierarchyLevelTableData.push(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },
            handleSaveHierarchyLevel(data) {
                if(data.title.trim() === "") {
                    this.$message.error({
                        message: "请输入标题!",
                        type: "error"
                    });
                    return;
                }
                if(data.revenueRate <= 0) {
                    this.$message.error({
                        message: "收益只能大于 0 !",
                        type: "error"
                    });
                    return;
                }
                if(data.revenueRate >= 1000) {
                    this.$message.error({
                        message: "收益只能小于 100 !",
                        type: "error"
                    });
                    return;
                }
                this.cardLoading = true;
                this.axios.post("/manager/update_hierarchy_level", data).then((response) => {
                    this.cardLoading = false;
                    if(response.data.result) {
                        data.edit = false;
                        this.$message.success({
                            message: response.data.message,
                            type: "success"
                        });
                    } else {
                        this.$message.error({
                            message: response.data.message,
                            type: "error"
                        });
                    }
                });
            },
            handleDeleteHierarchyLevel(index, data) {
                this.$confirm("确定删除 \"" + data.title + "\" 吗?", '提示', {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_hierarchy_level", data).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.hierarchyLevelTableData.splice(index,1);
                            this.$message.success({
                                message: response.data.message,
                                type: "success"
                            });
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            hierarchyDialogClosed() {
                this.hierarchyFormData = {
                    name: "",
                    clientShowTitle: false,
                    crownCount: 0
                }
                this.$refs["addHierarchyForm"].resetFields();
            }
        }
    }
</script>

<style scoped>
    .user-hierarchies-table:hover {
        cursor: pointer;
    }
</style>