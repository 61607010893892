<template>
    <el-dialog
            :title="title" :model="model"
            @open="openCallback"
            width="1000px">
        <el-table
                :data="tableData"
                v-loading="loading"
                stripe="true"
                style="width: 100%">
            <el-table-column
                    label="姓名"
                    prop="name">
            </el-table-column>
            <el-table-column
                    label="邮箱"
                    prop="email">
            </el-table-column>
            <el-table-column
                    label="手机"
                    prop="mobileNumber">
            </el-table-column>
            <el-table-column
                    label="银行"
                    prop="bankName">
            </el-table-column>
            <el-table-column
                    label="卡号"
                    prop="cardNumber">
            </el-table-column>
            <el-table-column
                    label="创建时间"
                    :formatter="formatDate"
                    prop="createTime">
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
    export default {
        name: "UserBankInfoDialog",
        props: {
            model: Boolean,
            userId: Number,
            title: String
        },
        data() {
            return {
                loading: false,
                tableData: []
            }
        },
        methods: {
            openCallback() {
                this.tableData = [];
                this.loading = true;
                this.axios.post("/salesman/get_user_bank_info", {
                    "userId": this.userId
                }).then((response) => {
                    this.tableData = response.data;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>