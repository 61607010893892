<template>
    <ManagerFrame default-active="coupon-auto">
        <el-card v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        prop="posterBase64"
                        align="center"
                        label="类型">
                </el-table-column>
                <el-table-column
                        #default="scope"
                        label="优惠券"
                        align="center">
                    <div>
                        <p>{{scope.row.couponTypeBean.scope}}</p>
                        <p>折扣: {{scope.row.couponTypeBean.discountAmount}}</p>
                        <p>{{scope.row.couponTypeBean.remark}}</p>
                    </div>
                </el-table-column>
                <el-table-column
                        prop="couponExpireTime"
                        align="center"
                        label="优惠券时效">
                </el-table-column>
                <el-table-column
                        width="70"
                        align="center">
                    <template #header>
                        <el-button @click="addDialogVisible = true" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <div class="operate-btn-group">
                            <el-tooltip content="删除设置" placement="bottom" effect="light" :enterable="false">
                                <i @click="handleDelete(scope.$index)" class="el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="rowCount"
                :page-size="pageSize"
                :current-page="currentPage"
        />
        <el-dialog
                :close-on-click-modal="!addRequesting"
                :close-on-press-escape="false"
                :show-close="!addRequesting"
                v-model="addDialogVisible"
                @open="addDialogOpen"
                width="400px">
            <el-form
                    ref="addForm"
                    :rules="addRules"
                    label-position="left"
                    label-width="80px"
                    :model="addFormData"
                    size="small"
                    v-loading="addRequesting">
                <el-form-item label="类型" prop="discountType">
                    <el-select v-model="addFormData.type" placeholder="请选择触发条件" :style="{width:'100%'}">
                        <el-option :key="0" :value="0" :label="'新注册用户'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠券" prop="couponTypeId">
                    <el-select v-model="addFormData.couponTypeId" placeholder="请选择优惠券" :style="{width:'100%'}">
                        <el-option v-for="row in couponTypeList" :key="row.id" :value="row.id" :label="row.discountAmount + '(' + row.scope + ')' + ' - ' + row.remark"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="有效时长" prop="couponEffectiveDay">
                    <el-input
                            @keyup.enter="addAutoDistributeCoupon"
                            v-model="addFormData.couponEffectiveDay"
                            placeholder="优惠券有效时长"
                            clearable
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                  <el-button :disabled="addRequesting" @click="addDialogVisible = false" size="small">取 消</el-button>
                  <el-button type="primary" :disabled="addRequesting" @click="addAutoDistributeCoupon" size="small">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "AutoDistributeCoupon",
        components: {ManagerFrame},
        mounted() {
            this.currentPage = this.$route.params.currentPage;
            this.loadData();
        },
        data() {
            let couponEffectiveDayCheck = (rule, value, callback) => {
                if(value) {
                    if(/^[1-9][0-9]{0,2}$/.test(value)) {
                        callback();
                    } else {
                        callback(new Error("优惠券有效时长最多 999 天"));
                    }
                } else {
                    callback(new Error("请输入优惠券有效时长"));
                }
            }
            return {
                cardLoading: false,
                tableData: [],
                rowCount: 0,
                pageSize: 10,
                currentPage: 1,
                addDialogVisible: false,
                addRequesting: false,
                addFormData: {},
                couponTypeList: [],
                addRules: {
                    type: [
                        { required: true, message: "请选择类型", trigger: "change" }
                    ],
                    couponTypeId: [
                        { required: true, message: "请选择优惠券", trigger: "change" }
                    ],
                    couponEffectiveDay: [
                        { validator: couponEffectiveDayCheck, trigger: "change" }
                    ]
                }
            }
        },
        methods: {
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.$router.push("/manager/auto_distribute_coupon/" + currentPage);
                this.loadData();
            },
            loadData() {
                this.cardLoading = true;
                this.axios.post("/manager/auto_distribute_coupon_list",{
                    "currentPage": this.currentPage
                }).then((response) => {
                    this.tableData = response.data.dataList;
                    this.rowCount = response.data.rowCount;
                    this.pageSize = response.data.pageSize;
                    this.currentPage = response.data.currentPage;
                    this.cardLoading = false;
                });
            },
            handleDelete(index) {
                this.$confirm('确定删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/del_auto_distribute_coupon", {
                        "id": this.tableData[index].id
                    }).then((response) => {
                        this.cardLoading = false;
                        if(response.data.result) {
                            this.tableData.splice(index, 1);
                            this.$message.success({
                                message: "删除成功",
                                type: "success"
                            });
                            this.rowCount--;
                        } else {
                            this.$message.error({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
                });
            },
            addDialogOpen() {
                this.addFormData = {};
                if(this.couponTypeList.length === 0) {
                    this.axios.post("/manager/coupon_type_list").then((response) => {
                        this.couponTypeList = response.data;
                    });
                }
            },
            addAutoDistributeCoupon() {
                this.$refs["addForm"].validate((valid) => {
                    if(valid) {
                        this.addRequesting = true;
                        this.axios.post("/manager/add_auto_distribute_coupon", this.addFormData).then((response) => {
                            this.addRequesting = false;
                            this.addDialogVisible = false;
                            if(response.data.result) {
                                this.$message.success({
                                    message: "添加成功",
                                    type: "success"
                                });
                                this.tableData.unshift(response.data.data);
                            } else {
                                this.$message.error({
                                    message: response.data.message,
                                    type: "error"
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .operate-btn-group i {
        cursor: pointer;
        font-size: 18px;
    }
</style>