<template>
    <ManagerFrame default-active="websiteSetting">
        <el-card v-loading="cardLoading">
            <el-table
                    :data="tableData"
                    stripe="true"
                    style="width: 100%">
                <el-table-column
                        label="链接"
                        prop="url">
                </el-table-column>
                <el-table-column
                        label="使用次数"
                        prop="useTimes">
                </el-table-column>
                <el-table-column
                        align="right">
                    <template #header>
                        <el-button @click="addWebsite" size="small" type="primary">添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    v-if="tableData.length > 0"
                    background
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :total="rowCount"
                    :page-size="pageSize"
                    :current-page="currentPage">
            </el-pagination>
        </el-card>
    </ManagerFrame>
</template>

<script>
    import ManagerFrame from "../components/ManagerFrame";
    export default {
        name: "WebsiteSetting",
        components: {ManagerFrame},
        data() {
            return {
                cardLoading: true,
                pageSize: 10,
                rowCount: 0,
                currentPage: 1,
                tableData: []
            }
        },
        mounted() {
            this.loadWebsite(this.currentPage);
        },
        methods: {
            handleCurrentChange(currentPage) {
                this.loadWebsite(currentPage);
            },
            loadWebsite(currentPage) {
                this.cardLoading = true;
                let $this = this;
                this.axios.post("/manager/website_list",{
                    "currentPage": currentPage
                }).then(function(response) {
                    $this.cardLoading = false;
                    $this.tableData = response.data.dataList;
                    $this.rowCount = response.data.rowCount;
                    $this.pageSize = response.data.pageSize;
                    $this.currentPage = response.data.currentPage;
                });
            },
            addWebsite() {
                this.$prompt('请输入链接', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/,
                    inputErrorMessage: '链接格式不正确'
                }).then(({ value }) => {
                    this.cardLoading = true;
                    let $this = this;
                    this.axios.post("/manager/add_website",{
                        "url": value
                    }).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.$message({
                                type: "success",
                                message: "添加成功!"
                            });
                            if($this.tableData.length === $this.pageSize) {
                                $this.tableData.pop();
                            }
                            $this.tableData.unshift(response.data.data);
                            $this.rowCount++;
                        } else {
                            $this.$message.error({
                                message: '添加失败,' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                })
            },
            handleDelete(index, data) {
                let $this = this;
                this.$confirm('确定删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.cardLoading = true;
                    this.axios.post("/manager/delete_website", data).then(function(response) {
                        $this.cardLoading = false;
                        if(response.data.result) {
                            $this.tableData.splice(index, 1);
                            $this.$message.success({
                                message: '删除成功',
                                type: 'success'
                            });
                            $this.rowCount--;
                        } else {
                            $this.$message.error({
                                message: '删除失败! ' + response.data.message,
                                type: 'error'
                            });
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>