<template>
    <el-dropdown
            @visible-change="loadData"
            @command="handleCommand"
            trigger="click">
        <slot></slot>
        <template #dropdown>
            <el-dropdown-menu :style="{ textAlign: 'center' }">
                <template v-for="(row, index) in dataList" :key="row.userId">
                    <el-dropdown-item :command="row.username">{{row.username}}</el-dropdown-item>
                    <i class="el-icon-caret-bottom" v-if="index < dataList.length - 1"></i>
                </template>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
    export default {
        name: "UserRelationshipDropdown",
        props: {
            userId: Number
        },
        data() {
            return {
                dataList: []
            }
        },
        methods: {
            loadData(command) {
                if(command) {
                    let $this = this;
                    this.axios.post("/salesman/user_relationship", {
                        "userId": this.userId
                    }).then(function (response) {
                        $this.dataList = response.data;
                    });
                }
            },
            handleCommand(command) {
                document.location.href = "/salesman/user_list/1/" + command;
            }
        }
    }
</script>

<style scoped>

</style>