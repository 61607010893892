<template>
    <SalesmanFrame default-active="rankingList">
        <div v-loading="loading" :style="{float:'right'}">
            <el-date-picker
                    v-model="dateRange"
                    @change="dateRangeChange"
                    :style="{marginRight: '20px'}"
                    value-format="YYYY-MM-DD"
                    size="small"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            <el-radio-group @change="shortcutsChange" v-model="shortcuts" size="small">
                <el-radio-button label="month">当前月份</el-radio-button>
                <el-radio-button label="week">当前星期</el-radio-button>
                <el-radio-button label="yesterday">昨天</el-radio-button>
                <el-radio-button label="today">今天</el-radio-button>
                <el-radio-button label="all">全部</el-radio-button>
            </el-radio-group>
        </div>
        <div :style="{width:'100%',marginTop:'60px'}">
            <el-row :gutter="10">
                <el-col :span="8">
                    <RankingListTable
                            title="注册人数"
                            type="spreadRegister"
                            :date-range="dateRange"
                    ></RankingListTable>
                </el-col>
                <el-col :span="8">
                    <RankingListTable
                            title="会员人数"
                            type="spreadMember"
                            :date-range="dateRange"
                    ></RankingListTable>
                </el-col>
                <el-col :span="8">
                    <RankingListTable
                            title="网站数"
                            type="spreadWebsite"
                            :date-range="dateRange"
                    ></RankingListTable>
                </el-col>
            </el-row>
            <el-row  :gutter="10" :style="{marginTop:'20px'}">
                <el-col :span="8">
                    <RankingListTable
                            title="金额"
                            type="purchaseAmount"
                            :date-range="dateRange"
                    ></RankingListTable>
                </el-col>
                <el-col :span="8">
                    <RankingListTable
                            title="等级"
                            type="membershipLevel"
                            :date-range="dateRange"
                    ></RankingListTable>
                </el-col>
            </el-row>
        </div>
    </SalesmanFrame>
    
</template>

<script>
    import SalesmanFrame from "../components/SalesmanFrame";
    import RankingListTable from "../components/RankingListTable";

    export default {
        name: "RankingList",
        components: {RankingListTable, SalesmanFrame},
        mounted() {
            this.shortcuts = this.$route.params.shortcuts;
            let dateStart = this.$route.params.dateStart;
            let dateEnd = this.$route.params.dateEnd;
            if((dateStart === "") || (dateEnd === "")) {
                dateStart = this.getSpecialDate("yesterday");
                dateEnd = this.getSpecialDate("yesterday");
                this.shortcuts = "yesterday";
            }
            this.dateRange = [dateStart, dateEnd];
        },
        data() {
            return {
                loading: false,
                shortcuts: "all",
                dateRange: [],
            }
        },
        methods: {
            shortcutsChange(value) {
                let startDate = "1970-01-01";
                let endDate = "";
                switch(value) {
                    case "all":
                        endDate = this.dateToString(new Date());
                        break;
                    case "today":
                        startDate = this.getSpecialDate("today");
                        endDate = this.dateToString(new Date());
                        break;
                    case "yesterday":
                        startDate = this.getSpecialDate("yesterday");
                        endDate = this.getSpecialDate("yesterday");
                        break;
                    case "week":
                        startDate = this.getSpecialDate("firstDayOfWeek");
                        endDate = this.dateToString(new Date());
                        break;
                    case "month":
                        startDate = this.getSpecialDate("firstDayOfMonth");
                        endDate = this.dateToString(new Date());
                        break;
                }
                this.dateRange = [startDate, endDate];
                this.$router.push("/salesman/ranking_list/" + value + "/" + startDate + "/" + endDate);
            },
            dateRangeChange(value) {
                this.shortcuts = "custom";
                this.$router.push("/salesman/ranking_list/custom/" + value[0] + "/" + value[1]);
            },
        }
    }
</script>

<style scoped>

</style>