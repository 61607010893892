<template>
    <el-result
            id="result-success"
            icon="success"
            title="Payment Successful"
            sub-title="Your website is under review."
    >
        <template #extra>
            <el-button type="primary" size="medium" @click="back">Back</el-button>
        </template>
    </el-result>
</template>

<script>
    export default {
        name: "PaySuccess",
        methods: {
            back() {
                document.location.href = "back://"
            }
        }
    }
</script>

<style scoped>

</style>