<template>
    <div class="content">
        <el-row>
            <el-col class="logo" :xs="24" :md="12">
                <div>
                    <img src="../../assets/aad_logo_pink.png">
                </div>
                <p>©2020 Adopsandadtech Limited<br>Designed by <a href="/">Adopsandadtech Limited</a> |&nbsp;Powered by <a href="/">OPS</a></p>
                <p><a href="/privacy_policy" :style="{color: '#2f2861', fontWeight: 600}">Privacy Policy</a></p>
            </el-col>
            <el-col :xs="24" :md="4">
                <h3>WHO WE ARE</h3>
                <p>
                    <a href="/">Home</a>
                    <br>
                    <a href="/">About Us</a>
                    <br>
                    <a href="/contact">Contact Us</a>
                </p>
            </el-col>
<!--            <el-col :xs="24" :md="4">-->
<!--                <h3>WHAT WE DO</h3>-->
<!--                <p>-->
<!--                    <a>For Publishers</a>-->
<!--                    <br>-->
<!--                    <a>For Agencies</a>-->
<!--                    <br>-->
<!--                    <a>Systems</a>-->
<!--                    <br>-->
<!--                    <a>Training</a>-->
<!--                    <br>-->
<!--                    <a>Google Reseller</a>-->
<!--                </p>-->
<!--            </el-col>-->
            <el-col :xs="24" :md="4">
                <h3>GET IN TOUCH</h3>
                <p>Need help with a specific problem? Let us know what it is and we’ll help you solve it.</p>
            </el-col>
            <el-col :xs="24" :md="4">
                <router-link to="contact">
                    <div class="card-block-btn">
                        <a>Contact Us ></a>
                    </div>
                </router-link>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "UserFooter"
    }
</script>

<style scoped>
* {
    font-family: sofia-pro;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}
a {
    color: #2f2861;
    font-weight: 600;
    line-height: 1.8;
}
.logo a,h3 {
    color: #e11f8f;
}
.content {
    background-color: #f2f2f2;
}
@media only screen and (min-width: 992px) {
.content {
    padding: 2vmax 6vw;
    font-size: 13px;
}
.logo img {
    display: inline-block;
    width: 120px;
    margin-bottom: 30px;
}
.content h3 {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 20px;
}
.card-block-btn {
    float: right;
}
}

@media only screen and (max-width: 992px) {
p {
    font-size: 13px;
}
h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 15px;
}
.content {
    padding: 8vw 6vw;
}
.logo>div {
    margin-bottom: 6vw;
}
.logo img {
    width: 120px;
}
.card-block-btn {
    margin-top: 1rem;
}
}
</style>