<template>
    <header>
        <div id="mobile-header" :class="bgBlueColor">
            <div class="header-title">
                <a href="/">
                    <img src="../../assets/web-logo.png"  alt="logo">
                </a>
            </div>
<!--            <div class="header-toggle">-->
<!--                <div class="toggle-btn-box" @click="menuToggle">-->
<!--                    <div :class="'toggle-btn-top ' + toggleBtnTopAnimation"></div>-->
<!--                    <div :class="'toggle-btn-bottom ' + toggleBtnBottomAnimation"></div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </header>
    <transition name="fade">
        <div class="mobile-menu" v-show="isShow">
            <div class="mobile-menu-item">
                <a>
                    <div class="mobile-menu-item-content mobile-menu-item-first"> Home </div>
                </a>
            </div>
            <div class="mobile-menu-item">
                <a>
                    <div class="mobile-menu-item-content">
                        <span> What We Do </span>
                        <span class="chevron chevron--right"></span>
                    </div>
                </a>
            </div>
            <div class="mobile-menu-item">
                <a>
                    <div class="mobile-menu-item-content"> Google Reseller </div>
                </a>
            </div>
            <div class="mobile-menu-item">
                <a>
                    <div class="mobile-menu-item-content"> Training </div>
                </a>
            </div>
            <div class="mobile-menu-item">
                <a>
                    <div class="mobile-menu-item-content">
                        <span> About </span>
                        <span class="chevron chevron--right"></span>
                    </div>
                </a>
            </div>
            <div class="mobile-menu-item">
                <a>
                    <div class="mobile-menu-item-content"> Contact </div>
                </a>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "UserHeader",
        mounted() {
            // document.querySelector(".toggle-btn-top").addEventListener("animationend", ()=> {
            //     if(this.toggleBtnTopAnimation === "toggle-btn-top-animate") {
            //         this.toggleBtnTopAnimation = "toggle-btn-top-animate-end";
            //     } else if(this.toggleBtnTopAnimation === "toggle-btn-top-animate-end toggle-btn-top-animate-reverse") {
            //         this.toggleBtnTopAnimation = "";
            //     }
            // });
            // document.querySelector(".toggle-btn-bottom").addEventListener("animationend", ()=> {
            //     if(this.toggleBtnBottomAnimation === "toggle-btn-bottom-animate") {
            //         this.toggleBtnBottomAnimation = "toggle-btn-bottom-animate-end";
            //     } else if(this.toggleBtnBottomAnimation === "toggle-btn-bottom-animate-end toggle-btn-bottom-animate-reverse") {
            //         this.toggleBtnBottomAnimation = "";
            //     }
            // });
            window.addEventListener("scroll", this.handleScroll, true);
        },
        data() {
            return {
                isShow: false,
                toggleBtnTopAnimation: "",
                toggleBtnBottomAnimation: "",
                bgBlueColor: ""
            }
        },
        methods: {
            menuToggle() {
                if(this.isShow) {
                    this.toggleBtnTopAnimation = "toggle-btn-top-animate-end toggle-btn-top-animate-reverse";
                    this.toggleBtnBottomAnimation = "toggle-btn-bottom-animate-end toggle-btn-bottom-animate-reverse"
                    this.bgBlueColor = this.bgBlueColor.replace(" bg-transparent-color", "");
                } else {
                    this.toggleBtnTopAnimation = "toggle-btn-top-animate";
                    this.toggleBtnBottomAnimation = "toggle-btn-bottom-animate"
                    this.bgBlueColor = this.bgBlueColor + " bg-transparent-color";
                }
                this.isShow = !this.isShow;
            },
            handleScroll() {
                if(this.isShow) {
                    return;
                }
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop > 30) {
                    this.bgBlueColor = "bg-blue-color";
                } else  {
                    this.bgBlueColor = "";
                }
            }
        }
    }
</script>

<style scoped>
* {
    font-family: sofia-pro;
}
.bg-blue-color {
    background-color: #2f2861;
}
.bg-transparent-color {
    background-color: transparent;
}
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
#mobile-header {
    display: flex;
    padding: 2vmax 6vw;
}
#mobile-header .header-title {
    flex: 1 0 calc(100% - 50px);
}
#mobile-header .header-title img {
    height: 40px;
}
#mobile-header .header-toggle {
    width: 50px;
}
.toggle-btn-box {
    position: relative;
    height: 40px;
    width: 35px;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}
.toggle-btn-top {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: 15px;
    background-color: #2f2a6a;
}
.toggle-btn-bottom {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 15px;
    background-color: #2f2a6a;
}
.mobile-menu {
    padding-top: 48px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mobile-menu-item {
    height: 55px;
    font-size: 8.5vmin;
    font-weight: 600;
    color: #e11f8f;
    text-align: center;
}
.mobile-menu-item-content {
    line-height: 1;
    cursor: pointer;
    display: inline-block;
}
.mobile-menu-item-first {
    background-image: linear-gradient(currentColor,currentColor);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 100%;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}
.toggle-btn-top-animate {
    animation: toggle-btn-top 100ms linear forwards;

}
.toggle-btn-top-animate-reverse {
    animation: toggle-btn-top 100ms linear reverse forwards;

}
.toggle-btn-top-animate-end {
    top: 20px;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background-color: #000;
}
@keyframes toggle-btn-top {
    from {
        top: 15px;
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        background-color: #2f2a6a;
    }
    to {
        top: 20px;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        background-color: #000;
    }
}
.toggle-btn-bottom-animate {
    animation: toggle-btn-bottom 100ms linear forwards;

}
.toggle-btn-bottom-animate-reverse {
    animation: toggle-btn-bottom 100ms linear reverse forwards;

}
.toggle-btn-bottom-animate-end {
    bottom: 20px;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    background-color: #000;
}
@keyframes toggle-btn-bottom {
    from {
        bottom: 15px;
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    to {
        bottom: 20px;
        transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        background-color: #2f2a6a;
    }
}
@media only screen and (min-width: 992px) {

}

@media only screen and (max-width: 992px) {

}
</style>
<style>
.chevron {
    position: relative;
    display: inline-block;
    width: .4em;
    height: .4em;
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
    vertical-align: middle;
    margin-top: -.14em;
}
.chevron--right {
    transform: rotate(45deg);
}
/* devanagari */
@font-face {
    font-family: 'Rozha One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2kRc335fh4DolH3.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
    font-family: 'Rozha One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2kRfH35fh4DolH3.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Rozha One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rozhaone/v13/AlZy_zVFtYP12Zncg2kRcn35fh4Dog.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face{
    font-family:itc-avant-garde-gothic-pro;
    src:url(https://use.typekit.net/af/6161d4/00000000000000007735ba1e/30/l?subset_id=2&fvd=n5&v=3) format("woff2"),url(https://use.typekit.net/af/6161d4/00000000000000007735ba1e/30/d?subset_id=2&fvd=n5&v=3) format("woff"),url(https://use.typekit.net/af/6161d4/00000000000000007735ba1e/30/a?subset_id=2&fvd=n5&v=3) format("opentype");
    font-weight:500;
    font-style:normal;
    font-stretch:normal;font-display:auto;
}
@font-face{
    font-family:sofia-pro;
    src:url(https://use.typekit.net/af/2e2409/00000000000000007735f990/30/l?subset_id=2&fvd=n3&v=3) format("woff2"),url(https://use.typekit.net/af/2e2409/00000000000000007735f990/30/d?subset_id=2&fvd=n3&v=3) format("woff"),url(https://use.typekit.net/af/2e2409/00000000000000007735f990/30/a?subset_id=2&fvd=n3&v=3) format("opentype");
    font-weight:300;
    font-style:normal;
    font-stretch:normal;
    font-display:auto;
}
@font-face{
    font-family:sofia-pro;
    src:url(https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/l?subset_id=2&fvd=n7&v=3) format("woff2"),url(https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/d?subset_id=2&fvd=n7&v=3) format("woff"),url(https://use.typekit.net/af/5d97ff/00000000000000007735f999/30/a?subset_id=2&fvd=n7&v=3) format("opentype");
    font-weight:700;
    font-style:normal;
    font-stretch:normal;
    font-display:auto;
}
@font-face{
    font-family:sofia-pro;
    src:url(https://use.typekit.net/af/0ab956/00000000000000007735f998/30/l?subset_id=2&fvd=i3&v=3) format("woff2"),url(https://use.typekit.net/af/0ab956/00000000000000007735f998/30/d?subset_id=2&fvd=i3&v=3) format("woff"),url(https://use.typekit.net/af/0ab956/00000000000000007735f998/30/a?subset_id=2&fvd=i3&v=3) format("opentype");
    font-weight:300;
    font-style:italic;
    font-stretch:normal;
    font-display:auto;
}@font-face{font-family:sofia-pro;src:url(https://use.typekit.net/af/773a22/00000000000000007735f996/30/l?subset_id=2&fvd=n9&v=3) format("woff2"),url(https://use.typekit.net/af/773a22/00000000000000007735f996/30/d?subset_id=2&fvd=n9&v=3) format("woff"),url(https://use.typekit.net/af/773a22/00000000000000007735f996/30/a?subset_id=2&fvd=n9&v=3) format("opentype");font-weight:900;font-style:normal;font-stretch:normal;font-display:auto;}@font-face{font-family:sofia-pro;src:url(https://use.typekit.net/af/66e20c/00000000000000007735f993/30/l?subset_id=2&fvd=i7&v=3) format("woff2"),url(https://use.typekit.net/af/66e20c/00000000000000007735f993/30/d?subset_id=2&fvd=i7&v=3) format("woff"),url(https://use.typekit.net/af/66e20c/00000000000000007735f993/30/a?subset_id=2&fvd=i7&v=3) format("opentype");font-weight:700;font-style:italic;font-stretch:normal;font-display:auto;}
</style>